export enum ZIndexSettings {
  CART_BUTTON = 30,
  MODAL = 50,
  POP_UP = 100010,
  BOTTOM_SHEET = 50,
  HEADER = 30,
  DRAWER = 500,
  DRAWER_SHADOW = 400,
  LOADER = 100000,
}
