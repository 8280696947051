import React from "react";
import styled from "styled-components";
import { ZIndexSettings } from "../config/ZIndexSettings";
const { MetroSpinner } = require("react-spinners-kit");

export default class DiniiLoader extends React.Component<IProps, IState> {
  render() {
    return  this.props.visible ? (<Wrapper transparent={this.props.transparent || false} id="target-container">
        <MetroSpinner
            color={this.props.transparent ? "#686769" : "#FFFFFF"}
        />
        <div>
          <br/>
          {this.props.text || "読み込み中…"}
          <br/>
        </div>
      </Wrapper>)
      : (<div/>);
  }
}

interface IProps {
  visible: boolean;
  text?: string;
  transparent?: boolean;
  asChild?: boolean;
}
interface IState {}

const Wrapper = styled.div<{transparent?: boolean, asChild?: boolean}>`
  position: absolute;
  color: ${({ transparent }) => transparent ? "black" : "white"};
  background-color: rgba(0, 0, 0, ${({ transparent }) => transparent ? 0.2 : 0.8});
  z-index: ${ZIndexSettings.LOADER};
  height: 100%;
  width: 100%;
  display: -moz-box;
  display: -moz-flex;
  display: -webkit-box;  //この辺が
  display: -webkit-flex; //Android2.3~とか
  display: -ms-flexbox; //IE9~用
  display: flex; //最新のブラウザのみであればflexだけでもよいかも
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction:column;
`;
