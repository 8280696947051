import { connect } from "react-redux";
import State from "../../../store/state";
import actions from "./action";
import { OrderHistoryComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    initializeOrders: () => dispatch({
      type: actions.initializeOrders
    })
  };
};

export const OrderHistory = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderHistoryComponent);

export default OrderHistory;
