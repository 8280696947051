import { Modal, PopUpModal } from "./state";
import {
  ADD_CURRENT_COMMUNICATION,
  AppActionTypes,
  DELETE_CURRENT_COMMUNICATION,
  DELETE_CURRENT_ERROR,
  RESET_COMMUNICATION,
  UPDATE_ACTIVE_FREE_PLAN_ITEM,
  UPDATE_ACTIVE_MENU_ITEM,
  UPDATE_ACTIVE_ORDER_ID,
  UPDATE_ACTIVE_SHOP,
  UPDATE_CART_PRICE,
  UPDATE_CURRENT_ERROR,
  UPDATE_IS_LOADING,
  UPDATE_IS_SHOWING_DRAWER,
  UPDATE_MODAL,
  UPDATE_POP_UP_MODAL
} from "./types";
import CustomError from "../../infra/errors";
import { Menu } from "../../infra/domain/entities";

export function updateCartPriceAction(priceAll: number, taxPrice: number): AppActionTypes {
  return {
    type: UPDATE_CART_PRICE,
    priceAll,
    taxPrice
  };
}

export function updateIsShowingDrawerAction(
  isShowing: boolean
): AppActionTypes {
  return {
    type: UPDATE_IS_SHOWING_DRAWER,
    isShowing
  };
}

export function updateIsLoading(isLoading: boolean): AppActionTypes {
  return {
    type: UPDATE_IS_LOADING,
    isLoading
  };
}
export function updateModalAction(modal: Modal): AppActionTypes {
  return {
    type: UPDATE_MODAL,
    modal
  };
}

export function addCurrentCommunicationAction(
  communicationId: string
): AppActionTypes {
  return {
    type: ADD_CURRENT_COMMUNICATION,
    communicationId
  };
}

export function deleteCurrentCommunicationAction(
  communicationId: string
): AppActionTypes {
  return {
    type: DELETE_CURRENT_COMMUNICATION,
    communicationId
  };
}

export function resetCommunicationAction(): AppActionTypes {
  return {
    type: RESET_COMMUNICATION
  };
}

export function updateActiveMenuItemAction(menu: Menu): AppActionTypes {
  return {
    type: UPDATE_ACTIVE_MENU_ITEM,
    menu
  };
}

export function updateActiveFreePlanIdAction(planId: string): AppActionTypes {
  return {
    type: UPDATE_ACTIVE_FREE_PLAN_ITEM,
    planId
  };
}

export function updateActiveShopAction(shopId: string): AppActionTypes {
  return {
    type: UPDATE_ACTIVE_SHOP,
    shopId
  };
}

export function updateCurrentErrorAction(error: CustomError): AppActionTypes {
  return {
    type: UPDATE_CURRENT_ERROR,
    currentError: error
  };
}

export function deleteCurrentErrorAction(): AppActionTypes {
  return {
    type: DELETE_CURRENT_ERROR
  };
}

export function updatePopUpAction(popUp: PopUpModal): AppActionTypes {
  return {
    type: UPDATE_POP_UP_MODAL,
    popUp
  };
}

export function updateActiveOrderIdAction(
  orderId: string
): AppActionTypes {
  return {
    type: UPDATE_ACTIVE_ORDER_ID,
    orderId
  };
}
