import { connect } from "react-redux";
import State from "../../../store/state";
import actions from "./action";
import { MenusComponent } from "./component";

const mapStateToProps = (state: State) => {
  try {
    return {
      shopName: state.shop.activeShop.name,
    };
  } catch (error) {
    throw error;
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    initializeInformation: () => dispatch({
      type: actions.initializeInformation
    })
  };
};

export const Menus = connect(
    mapStateToProps,
    mapDispatchToProps
)(MenusComponent);

export default Menus;
