import styled from "styled-components";

const SecondaryButton = styled.button`
  width: ${(props: IProps) => props.width};
  height: ${(props: IProps) => (props.height ? props.height : "44px")};
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  color: #c4c4c4;
  background-color: ${(props: IProps) =>
    props.color ? props.color : "#fff"};
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  display: block;
  -webkit-tap-heighlight-color: #d05a36;
  outline: none;
`;

interface IProps {
  width: string;
  height?: string;
  color?: string;
}

export default SecondaryButton;
