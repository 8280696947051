import React, { Component } from "react";
import OptionHeader from "./option_header";
import OptionContent, { MoleculesSelectedChoice, MoleculesChoice } from "./option_content";

export default class OptionForm extends Component<IProps, any> {
  render() {
    return (
      <div style={{ margin: "0 0 10px" }}>
        <OptionHeader isSoldOut={this.props.isSoldOut} isRequired={this.props.isRequired} name={this.props.name} />
        <OptionContent
          isSoldOut={this.props.isSoldOut}
          applyChoices={this.props.applyChoices}
          minSelectOrderNumber={this.props.minNum}
          maxSelectOrderNumber={this.props.maxNum}
          choices={this.props.choices}
          defaultIndexes={this.props.defaultIndexes}
          isMultiple={this.props.isMultiple}/>
      </div>
    );
  }
}

interface IProps {
  isSoldOut: boolean;
  minNum: number;
  maxNum: number;
  isRequired: boolean;
  isMultiple: boolean;
  name: string;
  choices: MoleculesChoice[];
  applyChoices: (selectedChoices: MoleculesSelectedChoice[]) => void;
  defaultIndexes: number[];
}
