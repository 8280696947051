import React from "react";
import styled from "styled-components";
import { OrderType } from "../../../infra/domain/entities";

export default class OrderMenuPayment extends React.Component<IProps> {
  render() {
    return (
      <Payment>
        {this.props.ex_tax && (
          <Subtotal>
            <div style={{ width: "50%" }}>小計</div>
            <PaymentPrice>¥{this.props.price}</PaymentPrice>
          </Subtotal>
        )}

        <Tax>
          <div style={{ width: "50%" }}>{this.props.ex_tax ? "" : "うち"}消費税</div>
          <PaymentPrice>
            ¥{ this.props.tax_price}
          </PaymentPrice>
        </Tax>

        {this.props.discount_amount && (
          <Discount>
            <div style={{ width: "50%" }}>割引</div>
            <PaymentPrice>-¥{this.props.discount_amount}</PaymentPrice>
          </Discount>
        )}

        {this.props.order_type === OrderType.DELIVERY &&
        typeof this.props.delivery_fee_amount !== "undefined" &&
        this.props.delivery_fee_amount > 0 && (
            <>
              <Bar />
              <DeliveryFee>
                <div style={{ width: "50%" }}>配送料</div>
                <PaymentPrice>¥{this.props.delivery_fee_amount}</PaymentPrice>
              </DeliveryFee>
            </>
        )}

        <Bar />

        <Total>
          <div style={{ width: "50%" }}>合計</div>
          <PaymentPrice>
            ¥{this.props.order_type === OrderType.DELIVERY && typeof this.props.delivery_price_all !== "undefined" ?
            this.props.delivery_price_all : this.props.price_all}
          </PaymentPrice>
        </Total>

        {this.props.is_canceled && (
          <>
            <Bar />
            <Cancel>
              <div style={{ width: "50%" }}>キャンセル済みです</div>
            </Cancel>
          </>
        )}

        {this.props.refunded_amount && (
          <>
            <Bar />
            <Refund>
              <div style={{ width: "50%" }}>一部返金があります</div>
              <PaymentPrice>
                ¥{this.props.refunded_amount}
              </PaymentPrice>
            </Refund>
          </>
        )}

      </Payment>
    );
  }
}

interface IProps {
  ex_tax: boolean;
  price: number;
  tax_price: number;
  discount_amount?: number;
  price_all: number;
  delivery_fee_amount?: number;
  delivery_price_all?: number;
  order_type: OrderType;
  refunded_amount?: number;
  is_canceled?: boolean;
}

export interface IOrderMenuItemOption {
  choices: IOrderMenuItemChoice[];
}

export interface IOrderMenuItemChoice {
  name: string;
  price: number;
  options: IOrderMenuItemSubOption[];
}

export interface IOrderMenuItemSubOption {
  choices: IOrderMenuItemSubChoice[];
}

export interface IOrderMenuItemSubChoice {
  name: string;
}

const Payment = styled.div`
  padding: 0px 30px;
  padding-top: 12px;
`;

const Subtotal = styled.div`
  display: flex;
  padding-bottom: 3px;
  color: #777777;
`;

const DeliveryFee = styled.div`
  display: flex;
  padding-bottom: 3px;
  margin-top: 12px;
  color: #777777;
`;

const Tax = styled.div`
  display: flex;
  padding-bottom: 3px;
  padding-top: 10px;
  color: #777777;
`;

const Discount = styled.div`
  display: flex;
  padding: 3px 0px;
  color: #f46f46;
`;
const Bar = styled.div`
  border-bottom: 1px solid #f1f1f1;
  padding-top: 9px;
`;
const Total = styled.div`
  display: flex;
  padding-top: 12px;
  font-weight: semibold;
`;

const Refund = styled.div`
  display: flex;
  padding-top: 12px;
  font-weight: semibold;
  color: red;
`;

const Cancel = styled.div`
  display: flex;
  padding-top: 12px;
  font-weight: semibold;
  color: red;
`;

const PaymentPrice = styled.div`
  width: 50%;
  text-align: right;
`;
