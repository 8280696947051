import React from "react";
import AddCard from "../../organisms/add_card/container";
import SecondaryHeader from "../../components/atoms/secondary_header";
import CardRegistered from "../../components/molecules/card_registered";

export class AddCardPageComponent extends React.Component<IProps, IState> {

  componentDidMount(): void {
    this.props.reloadCard();
  }

  render() {

    if (this.props.enableStripe) {
      return (
        <div>
          {
            this.props.last && this.props.brand
              ? (
                <div>
                  <SecondaryHeader>登録中のカード情報</SecondaryHeader>
                  <div style={{ width: "90%", padding: "20px" }}>
                    <CardRegistered brand={this.props.brand} last={this.props.last}/>
                  </div>
                </div>
              )
              : null
          }
          <AddCard sectionHeaderText={this.props.last && this.props.brand ? "カード情報を変更する" : "カード情報"}/>
        </div>
      );
    }
    return (
      <>
        <div style={{ margin: "10px", textAlign: "center" }}>
           こちらのページではカード決済に対応しておりません
        </div>
        <div style={{ margin: "10px", textAlign: "center" }}>
          現地決済でのご利用のみになります
        </div>
       </>
    );
  }
}

interface IProps {
  brand?: string;
  last?: string;
  reloadCard: () => void;
  enableStripe: boolean;
}

interface IState {
}
