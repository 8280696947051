import { connect } from "react-redux";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { AddCardComponent } from "./component";
import { addCurrentCommunicationAction, deleteCurrentCommunicationAction } from "../../../store/app/actions";

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addCommunication: (id: string) => dispatch(addCurrentCommunicationAction(id)),
    deleteCommunication: (id: string) => dispatch(deleteCurrentCommunicationAction(id)),
    handleCardToken: (token: string, backTo?: string) =>
        dispatch(dispatchers.handleCardToken(token, backTo)),
    handleCardTokenError: (error: Error) => dispatch(dispatchers.handleCardTokenError(error))
  };
};

export const AddCard = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCardComponent);

export default AddCard;
