import { ShopState } from "./state";

export const UPDATE_SHOP = "UPDATE_SHOP";
export const UPDATE_ACTIVE_VISIT_TIMES = "UPDATE_ACTIVE_VISIT_TIMES";

interface UpdateShopAction {
  type: typeof UPDATE_SHOP;
  shop: ShopState;
}

interface UpdateActiveVisitTimesAction {
  type: typeof UPDATE_ACTIVE_VISIT_TIMES;
  activeVisitTimes: string[];
}

export type ShopActionTypes = UpdateShopAction | UpdateActiveVisitTimesAction;
