
export const actions = {
  fetchCard: "ADD_CARD_PAGE_FETCH_CARD",
};

export const dispatchers = {
  fetchCard: () => ({
    type: actions.fetchCard,
  }),
};

export default actions;
