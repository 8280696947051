import { OrderMenu } from "../../../infra/domain/entities";

export const actions = {
  updateCartItem: "CART_CONFIRM_UPDATE_CART_ITEM",
  postOrder: "CART_CONFIRM_POST_ORDER"
};

export const dispatchers = {
  handleUpdateCart: (cartItem: OrderMenu) => ({
    type: actions.updateCartItem,
    cartItem
  }),
  handlePostOrder: () => ({
    type: actions.postOrder
  })
};

export default actions;
