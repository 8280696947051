import { RESET_USER, UPDATE_ACCESS_TOKEN, UPDATE_CARDS, UPDATE_USER, UserActionTypes } from "./types";
import { User } from "../../infra/domain/User";
import { StpCard } from "../../infra/domain/StpCard";

export function resetUserAction(): UserActionTypes {
  return {
    type: RESET_USER
  };
}

export function updateUserAction(user: Partial<User>): UserActionTypes {
  return {
    type: UPDATE_USER,
    user
  };
}

export function updateAccessTokenAction(accessToken: string): UserActionTypes {
  return {
    type: UPDATE_ACCESS_TOKEN,
    accessToken
  };
}

export function updateCards(cards: StpCard[]): UserActionTypes {
  return {
    type: UPDATE_CARDS,
    cards
  };
}
