import State from "../../../store/state";
import { connect } from "react-redux";
import { OrderDetailMenusComponent } from "./component";

const mapStateToProps = (state: State) => {
  const id = state.app.activeOrderId;
  const filteredOrders = state.entity.orders.filter(
    item => item.order_id === id
  );
  const order =
    filteredOrders && filteredOrders.length > 0 ? filteredOrders[0] : undefined;
  const shop = state.shop.activeShop;
  return {
    price: order ? order.price : undefined,
    tax_price: order ? order.tax_price : undefined,
    discount_amount: order ? order.discount_amount : undefined,
    price_all: order ? order.price_all : undefined,
    order_menus: order ? order.order_menus : undefined,
    delivery_fee_amount: order ? order.delivery_fee_amount : undefined,
    delivery_price_all: order ? order.delivery_price_all : undefined,
    ex_tax: shop ? shop.ex_tax! : true,
    refunded_amount: order ? order.refunded_amount : undefined,
    is_canceled: order && order.is_canceled
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const OrderDetailMenus = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailMenusComponent);

export default OrderDetailMenus;
