import { PostOrderArguments } from "../../../infra/api/ApiService";

export const actions = {
  updateCart: "CONFIRM_ORDER_UPDATE_CART",
  postOrderAction: "CONFIRM_CART_BUTTON_POST_ORDER_ACTION",
  fetchCard: "CONFIRM_ORDER_FETCH_CARD",
};

export const dispatchers = {
  updateCart:() => ({
    type: actions.updateCart
  }),
  postOrder: (args: PostOrderArguments) => ({
    type: actions.postOrderAction,
    args
  }),
  fetchCard: () => ({
    type: actions.fetchCard,
  }),
};

export default actions;
