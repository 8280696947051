import State from "../../../store/state";
import { connect } from "react-redux";
import { OrderDetailInfoComponent } from "./component";

const mapStateToProps = (state: State) => {
  const id = state.app.activeOrderId;
  const filteredOrders = state.entity.orders.filter(
    item => item.order_id === id
  );

  const order =
    filteredOrders && filteredOrders.length > 0 ? filteredOrders[0] : undefined;

  const filteredShop = state.entity.shops.filter(
    shop => shop.shop_id === (order ? order.shop_id : undefined)
  );

  const shop =
    filteredShop && filteredShop.length > 0 ? filteredShop[0] : undefined;

  return {
    order_type: order ? order.order_type : undefined,
    address_info: order ? order.address_info : undefined,
    order_number: order ? order.order_number : undefined,
    order_status: order ? order.order_status : undefined,
    shop_name: shop ? shop.name : undefined,
    visit_at: order ? order.visit_at : undefined,
    payment_method: order ? order.payment_method : undefined,
    nick_name: order ? order.nick_name : undefined,
    is_canceled: order ? order.is_canceled : undefined
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const OrderDetailInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailInfoComponent);

export default OrderDetailInfo;
