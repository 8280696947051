import React from "react";
import styled from "styled-components";
import SecondaryHeader from "../../components/atoms/secondary_header";
import { Icon } from "semantic-ui-react";

export class OrderDetailMapComponent extends React.Component<IProps> {
  render() {
    return (
      <Wrapper>
        <SecondaryHeader onClick={() => this.props.mapUrl && window.open(this.props.mapUrl)}>
            <div style={{ display:"flex", alignItems:"center" }}>
              <div> 地図を確認する </div>
              <Icon name="external alternate" size="small" style={{ marginLeft:"10px" }}/>
            </div>
        </SecondaryHeader>
        <div style={{ height:"10px" }}/>
      </Wrapper>
    );
  }
}

interface IProps {
  mapUrl?: string;
}
const Wrapper = styled.div`
  width: 100%;
`;
