import React from "react";
import { RouteComponentProps } from "react-router";
import { Image } from "semantic-ui-react";
import StepLogin from "../../../assets/images/step_login.svg";
import MailLoginForm from "../../components/molecules/mail_login_form";
import styled from "styled-components";
import ModalHeader, { ActionImageType } from "../../components/molecules/bottom_modal_header";

export class MailSignInComponent extends React.Component<IProps, IState> {
  render() {
    return (
            <div>
                <ModalHeader text={"メールアドレスでログイン"} actionImageSrcType={ActionImageType.back} onActionClick={this.props.onClickBackButton} />
                <Content>
                <Image src={StepLogin} style={{ margin: "0 auto 18px" }} />
                <div style={{ margin: "28px auto" }}>
                    <MailLoginForm
                        onLoginButtonClick={(email: string, password: string) => {
                          this.props.signInWithEmail(email, password);
                        }}
                    />
                </div>
                </Content>
            </div>
    );
  }
}

interface IProps extends RouteComponentProps<any> {
  signInWithEmail: (email: string, password: string) => void;
  onClickBackButton: () => void;
}
interface IState {}

const Content = styled.div`
  max-height: 65vh;
  overflow: scroll;
`;
