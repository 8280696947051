import { put, take } from "redux-saga/effects";
import { push } from "connected-react-router";
import actions from "./action";
import Routes from "../../routes";
import { Modal } from "../../../store/app/state";
import {
  deleteCommunicationId,
  getCart,
  handleError,
  putCommunicationId
} from "../../../store/sagas";
import uuid from "uuid";
import { call, select } from "@redux-saga/core/effects";
import { updateModalAction } from "../../../store/app/actions";
import { Cart } from "../../../infra/domain/entities";
import UserRepository from "../../../infra/repository/UserRepository";
import EntityRepository from "../../../infra/repository/EntityRepository";
import ShopRepository from "../../../infra/repository/ShopRepository";

function handlePostOrder(
  userRepository: UserRepository,
  entityRepository: EntityRepository,
  shopRepository: ShopRepository
) {
  return function* () {
    while (true) {
      yield take(actions.postOrder);

      const communication = uuid();

      try {
        const isFirebaseSignIn = yield call(userRepository.isFirebaseSignedIn);
        if (!isFirebaseSignIn) {
          yield put(updateModalAction(Modal.SignIn));
          continue;
        }

        const isDiniiSignIn = yield call(userRepository.isDiniiSignedIn);
        if (!isDiniiSignIn) {
          yield call(userRepository.signOut);
          yield put(updateModalAction(Modal.SignIn));
          continue;
        }

        yield call(putCommunicationId, communication);
        const shopId = yield call(shopRepository.selectCurrentShopId);
        yield call(entityRepository.fetchAndUpdateCart, shopId);
        yield put(updateModalAction(Modal.NoModal));
        yield put(push(Routes.confirmOrder));
      } catch (e) {
        if (e.shouldShowMessage && e.status === 402) {
          const shopId = yield call(shopRepository.selectCurrentShopId);
          yield call(entityRepository.fetchAndUpdateCart, shopId);
        }
        yield handleError(e);
      } finally {
        yield call(deleteCommunicationId, communication);
      }
    }
  };
}

function handleUpdateCart(
  entityRepository: EntityRepository,
  shopRepository: ShopRepository
) {
  return function* () {
    while (true) {
      const updateCartAction = yield take(actions.updateCartItem);
      const communication = uuid();
      try {
        yield call(putCommunicationId, communication);
        const cartItem = updateCartAction.cartItem;
        const stateCart: Cart = yield select(getCart);
        const newCart: Cart = stateCart;
        const existIndex = stateCart.order_menus.findIndex(
          item => item.id === cartItem.id
        );
        if (existIndex === -1) {
          newCart.order_menus.push(cartItem);
        } else {
          newCart.order_menus[existIndex] = cartItem;

          // 個数が0個の時に削除する
          if (newCart.order_menus[existIndex].quantity === 0) {
            newCart.order_menus = newCart.order_menus.filter(
              v => v.id !== newCart.order_menus[existIndex].id
            );
          }
        }
        const shopId = yield call(shopRepository.selectCurrentShopId);
        yield call(entityRepository.updateCart, shopId, newCart);

        // カート内にメニューが１つもない時にモーダルを消す
        if (!newCart.order_menus || newCart.order_menus.length === 0) {
          yield put(updateModalAction(Modal.NoModal));
        }
      } catch (e) {
        if (e.shouldShowMessage && e.status === 402) {
          const shopId = yield call(shopRepository.selectCurrentShopId);
          yield call(entityRepository.fetchAndUpdateCart, shopId);
        }
        yield handleError(e);
      } finally {
        yield call(deleteCommunicationId, communication);
      }
    }
  };
}

function createSagas(
  entityRepository: EntityRepository,
  userRepository: UserRepository,
  shopRepository: ShopRepository
) {
  return [
    handlePostOrder(userRepository, entityRepository, shopRepository),
    handleUpdateCart(entityRepository, shopRepository)
  ];
}

export default createSagas;
