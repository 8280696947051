import React from "react";
import styled from "styled-components";
import { Elements } from "react-stripe-elements";
import CardAll from "../../components/molecules/card_all";
import CardInfo from "../../components/molecules/card_info";
import SecondaryHeader from "../../components/atoms/secondary_header";

export class AddCardComponent extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper>
        <div style={{ marginBottom: "10px" }}>
          <SecondaryHeader>{this.props.sectionHeaderText}</SecondaryHeader>
        </div>
        <CardAllSection>
          <CardAll/>
        </CardAllSection>
        <div style={{ margin: "20px" }}>
          <Elements>
          <CardInfo
            width="100%"
            handleCardToken={token => this.props.handleCardToken(token, this.props.backTo)}
            handleCardTokenError={this.props.handleCardTokenError}
            addCommunication={this.props.addCommunication}
            deleteCommunication={this.props.deleteCommunication}
            addCardText={this.props.addCardText}
          />
        </Elements>
        </div>
        <Text>登録したカードで以降のお会計はスムーズに♪</Text>
      </Wrapper>
    );
  }
}

interface IProps {
  handleCardToken: (token: string, backTo?: string) => void;
  addCommunication: (id: string) => void;
  deleteCommunication: (id: string) => void;
  handleCardTokenError: (error: Error) => void;
  sectionHeaderText: string;
  addCardText?: string;
  backTo?: string;
}

interface IState {
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`;
const CardAllSection = styled.div`
  //  width:275px;
  margin-bottom:10px;
`;

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  margin-top: 26px;
`;
