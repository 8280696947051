import moment from "moment-timezone";

export const parseDate = (visit_at: number): string => {
  const m = moment(visit_at * 1000).tz("Asia/Tokyo");
  return `${m.format("YYYY/MM/DD")} (${dayIndexToText(m.day())})`;
};

export const parseTime = (visit_at: number): string =>
    moment(visit_at * 1000).tz("Asia/Tokyo").format("H:mm");

export const dayIndexToText = (idx: number):string => ["日", "月", "火", "水", "木", "金", "土"][idx];
