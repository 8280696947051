import { put, take } from "redux-saga/effects";
import actions from "./action";
import { Modal } from "../../../store/app/state";
import { deleteCommunicationId, getCart, handleError, putCommunicationId } from "../../../store/sagas";
import uuid from "uuid";
import { call, select } from "@redux-saga/core/effects";
import { updateModalAction } from "../../../store/app/actions";
import { Cart } from "../../../infra/domain/entities";
import EntityRepository from "../../../infra/repository/EntityRepository";
import ShopRepository from "../../../infra/repository/ShopRepository";

function handleClickAddCart(
    entityRepository: EntityRepository,
    shopRepository: ShopRepository
) {
  return function* () {
    while (true) {
      const clickAction = yield take(actions.clickAddCart);
      const communication = uuid();
      try {
        yield call(putCommunicationId, communication);

        const cart = yield select(getCart);
        const newCart: Cart = Object.assign({}, cart);
        newCart.order_menus = [].concat(cart.order_menus);
        newCart.order_menus.push(clickAction.cartItem);
        newCart.order_menus = newCart.order_menus.map(orderMenu => {
          delete orderMenu.id;
          return orderMenu;
        });

        try {
          const shopId = yield call(shopRepository.selectCurrentShopId);
          yield call(entityRepository.updateCart, shopId, newCart);
          yield put(updateModalAction(Modal.NoModal));
        } catch (error) {
          if (error.shouldShowMessage && error.status === 402) {
            const shopId = yield call(shopRepository.selectCurrentShopId);
            yield call(entityRepository.fetchAndUpdateCart, shopId);
          }
          yield handleError(error);
        }
      } catch (e) {
        yield handleError(e);
      } finally {
        yield call(deleteCommunicationId, communication);
      }
    }
  };
}

function createSagas(
    entityRepository: EntityRepository,
    shopRepository: ShopRepository
) {
  return [
    handleClickAddCart(entityRepository, shopRepository),
  ];
}

export default createSagas;
