import { connect } from "react-redux";
import { withRouter } from "react-router";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { LogoutConfirmComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signOut: () => dispatch(dispatchers.handleOnClickSignOutAction())
  };
};

export const LogoutConfirm = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LogoutConfirmComponent)
);

export default LogoutConfirm;
