import { connect } from "react-redux";
import { withRouter } from "react-router";
import State from "../../../store/state";
import { HeaderShopAboutComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {
    app: state.app,
    name: state.shop.activeShop.name,
    open: state.shop.activeShop.open,
    close: state.shop.activeShop.close,
    user: state.user,
    shopImage: state.shop.activeShop.top_image_url,
    mapUrl: state.shop.activeShop.map_image_url,
    enableDelivery: state.shop.activeShop.enable_delivery,
    deliveryFee: state.shop.activeShop.delivery_fee
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { };
};

export const HeaderShopAbout = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderShopAboutComponent)
);

export default HeaderShopAbout;
