import { put, call, take, select } from "redux-saga/effects";
import actions from "./action";
import UserRepository from "../../../infra/repository/UserRepository";
import { deleteCommunicationId, getAccessToken, handleError } from "../../../store/sagas";
import uuid from "uuid";
import State from "../../../store/state";
import { Company } from "../../../infra/domain/entities";
import { updateAccessTokenAction } from "../../../store/user/actions";
import { updateModalAction } from "../../../store/app/actions";
import { Modal } from "../../../store/app/state";

export const getCompany = (state: State): Company | undefined => state.entity.company;

function handleClickTwitterLoginAction(
    userRepository: UserRepository
) {
  return function* () {
    while (true) {
      try {
        yield take(actions.clickTwitterLoginAction);
        yield call(userRepository.twitterSignInWithRedirect);
      } catch (e) {
        yield handleError(e);
      }
    }
  };
}

function handleClickLINELoginAction(
    userRepository: UserRepository,
) {
  return function* () {
    while (true) {
      const communication = uuid();
      try {

        yield take(actions.clickLINELoginAction);

        const company: Company | undefined = yield select(getCompany);
        if (!company) {
          console.error("no company info");
          continue;
        }

        if (!company.liff_id) {
          console.error("no liff id");
          continue;
        }

        const accessToken = yield select(getAccessToken);

        yield put(updateAccessTokenAction(accessToken));

        yield call(userRepository.redirectToLineAuthPage, company.liff_id!, accessToken);

        yield put(updateModalAction(Modal.NoModal));

      } catch (e) {
        console.error(e);
        yield handleError(e);
      } finally {
        yield call(deleteCommunicationId, communication);
      }
    }
  };
}

function createSagas(
    userRepository: UserRepository,
) {
  return [
    handleClickTwitterLoginAction(userRepository),
    handleClickLINELoginAction(userRepository)
  ];
}

export default createSagas;
