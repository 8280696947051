/* tslint:disable */

const makeURLs = () => {
  let URLs;
  switch (process.env.REACT_APP_STAGE) {
    case "stag":
      URLs = {
        API: process.env.REACT_APP_USE_LOCAL_API === 'yes' ? "http://localhost:3000": "https://api.stag.for.dinii.jp/v2" ,
        STP_KEY_DINII_FF: "pk_test_qQSVTtpKsopORDaILfCIEvRn00TW3GFcvI",
        STP_KEY_DINII: "pk_test_3cz5HfGLkulgJXDRHSiITXVS001BUVY4Yi",
        SENTRY: "https://098cd2c7360a40f49c742049edcebf16@sentry.io/1778377"
      };
      break;
    case "prod":
      console.log("Created by Dinii.inc. ");
      URLs = {
        API: "https://api.for.dinii.jp/v2",
        STP_KEY_DINII_FF: "pk_live_EaW1pLdgFE4rHqH041WbhCEp00oIFexmO3",
        STP_KEY_DINII: "pk_live_bw3qMylblkUQ64LppxJwxS3S00YqLuDZwy",
        SENTRY: "https://098cd2c7360a40f49c742049edcebf16@sentry.io/1778377"
      };
      break;
    default:
      console.log("%cUnknown Stage: " + process.env.REACT_APP_STAGE);
      URLs = {
        API: "",
        STP_KEY_DINII_FF: "",
        STP_KEY_DINII: "",
        SENTRY: ""
      };
      break;
  }
  return URLs;
};
const URLs = makeURLs();
export default URLs;
