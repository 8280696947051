import { ApiService, PostOrderArguments } from "../api/ApiService";
import { all, call, put, select } from "@redux-saga/core/effects";
import { updateAllMenusAction, updateAllShopsAction, updateCartAction, updateCompanyAction, updateOrdersAction } from "../../store/entity/actions";
import { getAccessToken, getCart } from "../../store/sagas";
import { compareValues } from "../../util/reducer_helper";
import { FirebaseService } from "../firebase/FirebaseService";
import { Cart } from "../domain/entities";

export default class EntityRepository {
  constructor(private apiClient: ApiService, private firebaseClient: FirebaseService) {
    this.fetchAndUpdateOneShopInformation = this.fetchAndUpdateOneShopInformation.bind(this);
    this.fetchAndUpdateCart = this.fetchAndUpdateCart.bind(this);
    this.fetchAndUpdateShopsAndCompany = this.fetchAndUpdateShopsAndCompany.bind(this);
    this.fetchAndUpdateOrders = this.fetchAndUpdateOrders.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.postOrder = this.postOrder.bind(this);
  }

  *postOrder(shopId: string, args: PostOrderArguments) {
    const idToken = yield call(this.firebaseClient.getIdToken);
    const response = yield call(this.apiClient.orderRequest, shopId, idToken, args);
    return response.order_id;
  }

  *fetchAndUpdateOneShopInformation(shopId: string) {
    const accessToken = yield select(getAccessToken);
    const [menus, cart] = yield all([
      call(this.apiClient.listMenus, accessToken, shopId),
      call(this.apiClient.getCartByAccessToken, accessToken, shopId)
    ]);

    const sortedMenus = menus.sort(compareValues("priority"));
    yield all([
      put(updateAllMenusAction(sortedMenus)),
    ]);
    if (cart) {
      yield put(updateCartAction(cart));
    }
  }

  *fetchAndUpdateShopsAndCompany() {
    const accessToken = yield select(getAccessToken);
    const company = yield call(this.apiClient.getCompanyInfo, accessToken);
    yield put(updateCompanyAction(company));
    const shops = yield call(this.apiClient.listShop, accessToken);
    yield put(updateAllShopsAction(shops));
  }

  *fetchAndUpdateOrders() {
    const accessToken = yield select(getAccessToken);
    const idToken = yield call(this.firebaseClient.getIdToken);
    const orders = yield call(this.apiClient.listOrders, accessToken, idToken);
    orders.sort(compareValues("ordered_at"));
    yield put(updateOrdersAction(orders));
  }

  *fetchAndUpdateCart(shopId: string) {
    const accessToken = yield select(getAccessToken);
    const cart = yield call(this.apiClient.getCartByAccessToken, accessToken, shopId);
    yield put(updateCartAction(cart));
  }

  *updateCart(shopId: string, cart: Cart) {
    const accessToken = yield select(getAccessToken);
    yield call(this.apiClient.updateCartByAccessToken, accessToken, shopId, cart);
    const newCart = yield call(this.apiClient.getCartByAccessToken, accessToken, shopId);
    yield put(updateCartAction(newCart));
  }

  *selectCart() {
    return yield select(getCart);
  }
}
