import State from "../../../store/state";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { NavigationHeaderComponent } from "./component";
import { updateIsShowingDrawerAction } from "../../../store/app/actions";

const mapStateToProps = (state: State) => {
  const closeModal = () => {};
  return {
    closeModal,
    brandName: state.entity.company ? state.entity.company.company_name : "",
    shopName: state.shop.activeShop ? state.shop.activeShop.name : undefined,
    isSignedIn: !!state.user.user
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClickHamburger: () => dispatch(updateIsShowingDrawerAction(true)),
  };
};

export const NavigationHeader = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NavigationHeaderComponent)
);

export default NavigationHeader;
