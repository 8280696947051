import { all, call, put, spawn } from "@redux-saga/core/effects";
import { ApiService } from "../infra/api/ApiService";
import { FirebaseService } from "../infra/firebase/FirebaseService";
/**
 * Pages
 */
import homeSagas from "../presentation/pages/menus/saga";
import launchScreenSagas from "../presentation/pages/launch_screen/saga";
import confirmOrderSagas from "../presentation/pages/confirm_order/saga";
import orderHistorySagas from "../presentation/pages/order_history/saga";
import shopsSagas from "../presentation/pages/shops/saga";
import addCardPageSags from "../presentation/pages/add_card_page/saga";
/**
 * Organisms
 */
import menuListSagas from "../presentation/organisms/menu_list/saga";
import menuDetailSagas from "../presentation/organisms/menu_detail/saga";
import cartSagas from "../presentation/organisms/cart/saga";
import signInSagas from "../presentation/organisms/sign_in/saga";
import shopListSagas from "../presentation/organisms/shop_list/saga";
import mailSignUpSagas from "../presentation/organisms/mail_sign_up/saga";
import mailSignInSagas from "../presentation/organisms/mail_sign_in/saga";
import addCartSagas from "../presentation/organisms/add_card/saga";
import logoutConfirmSagas from "../presentation/organisms/logout_confirm/saga";
import orderHistoryListSagas from "../presentation/organisms/order_history_list/saga";
import accountUserInfoSagas from "../presentation/organisms/account_user_info/saga";
import verifyEmailSagas from "../presentation/organisms/confirm_order_recommend_verify_email/saga";
import URLs from "../config/URL";
import State from "./state";
import {
  addCurrentCommunicationAction,
  deleteCurrentCommunicationAction,
  updateCurrentErrorAction,
} from "./app/actions";
import { createAppRedirectSaga } from "../presentation/AppContainer";
import { ShopState } from "./shop/state";
import EntityRepository from "../infra/repository/EntityRepository";
import UserRepository from "../infra/repository/UserRepository";
import ShopRepository from "../infra/repository/ShopRepository";
import AppRepository from "../infra/repository/AppRepository";
import ApiClient from "../infra/api/ApiClient";
import FirebaseClient from "../infra/firebase/FirebaseClient";
import CustomError from "../infra/errors";
import { push } from "connected-react-router";
import Routes from "../presentation/routes";
import { Shop } from "../infra/domain/shop";

export const getUserId = (state: State) =>
  state.user.user ? state.user.user.user_id : undefined;
export const getAccessToken = (state: State) => state.user.accessToken;
export const getCart = (state: State) => state.entity.cart;
export const getCurrentModal = (state: State) => state.app.modal;
export const getCards = (state: State) => state.user.cards;
export const getShops = (state: State): Shop[] => state.entity.shops;
export const getShop = (state: State): ShopState => state.shop;
export const getUser = (state: State) => state.user;

export function* handleError(error: any) {
  console.log("handle error: ", error);
  if (error !== null) {
    if (error.errorCode === 1102) {
      yield put(push(Routes.default));
    } else {
      if (error.shouldShowMessage) {
        yield put(updateCurrentErrorAction(error));
      } else {
        yield put(
          updateCurrentErrorAction(
              new CustomError("ページの読み込みに失敗しました", "電波状況をお確かめの上、再度読み込み直してください")
          )
        );
        throw error;
      }
    }
  }
}

export function* putCommunicationId(communicationID: string) {
  yield put(addCurrentCommunicationAction(communicationID));
}

export function* deleteCommunicationId(communicationID: string) {
  yield put(deleteCurrentCommunicationAction(communicationID));
}

export default function* rootSaga() {
  const apiClient: ApiService = new ApiClient(URLs.API);
  const firebaseClient: FirebaseService = new FirebaseClient();
  const userRepository = new UserRepository(apiClient, firebaseClient);
  const entityRepository = new EntityRepository(apiClient, firebaseClient);
  const shopRepository = new ShopRepository(apiClient, firebaseClient);
  const appRepository = new AppRepository(apiClient);
  const sagas = [
    ...homeSagas(entityRepository, shopRepository),
    ...launchScreenSagas(userRepository, entityRepository, shopRepository),
    ...menuListSagas(appRepository),
    ...menuDetailSagas(entityRepository, shopRepository),
    ...cartSagas(entityRepository, userRepository, shopRepository),
    ...signInSagas(userRepository),
    ...shopListSagas(entityRepository),
    ...confirmOrderSagas(shopRepository, entityRepository, appRepository, userRepository),
    ...mailSignUpSagas(userRepository, entityRepository, shopRepository),
    ...mailSignInSagas(userRepository, entityRepository, shopRepository),
    ...addCartSagas(userRepository),
    ...logoutConfirmSagas(userRepository),
    ...orderHistorySagas(entityRepository),
    ...orderHistoryListSagas(appRepository),
    ...accountUserInfoSagas(userRepository),
    ...shopsSagas(userRepository, entityRepository),
    ...addCardPageSags(userRepository),
    ...verifyEmailSagas(userRepository),
    createAppRedirectSaga(userRepository, entityRepository, shopRepository)
  ];
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log("saga finished with error: ", e);
          }
        }
      })
    )
  );
}
