import { connect } from "react-redux";
import { withRouter } from "react-router";
import { SignInComponent } from "./component";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { updateModalAction } from "../../../store/app/actions";
import { Modal } from "../../../store/app/state";

const mapStateToProps = (state: State) => {
  return {
    isLoading: state.app.isLoading
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClickLINESignIn: () => dispatch(
        dispatchers.handleClickLINELoginAction()
    ),
    onClickTwitterSignIn: () => dispatch(
        dispatchers.handleClickTwitterLoginAction()
    ),
    onClickMailSignIn: () => dispatch(updateModalAction(Modal.MailSignin)),
    onClickMailSignUp: () => dispatch(updateModalAction(Modal.MailSignup)),
  };
};

export const SignIn =  withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SignInComponent)
);

export default SignIn;
