import firebase from "firebase/app";
import { FirebaseService } from "./FirebaseService";
import CustomError, { Errors } from "../errors";

async function awaitAuthInitialize() {
  return new Promise((resolve, reject) =>
                         firebase.auth().onAuthStateChanged(user => {
                           resolve({});
                         })
  );
}

export default class FirebaseClient implements FirebaseService {

  get currentUser(): firebase.User | null {
    return firebase.auth().currentUser;
  }

  async getCurrentUser(): Promise<firebase.User> {
    const user = firebase.auth().currentUser;
    if (!user) {
      await awaitAuthInitialize();
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new CustomError("認証エラー", "ユーザの認証に失敗しました。", Errors.NOT_FIREBASE_AUTHORIZED);
      }
      return user;
    }
    return user;
  }

  // FIXME: 本人認証(email認証)をしっかりと行う際に、mail 送信部分もwrapする
  async shouldVerifyEmail(): Promise<boolean> {
    await awaitAuthInitialize();
    const user = firebase.auth().currentUser;
    if (user && user.providerData.filter((data) => data && data.providerId === "password").length > 0) {
      await user.reload();
      if (user.email && !user.emailVerified) {
        return true;
      }
    }
    return false;
  }

  async getUserId() {
    const user = firebase.auth().currentUser;
    if (user === null) {
      await awaitAuthInitialize();
      const user = firebase.auth().currentUser;
      return user ? user.uid : null;
    }
    return await firebase.auth().currentUser!.uid;
  }

  async isSignedIn(): Promise<boolean> {
    const user = await firebase.auth().currentUser;
    if (user === null) {
      await awaitAuthInitialize();
      const user = firebase.auth().currentUser;
      return user !== null;
    }
    return true;
  }

  async signOut() {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      await firebase.auth().signOut();
    } else {
      await awaitAuthInitialize();
      const user = firebase.auth().currentUser;
      if (user !== null) {
        await firebase.auth().signOut();
      }
    }
  }

  async signUpWithEmail(name: string, mail: string, password: string) {
    try {
      const credential = await firebase.auth().createUserWithEmailAndPassword(mail, password);
      if (credential && credential.user) {
        await credential.user.updateProfile({ displayName: name });
      }

      await credential.user!.sendEmailVerification().catch(error => {
        throw error;
      });

      return credential.user;
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use": {
          throw new CustomError("登録済みのアドレスです", "ログインしてください");
        }
        case "auth/invalid-email": {
          throw new CustomError("メールアドレスの形式が正しくありません", "正しいメールアドレスを入力してください");
        }
        default:
          throw new CustomError("ユーザ登録に失敗しました。", "");
      }
    }
  }

  async sendEmailVerification() {
    const user = await firebase.auth().currentUser;
    if (user) {
      await user.sendEmailVerification();
    }
  }

  async signInWithEmail(mail: string, password: string) {
    try {
      const credential = await firebase.auth().signInWithEmailAndPassword(mail, password);
      return credential.user;
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password": {
          throw new CustomError("メールアドレスもしくはパスワードが間違っています",
                                "ご確認お願いします");
        }
        case "auth/user-not-found": {
          throw new CustomError("メールアドレスもしくはパスワードが間違っています",
                                "ご確認お願いします");
        }
        default:
          throw new CustomError("メールアドレスもしくはパスワードが間違っています", "ご確認お願いします");
      }
    }
  }
  async updateUserProfile (name: string) {
    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new CustomError("ユーザ情報を取得できませんでした。", "お手数ですが、ログインしなおしてください");
      }
      await user.updateProfile({
        displayName: name
      });
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use": {
          throw new CustomError("登録済みのアドレスです", "ログインしてください");
        }
        case "auth/invalid-email": {
          throw new CustomError("メールアドレスの形式が正しくありません", "正しいメールアドレスを入力してください");
        }
        default:
          throw error;
      }
    }
  }
  async getIdToken() {
    const user = firebase.auth().currentUser;
    if (user === null) {
      await awaitAuthInitialize();
      const user = firebase.auth().currentUser;
      if (user == null) {
        throw new CustomError("認証エラー", "ユーザの認証に失敗しました。", Errors.NOT_FIREBASE_AUTHORIZED);
      }
      return await user.getIdToken(true);
    }
    return await user.getIdToken(true);
  }

  async getIdTokenIfExist() {
    const user = firebase.auth().currentUser;
    if (user === null) {
      await awaitAuthInitialize();
      const user = firebase.auth().currentUser;
      if (user == null) {
        return null;
      }
      return await user.getIdToken(true);
    }
    return await user.getIdToken(true);
  }

  async twitterSignInWithRedirect() {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      await firebase.auth().signOut();
    }
    const provider = new firebase.auth.TwitterAuthProvider();
    return await firebase.auth().signInWithRedirect(provider);
  }

  async signInWithCustomToken(customToken: string): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithCustomToken(customToken);
  }
}
