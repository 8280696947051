import React from "react";
import styled from "styled-components";
import FirebaseClient from "../../../infra/firebase/FirebaseClient";
import {
  getBrowserDocumentHiddenProp,
  getBrowserVisibilityProp
} from "../../../util/window";
import { Button } from "semantic-ui-react";

export class ConfirmOrderRecommendEmailVerifyComponent extends React.Component<
  IProps,
  IState
> {
  visibilityProp = getBrowserVisibilityProp() || "visibilitychange";
  hidden = getBrowserDocumentHiddenProp() || "hidden";

  firebaseClient = new FirebaseClient();

  constructor(props: IProps) {
    super(props);
    this.state = {
      visible: false
    };

    this.onClickSendEmailVerificationButton = this.onClickSendEmailVerificationButton.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener(
      this.visibilityProp,
      this.handleVisibilityChange
    );
  }

  handleVisibilityChange = async () => {
    try {
      // @ts-ignore
      if (document[this.hidden]) {
      } else {
        this.setState({
          visible: await this.firebaseClient.shouldVerifyEmail()
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount(): void {
    document.addEventListener(
      this.visibilityProp,
      this.handleVisibilityChange,
      false
    );
    this.firebaseClient.shouldVerifyEmail().then(shouldVerify => {
      this.setState({
        visible: shouldVerify
      });
    });
  }

  private onClickSendEmailVerificationButton = () => {
    this.props.sendEmailVerification();
  };

  render() {
    return (
      this.state.visible && (
        <Wrapper>
          ご注文には、ご登録のメールアドレスに送信されたリンクからメールアドレスの認証が必要です。<br/>
          メールが確認できない場合は以下のボタンから認証メールの再送をお試しください。
          <SendEmailButtonWrapper>
            <Button basic onClick={() => this.onClickSendEmailVerificationButton()}>認証メールを再送する</Button>
          </SendEmailButtonWrapper>
        </Wrapper>
      )
    );
  }
}

interface IProps {
  sendEmailVerification: () => void;
}

interface IState {
  visible: boolean;
  sendEmailVerificationResultMessage?: string;
}

const Wrapper = styled.div`
  border-top: 1px dotted #4a4a4a;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  width: 100%;
`;

const SendEmailButtonWrapper = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 12px;
  width: 100%;
`;
