export const actions = {
  onReceiveCardToken: "ADD_CARD_ON_RECEIVE_CARD_TOKEN",
  onReceiveCardTokenError: "ADD_CARD_ON_RECEIVE_CARD_TOKEN_ERROR",
};

export const dispatchers = {
  handleCardToken: (token: string, backTo?: string) => ({
    type: actions.onReceiveCardToken,
    token,
    backTo
  }),
  handleCardTokenError: (error: any) => ({
    type: actions.onReceiveCardTokenError,
    error
  }),
};
