import { Shop } from "../../../infra/domain/shop";

export const actions = {
  clickShopItem: "SHOP_LIST_CLICK_SHOP_ITEM",
};

export const dispatchers = {
  handleClickShopItem: (shop: Shop) => ({
    type: actions.clickShopItem,
    shop
  }),
};

export default actions;
