import { put, take, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import actions from "./action";
import { updateShopAction } from "../../../store/shop/actions";
import Routes from "../../routes";
import { handleError } from "../../../store/sagas";
import EntityRepository from "../../../infra/repository/EntityRepository";
import uuid from "uuid";
import { addCurrentCommunicationAction, deleteCurrentCommunicationAction } from "../../../store/app/actions";

function handleClickShopItem(
    entityRepository: EntityRepository
) {
  return function* () {
    while (true) {
      const action = yield take(actions.clickShopItem);
      const communication = uuid();
      try {
        yield put(addCurrentCommunicationAction(communication));
        yield put(updateShopAction(action.shop));
        yield call(entityRepository.fetchAndUpdateOneShopInformation, action.shop.shop_id);
        yield put(push(Routes.menus));
      } catch (e) {
        yield handleError(e);
      } finally {
        yield put(deleteCurrentCommunicationAction(communication));
      }
    }
  };
}

function createSagas(
    entityRepository: EntityRepository
) {
  return [
    handleClickShopItem(entityRepository),
  ];
}

export default createSagas;
