import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { SphereSpinner } from "react-spinners-kit";
import styled from "styled-components";
import { fetchLineLoginQueryParameters } from "../../../util/parse_url_query";

export class LaunchScreenComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { isLineLogin : false };
    this.handleCheckInOrLineLogin = this.handleCheckInOrLineLogin.bind(this);
  }

  componentWillMount() {
    let isAuthChanged = false;
    firebase.auth().onAuthStateChanged(() => {
      if (!isAuthChanged) {
        isAuthChanged = true;
        this.handleCheckInOrLineLogin();
      }
    });
    setTimeout(
        () => {
          if (!isAuthChanged) {
            isAuthChanged = true;
            this.handleCheckInOrLineLogin();
          }
        },
        20000);
  }

  handleCheckInOrLineLogin() {
    const isLoggedIn = !!firebase.auth().currentUser;
    const queryParams = fetchLineLoginQueryParameters(window.location);
    if (queryParams && !isLoggedIn) {
      this.setState({ isLineLogin: true });
      this.props.lineLogin(queryParams.accessToken, queryParams.navigateTo);
    } else {
      this.setState({ isLineLogin: false });
      this.props.checkIn();
    }
  }

  render() {
    return (<Wrapper>
        <SphereSpinner
            size={40}
            color="#686769"
            />
        <div>
            <br/>
            {this.state.isLineLogin ?  "LINEでログイン中…" : "読み込み中…"}
            <br/>
        </div>
    </Wrapper>);
  }
}

interface IProps {
  checkIn: () => void;
  lineLogin: (accessToken: string, navigateTo: string) => void;
  getShop: () => void;
  navigateToMain?: boolean;
  navigateToError?: boolean;
}

interface IState {
  isLineLogin: boolean;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  display: -moz-box;
  display: -moz-flex;
  display: -webkit-box;  //この辺が
  display: -webkit-flex; //Android2.3~とか
  display: -ms-flexbox; //IE9~用
  display: flex; //最新のブラウザのみであればflexだけでもよいかも
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction:column;
`;
