import React from "react";
import styled from "styled-components";
export default class MyAccountList extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper>
        <Title>{this.props.title}</Title>
        <Data>{this.props.data}</Data>
      </Wrapper>
    );
  }
}

interface IProps {
  title: string;
  data: string;
}
interface IState {}

const Wrapper = styled.div`
  display: flex;
  padding: 16px 0px 16px 12px;
  border-bottom: 1px solid #f1f1f1;
  text-align: left;
  width: 100%;
`;

const Title = styled.div`
  width: 40%;
`;
const Data = styled.div`
  width: 60%;
  overflow: scroll;
`;
