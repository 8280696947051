import { connect } from "react-redux";
import State from "../../../store/state";
import { AddCardPageComponent } from "./component";
import { dispatchers } from "./action";

const mapStateToProps = (state: State) => {
  return {
    enableStripe: state.entity.shops.map(shop => shop.enable_stripe).some(v => v),
    brand: state.user.cards && state.user.cards.length > 0 ? state.user.cards[0].brand : undefined,
    last: state.user.cards && state.user.cards.length > 0 ? state.user.cards[0].last : undefined,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    reloadCard: () => {
      dispatch(dispatchers.fetchCard());
    }
  };
};

export const AddCardPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCardPageComponent);

export default AddCardPage;
