export const actions = {
  initializeOrders: "INITIALIZE_ORDERS",
};

export const dispatchers = {
  initializeOrders: () => ({
    type: actions.initializeOrders
  }),
};

export default actions;
