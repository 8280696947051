import React from "react";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import MoImage from "../../../assets/images/mo_image.svg";

export default class AboutMoPage extends React.Component<IProps, IState> {
  render() {
    return (
            <Wrapper>
                <TitleText>あなたのスマホが注文端末になる！</TitleText>
                <Image src={MoImage} style={{ margin: "0 auto 40px" }} />
                <Text>
                    モバイルオーダーとは、従来の
                    <br />
                    <Bold>「スタッフを呼び、注文を伝える」</Bold>
                    <br />
                    という行為を、
                    <br />
                    <Bold>スマホ一台</Bold>で済ませることができるサービスです。
                    <br />
                    <br />
                    そのまま<Bold>クレジット決済</Bold>もできるため、
                    <br />
                    面倒な会計もパスできます。
                    <br />
                    <br />
                    モバイルオーダーでスムーズなお食事を。
                </Text>
            </Wrapper>
    );
  }
}

interface IProps {}
interface IState {}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Image = styled(SemanticImage)`
  margin: 0 auto;
`;

const TitleText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 32px auto;
  text-align: center;
`;
const Text = styled.div`
  font-size: 14px;
  text-align: center;
  line-height: 24px;
`;
const Bold = styled.span`
  font-weight: bold;
`;
