import React from "react";
import styled from "styled-components";

export default class LoginSmallText extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper>
        ログインすることで<Link onClick={this.openTos}>利用規約</Link>および
        <br />
        <Link onClick={this.openPolicy}>プライバシポリシー</Link>に同意します
      </Wrapper>
    );
  }
  private openPolicy() {
    window.open("https://info.dinii.jp/privacy.html", "_blank");
  }
  private openTos() {
    window.open("https://info.dinii.jp", "_blank");
  }
}

interface IProps {}
interface IState {}
const Wrapper = styled.div`
  font-size: 12px;
  text-align: center;
  color: #d8d8d8;
`;
const Link = styled.span`
  font-size: 12px;
  color: #3273dc;
`;
