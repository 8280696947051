import React from "react";
import AddCard from "../../organisms/add_card/container";
import BottomModalHeader, { ActionImageType } from "../../components/molecules/bottom_modal_header";
import { Image } from "semantic-ui-react";
import StepCard from "../../../assets/images/step_card.svg";
import styled from "styled-components";

export class AddCardPageComponent extends React.Component<Props> {
  render() {
    return (
        <div>
            <BottomModalHeader onActionClick={this.props.onClickClose} actionImageSrcType={ActionImageType.close} text={"カード登録"} />
            <Wrapper>
                <Image src={StepCard} style={{ width: "240px", margin: "10px auto 5px" }}/>
                <AddCard sectionHeaderText={"カード情報"}/>
            </Wrapper>
        </div>
    );
  }
}

const Wrapper = styled.div`
  max-height: 70vh;
  overflow: scroll;
`;

interface Props {
  onClickClose: () => void;
}
