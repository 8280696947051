import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { color } from "../../../assets/colors";
import { OrderMenu } from "../../../infra/domain/entities";
import {
  CartMenuItem,
  CartMenuItemCartItem
} from "../../components/molecules/cart_menu_item";

export class CartComponent extends React.Component<IProps, {}> {
  render() {
    return (
      <div>
        <Title>注文内容</Title>
        <Border />
        <ContentWrapper>
          <Content>
            {this.props.cartItems.map((cartItem: OrderMenu, index) => {
              const cartMenuItem = this.translateDomainCartItemToCartMenuItemCartItem(
                cartItem
              );
              return (
                <CartMenuItem
                  key={index}
                  cartItem={cartMenuItem}
                  updateCartItem={(cartMenuItem: CartMenuItemCartItem) =>
                    this.props.updateCartItem(cartItem, cartMenuItem)
                  }
                  exTax={this.props.exTax}
                />
              );
            })}
          </Content>
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => {
              this.props.postOrder();
            }}
            color={color.orange}
            style={{ margin: "19px", fontSize: "16px", textAlign: "center" }}
          >
            <ButtonText>注文・決済へ進む</ButtonText>
          </Button>
        </ButtonWrapper>
      </div>
    );
  }
  private translateDomainCartItemToCartMenuItemCartItem(
    cartItem: OrderMenu
  ): CartMenuItemCartItem {
    return {
      name: cartItem.name,
      quantity: cartItem.quantity,
      price: cartItem.price,
      price_all: cartItem.price_all,
      options: cartItem.options
        ? cartItem.options.map(option => {
            return {
              choices: option.choices.map(choice => {
                return {
                  quantity: choice.quantity,
                  name: choice.name,
                  price: choice.price,
                  options: choice.options
                    ? choice.options.map(subOption => {
                        return {
                          choices: subOption.choices.map(subChoice => {
                            return {
                              quantity: subChoice.quantity,
                              name: subChoice.name
                            };
                          })
                        };
                      })
                    : []
                };
              })
            };
          })
        : []
    };
  }
}

interface IProps extends RouteComponentProps<{}> {
  // From container state
  cartItems: OrderMenu[];
  exTax: boolean;

  // From container dispacher
  updateCartItem: (
    cartItem: OrderMenu,
    cartMenuItem: CartMenuItemCartItem
  ) => void;
  postOrder: () => void;
}

const ContentWrapper = styled.div`
  // padding: 25px 25px 40px;
  width: 100%;
  max-height: 45vh;
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
`;
const Content = styled.div`
  width: 100%;
  padding: 0 5px;
  overflow: scroll;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button = styled.div`
  background: ${color.orange}
  border-radius: 5px;
  height: 45px;
  width: 200px;
  display: flex;
  align-items: center;
  jusitfy-content: center;
  margin: auto;
`;
const ButtonText = styled.div`
  margin: auto;
  color: white;
  // height: 20px;
  width: 200px;
  letter-spacing: 0.05rem;
`;

const Border = styled.div`
  width: 100%;
  border-top: 1px solid #f1f1f1;
`;
