/**
 * Company
 */
import { PaymentMethod } from "../../store/shop/state";

export interface Company {
  navi_image_url: string;
  company_name: string;
  stripe_platform_id: string;
  liff_id?: string;
}

/**
 *  Menus
 */
export interface Cart {
  shop_id?: string;
  cart_id?: string;
  price_all: number;
  price: number;
  tax_price: number;
  discount_amount?: number;
  delivery_price_all?: number;
  delivery_fee_amount?: number;
  order_menus: OrderMenu[];
}

/**
 *  Menus
 */
export interface Menu {
  shop_id: string;
  menu_id: string;
  name: string;
  price: number;
  priority: number;
  is_soldout: boolean;
  is_display: boolean;
  category_id: string;
  menu_type_id: string;
  current_num: number;
  max_num: number;
  options: null | Option[];
  description: string | null;
  meta: object | null;
  image_url: string | null;
}

/**
 *  Options
 */
export interface Option {
  company_id: string;
  is_required: boolean;
  is_multiple: boolean;
  priority: number;
  option_id: string;

  name: string;
  choices: Choice[];
  default_indexes: number[];
  is_soldout: boolean;
  shop_id: string;
  is_display: boolean;
  max_choice_num: number;
  min_choice_num: number;
}

/**
 *  Choices
 */
export interface Choice {
  choice_id: string;
  name: string;
  price: number;
  quantity: number;
  priority: number;
  is_soldout: boolean;
  is_recommended: boolean;
  is_display: boolean;
  image_url: string;
  sub_options?: Option[];
}

export interface MenuType {
  menu_type_id: string;
  name: string;
  priority: number;
  type: MenuConditionType;
  condition: MenuCondition;
}

export type MenuConditionType = "food" | "drink" | "special" | "free";

export type TimeCondition = {
  start: string;
  end: string;
};

export type PeriodCondition = {
  period: number;
};

export type FreePlanCondition = {
  plan_id: string;
};

export type MenuCondition = TimeCondition | PeriodCondition | FreePlanCondition;

/**
 *  OrderedItems
 */
export enum OrderType {
  TAKEOUT= "takeout",
  DELIVERY= "delivery"
}

export interface AddressInfo {
  address1: string;
  address2: string;
  zipCode: string;
}

export interface Order {
  company_id: string;
  shop_id: string;
  price_all: number;
  price: number;
  tax_price: number;
  discount_amount: number;
  order_id: string;
  ordered_at: number; // UnixTime, Cartで使う場合は、undefined
  is_canceled: boolean;
  refunded_amount?: number;
  menu_type_id: string;
  category_id: string;
  order_status: Status;
  is_paid: boolean;
  visit_at: number;
  order_menus: OrderMenu[];
  is_read: boolean;
  ir_printed: boolean;
  order_number: string;
  parent_order_id: string;
  order_type: OrderType;
  address_info: AddressInfo;
  phone_number: string;
  payment_method: PaymentMethod;
  delivery_price_all?: number;
  delivery_fee_amount?: number;
  paid_amount: number;
  nick_name?: string;
}

export interface OrderOption {
  option_id: string;
  name: string;
  choices: OrderChoice[];
}

export interface OrderChoice {
  choice_id: string;
  name: string;
  shop_display_name?: string;
  quantity: number;
  price: number;
  options?: OrderOption[];
}

export interface OrderMenu {
  category_id?: string;
  menu_id: string;
  menu_type_id?: string;
  name: string;
  options?: OrderOption[];
  price: number;
  price_all: number;
  quantity: number;
  shop_display_name?: string;
  remark?: string;
  id?: string;
  description: string;
}

export type Status = "preparing" | "ready" | "done";

export interface Slot {
  start: string; // HH:MM
  end: string; // HH:MM
  num: number;
  emergency_stop: {
    start: number; // unixtime
    end: number; // unixtime
  };
}

export interface Category {
  category_id: string;
  name: string;
  priority: number;
}
