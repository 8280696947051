import { call, take } from "redux-saga/effects";
import actions from "./action";
import uuidv4 from "uuid";
import { deleteCommunicationId, handleError, putCommunicationId, } from "../../../store/sagas";
import EntityRepository from "../../../infra/repository/EntityRepository";

function initializeOrder(
    entityRepository: EntityRepository,
) {
  return function* () {
    while (true) {
      yield take(actions.initializeOrders);
      const communication = uuidv4();
      try {
        yield putCommunicationId(communication);
        yield call(entityRepository.fetchAndUpdateOrders);
      } catch (e) {
        yield handleError(e);
      } finally {
        yield deleteCommunicationId(communication);
      }
    }
  };
}

function createSagas(
    entityRepository: EntityRepository
) {
  return [
    initializeOrder(entityRepository)
  ];
}

export default createSagas;
