import { connect } from "react-redux";
import { withRouter } from "react-router";
import { AccountMenuComponent } from "./component";
import State from "../../../store/state";
import firebase from "firebase/app";
import "firebase/auth";

const mapStateToProps = (state: State) => {
  return {
    isSignedIn: firebase.auth().currentUser !== null,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export const AccountMenu = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AccountMenuComponent)
);

export default AccountMenu;
