import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import LineIcon from "../../../assets/images/line_icon.svg";

export default class LineButton extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper>
        <Image src={LineIcon} style={{ padding: "0 27px 0 0" }} />
        <Text>LINEで新規登録/ログイン</Text>
      </Wrapper>
    );
  }
}

interface IProps {
  width?: string;
  height?: string;
}

interface IState {}

const Wrapper = styled.div`
  width: ${(props: IProps) => (props.width ? props.width : "300px")};
  height: ${(props: IProps) => (props.height ? props.height : "44px")};
  color: #fff;
  font-size: 14px;
  line-height: 44px;
  background: #00c300;
  border-radius: 4px;
  display: flex;
  padding-left: 12px;
`;

const Text = styled.div`
  line-height: 44px;
`;
