import { Route } from "react-router";
import LaunchScreen from "./pages/launch_screen/container";
import Shops from "./pages/shops/container";
import Menus from "./pages/menus/container";
import ConfirmOrder from "./pages/confirm_order/container";
import OrderDetail from "./pages/order_detail/container";
import OrderHistory from "./pages/order_history/container";
import MyAccount from "./pages/my_account/component";
import AddCardPage from "./pages/add_card_page/container";
import AboutMoPage from "./pages/about_mo/about_mo";
import FAQComponent from "./pages/faq/component";
import ErrorPage from "./pages/error_page/container";
import LogoutConfirmPageComponent from "./pages/logout_confirm_page/component";
import React from "react";
import Routes from "./routes";
import { AuthBoundarySwitch } from "./AuthBoundarySwitch";

export const Router = () => (
  <AuthBoundarySwitch>
    <Route exact path={Routes.default} component={LaunchScreen} />
    <Route exact path={Routes.shops} component={Shops} />
    <Route exact path={Routes.menus} component={Menus} />
    <Route
        exact
        path={Routes.confirmOrder}
        component={ConfirmOrder}
    />
    <Route
        exact
        path={Routes.orderDetail}
        component={OrderDetail}
    />
    <Route exact path={Routes.history} component={OrderHistory} />
    <Route exact path={Routes.account} component={MyAccount} />
    <Route exact path={Routes.addCard} component={AddCardPage} />
    <Route
        exact
        path={Routes.aboutMobileOrder}
        component={AboutMoPage}
    />
    <Route exact path={Routes.faq} component={FAQComponent} />
    <Route exact path={Routes.error} component={ErrorPage} />
    <Route
        exact
        path={Routes.logoutConfirm}
        component={LogoutConfirmPageComponent}
    />
    <Route component={LaunchScreen} />
  </AuthBoundarySwitch>
);
