import { Menu } from "../../../infra/domain/entities";

export const actions = {
  clickMenuItem: "MENU_ITEM_LIST_CLICK_MENU_ITEM",
};

export const dispatchers = {
  handleClickMenuItem: (menu: Menu) => ({
    type: actions.clickMenuItem,
    menu
  }),
};

export default actions;
