import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShopMap from "../../components/atoms/shop_map";
import TimeDropdown from "../../components/atoms/time_dropdown";
import SecondaryHeader from "../../components/atoms/secondary_header";
import injector from "../../../infra/injector";
import { useDispatch, useSelector } from "react-redux";
import { accessTokenSelector, shopSelector } from "../../../store/selectors";
import { dispatchError } from "../../../infra/errors";
import { Button, Icon, Image } from "semantic-ui-react";
import bagIcon from "../../../assets/images/bag_icon.svg";
import timeIcon from "../../../assets/images/timeIcon.svg";
import { OrderType } from "../../../infra/domain/entities";
import TakeoutText from "../../components/atoms/takeout_text";
import DeliveryText from "../../components/atoms/delivery_text";
import DateDropdown from "../../components/atoms/date_dropdown";

export const ConfirmOrderInfoComponent: React.FC<IProps> = (props: IProps) => {
  const [visitTimes, setVisitTimes] = useState([] as string[]);
  const [visitDates, setVisitDates] = useState([] as string[]);

  // FIXME: tokenやshopIdがない場合のエラーハンドリング
  const accessToken = useSelector(accessTokenSelector) || "";
  const shopId = useSelector(shopSelector).shop_id || "";

  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.visitDate) {
      const f = async () => {
        try {
          const idToken = await injector.firebaseClient.getIdToken();
          const visitDates = await injector.apiClient.listVisitDates(
              shopId,
              accessToken,
              idToken,
              props.orderType === OrderType.DELIVERY);
          if (!props.visitDate && visitDates.length > 0) {
            // props.onChangeVisitDate(visitDates[0]);
            setVisitDates(visitDates);
          }
        } catch (e) {
          await dispatchError(e, dispatch);
        }
      };
      f();
    }
  },        [props, accessToken, shopId, dispatch]);

  useEffect(() => {
    if (!props.visitTime) {
      const f = async () => {
        try {
          const idToken = await injector.firebaseClient.getIdToken();
          const visitTimes = await injector.apiClient.listVisitTimes(
              shopId,
              accessToken,
              idToken,
              props.orderType === OrderType.DELIVERY,
              props.visitDate
          );
          if (!props.visitTime && visitTimes.length > 0) {
            // props.onChangeVisitTime(visitTimes[0]);
            setVisitTimes(visitTimes);
          }
        } catch (e) {
          await dispatchError(e, dispatch);
        }
      };
      f();
    }
  },        [props, accessToken, shopId, dispatch]);

  return (
    <Wrapper>
      <SecondaryHeader>受取場所・時間</SecondaryHeader>
      <OrderTypeSelectorLayout>
        <div style={{ display: "flex", alignItems:"center" }}>
          {props.enableDelivery && props.enableTakeout ? (
            <>
              <Image src={bagIcon} style={{ width: "20px", height: "20px", marginRight: "14px" }} />
              <Button.Group>
                <Button
                  color="black"
                  inverted
                  onClick={() => props.onChangeOrderType(OrderType.DELIVERY)}
                  style={props.orderType === OrderType.DELIVERY ? activeButtonStyle : inactiveButtonStyle}>
                  デリバリー
                </Button>
                <Button
                    color="black"
                    inverted
                    onClick={() => props.onChangeOrderType(OrderType.TAKEOUT)}
                    style={props.orderType === OrderType.TAKEOUT ? activeButtonStyle : inactiveButtonStyle}>
                    テイクアウト
                </Button>
              </Button.Group>
            </>
          ) : (
            <>
              {props.enableTakeout && <TakeoutText />}
              {props.enableDelivery && <DeliveryText />}
            </>
          )}
        </div>
      </OrderTypeSelectorLayout>

      <Border />

      <MapLayout>
        <ShopMap shop_name={props.shop_name} onClick={() => props.mapUrl && window.open(props.mapUrl)} />
      </MapLayout>

      <Border />

      <DateLayout>
        <Icon name="calendar alternate outline" size={"large"} style={{ marginRight: "10px" }}/>
        <DateDropdown
          currentDate={props.visitDate}
          dateList={visitDates}
          onChange={props.onChangeVisitDate}
        />
      </DateLayout>

      <Border />

      <TimeLayout>
        <Image src={timeIcon} style={{ marginRight: "14px" }} />
        <TimeDropdown
          currentTime={props.visitTime}
          timeList={visitTimes}
          onChange={props.onChangeVisitTime}
        />
        {props.orderType === OrderType.TAKEOUT ? (<Text>頃 に受取</Text>) : (<Text>頃 配達希望</Text>) }
      </TimeLayout>
    </Wrapper >
  );
};

interface IProps {
  visitTime?: string;
  visitDate?: string;
  onChangeVisitTime: (visitTime: string) => void;
  onChangeVisitDate: (visitDate: string) => void;
  shop_name?: string;
  onChangeOrderType: (orderType: OrderType) => void;
  orderType: OrderType;
  enableTakeout: boolean;
  enableDelivery: boolean;
  mapUrl?: string;
}

const activeButtonStyle = {
  background: "#f47a55",
  color: "#ffffff"
};

const inactiveButtonStyle = {
  background: undefined,
  color: "#bbbbbb"
};

const Wrapper = styled.div`
  width: 100%;
`;

const MapLayout = styled.div`
  padding-left: 32px;
`;

const DateLayout = styled.div`
  padding-left: 32px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

const TimeLayout = styled.div`
  padding-left: 32px;
  padding-bottom: 14px;
  display: flex;
`;

const Border = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin: 13px 22px;
`;

const Text = styled.div`
  margin: 12px;
`;

const OrderTypeSelectorLayout = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 15px;
`;
