export function getDisplayHeight(): number {
  return window.innerHeight;
}

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  }
  return "visibilitychange";
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  }
  return "hidden";
}
