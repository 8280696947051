import React from "react";
import styled, { css } from "styled-components";
import posed, { PoseGroup } from "react-pose";
import { Modal } from "../../../store/app/state";
import { color } from "../../../assets/colors";
import { ZIndexSettings } from "../../config/ZIndexSettings";
import { EventGa } from "../../components/middleware/ga";

export class CartButtonComponent extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isPresentCart: true,
      timeoutID: null
    };
  }

  render() {
    return (
      <div>
        <PoseGroup>
          {this.props.isCartIn && [
            <CartButtonWrapper
              active={this.props.isCartIn}
              className="cart-button"
              key="cart-button"
            >
              <Button
                onClick={() => {
                  EventGa.addCart();
                  this.props.showOrderConfirmModal();
                }}
              >
                <CartCount>{this.props.cartCount}</CartCount>
                <ButtonText>カートを確認</ButtonText>
              </Button>
            </CartButtonWrapper>
          ]}
        </PoseGroup>
      </div>
    );
  }
}

interface IProps {
  isCartIn: boolean;
  cartCount: number;
  modal: Modal;
  showOrderConfirmModal: () => void;
}

const CartPose = posed.div({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

const CartButtonWrapper = styled(CartPose)`
  position: fixed;
  width: 90%;
  left: 5%;
  z-index: ${ZIndexSettings.CART_BUTTON};
  bottom: 30px;
  ${(props: { active: boolean }) =>
  props.active
    ? css``
    : css`
          display: none;
        `}
`;
const Button = styled.div`
  height: 55px;
  width: 100%;
  background: ${color.orange};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const ButtonText = styled.div`
  color: white;
  text-align: center;
  font-size: 18px;
  height: 18px;
  letter-spacing: 0.2rem;
`;

const CartCount = styled.div`
  margin: 5px 15px 5px 5px;
  background: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  color: ${color.orange};
  text-align: center;
`;
