import State from "../../../store/state";
import { connect } from "react-redux";
import { HowToGetItemComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const HowToGetItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(HowToGetItemComponent);

export default HowToGetItem;
