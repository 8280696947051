import { connect } from "react-redux";
import { withRouter } from "react-router";
import State from "../../../store/state";
import { ErrorPageComponent } from "./component";
import { push } from "connected-react-router";
import router from "../../routes";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    backToHome: () => {
      dispatch(push(router.default));
    }
  };
};

export enum ErrorDisplayType {
  UNKNOWN = "unknown",
  NO_TABLE = "no_table",
  TOKEN_EXPIRED = "token_expired"
}

export function createErrorRoute(type: ErrorDisplayType): string {
  return `/error/${type}`;
}

export const ErrorPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ErrorPageComponent));

export default ErrorPage;
