import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import MailLoginButton from "../atoms/mail_login_button";
import MailIcon from "../../../assets/images/mail_icon.svg";

export default class MailLoginButtonSet extends React.Component<
  IProps,
  IState
> {
  render() {
    return (
      <div>
        <TextSection>
          <Image src={MailIcon} style={{ padding: "0 10px 0 0" }} />
          <p>メールアドレスで新規登録/ログイン</p>
        </TextSection>
        <ButtonSection>
          <MailLoginButton onClick={() => this.props.signupButtonClick()}>
            新規登録
          </MailLoginButton>
          <MailLoginButton onClick={() => this.props.signinButtonClick()}>
            ログイン
          </MailLoginButton>
        </ButtonSection>
      </div>
    );
  }
}

interface IProps {
  signupButtonClick: () => void;
  signinButtonClick: () => void;
}
interface IState {}

const ButtonSection = styled.div`
  width: ${(props: { width?: string }) =>
    props.width ? props.width : "300px"};
  display: flex;
  justify-content: space-between;
`;

const TextSection = styled.div`
  display: flex;
  margin-bottom: 11px;
  color: #777;
`;
