import React from "react";
import styled from "styled-components";
import Complete from "../../components/molecules/complete/component";

import WithModal from "../../components/middleware/with_modal";
import WithPopUp from "../../components/middleware/with_pop_up";
import { Modal, PopUpModal } from "../../../store/app/state";

import { RouteComponentProps } from "react-router";
import CustomError from "../../../infra/errors";
import Cart from "../../organisms/cart/container";
import AddCard from "../../pages/add_card_bottom_sheet/container";
import ErrorModal from "../../organisms/error_modal/container";
import MenuDetail from "../../organisms/menu_detail/container";
import SignIn from "../../organisms/sign_in/container";
import MailSignUp from "../../organisms/mail_sign_up/container";
import MailSignIn from "../../organisms/mail_sign_in/container";
import ShopMap from "../../organisms/shop_map/container";

const MenuDetailModal = WithModal(MenuDetail);
const CartModal = WithModal(Cart);
const SignInModal = WithModal(SignIn);
const SignInCompleteModal = WithPopUp(Complete);
const SignOutCompleteModal = WithPopUp(Complete);
const OrderCompleteModal = WithPopUp(Complete);
const CardRegisterCompleteModal = WithPopUp(Complete);
const UserUpdateCompleteModal = WithPopUp(Complete);
const SendEmailVerificationCompleteModal = WithPopUp(Complete);
const AddCardModal = WithModal(AddCard);
const MailSignInModal = WithModal(MailSignIn);
const MailSignUpModal = WithModal(MailSignUp);
const ShopMapModal = WithModal(ShopMap);

export class ModalManagerComponent extends React.Component<IProps, {}> {
  render() {
    return (
      <ModalWrapper>
        <ErrorModal />
        <ShopMapModal
          isModal={this.props.modal === Modal.ShopMap}
          width="90%"
          modalClose={this.props.closeModal}
          noPadding={true}
          modalName={this.props.modal}
        />
        <MenuDetailModal
          isModal={this.props.modal === Modal.ItemDetail}
          width="90%"
          modalClose={this.props.closeModal}
          noPadding={true}
          modalName={this.props.modal}
        />
        <CartModal
          isModal={this.props.modal === Modal.CartConfirm}
          width="90%"
          modalClose={this.props.closeModal}
          noPadding={true}
          modalName={this.props.modal}
          isBottomModal={true}
        />
        <SignInModal
          isModal={this.props.modal === Modal.SignIn}
          modalClose={this.props.closeModal}
          noPadding={true}
          modalName={this.props.modal}
          isBottomModal={true}
        />
        <MailSignInModal
          isModal={this.props.modal === Modal.MailSignin}
          modalClose={this.props.closeModal}
          noPadding={true}
          isBottomModal={true}
        />
        <MailSignUpModal
          isModal={this.props.modal === Modal.MailSignup}
          modalClose={this.props.closeModal}
          noPadding={true}
          isBottomModal={true}
        />
        <SignInCompleteModal
          isPopUp={this.props.popUp === PopUpModal.SignInComplete}
          popUpClose={this.props.closePopUp}
          autoClose={true}
          text={"ログイン完了"}
        />
        <SignOutCompleteModal
          isPopUp={this.props.popUp === PopUpModal.SignOutComplete}
          popUpClose={this.props.closePopUp}
          autoClose={true}
          text={"ログアウト完了"}
        />
        <AddCardModal
          isModal={this.props.modal === Modal.AddCard}
          modalClose={this.props.closeModal}
          noPadding={true}
          modalName={this.props.modal}
          isBottomModal={true}
          hasStepHeader={true}
        />
        <OrderCompleteModal
          isPopUp={this.props.popUp === PopUpModal.OrderComplete}
          popUpClose={this.props.closePopUp}
          autoClose={true}
          text={"注文完了"}
        />
        <CardRegisterCompleteModal
          isPopUp={this.props.popUp === PopUpModal.CardRegisterComplete}
          popUpClose={this.props.closePopUp}
          autoClose={true}
          text={"カード登録完了"}
        />
        <UserUpdateCompleteModal
          isPopUp={this.props.popUp === PopUpModal.UpdateUserComplete}
          popUpClose={this.props.closePopUp}
          autoClose={true}
          text={"ユーザ名変更完了"}
        />
        <SendEmailVerificationCompleteModal
          isPopUp={this.props.popUp === PopUpModal.SendEmailVerificationComplete}
          popUpClose={this.props.closePopUp}
          autoClose={true}
          text={"確認メールが送信されました"}
        />
      </ModalWrapper>
    );
  }
}

interface IProps extends RouteComponentProps<any> {
  modal: Modal;
  popUp: PopUpModal;
  closeModal: () => void;
  closeErrorModal: () => void;
  closePopUp: () => void;
  currentError: CustomError | undefined;
  loading: boolean;
}

const ModalWrapper = styled.div``;
