import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import received from "../../../assets/images/received_icon.svg";
import unreceived from "../../../assets/images/unreceived_icon.svg";
import { AddressInfo, OrderType } from "../../../infra/domain/entities";
import { PaymentMethod } from "../../../store/shop/state";
import { parseDate, parseTime } from "../../../util/date_text_utils";

const parseOrderType = (orderType: OrderType): string => OrderType.TAKEOUT === orderType ? "テイクアウト" : "デリバリー";

const parsePaymentMethod = (paymentMethod: PaymentMethod, orderType: OrderType): string => {
  switch (orderType) {
    case OrderType.TAKEOUT:
      switch (paymentMethod) {
        case PaymentMethod.CARD:
          return "ネット決済";
        case PaymentMethod.CASH:
          return "現地決済";
      }
      break;
    case OrderType.DELIVERY:
      switch (paymentMethod) {
        case PaymentMethod.CARD:
          return "ネット決済";
        case PaymentMethod.CASH:
          return "配達員にお支払い";
      }
      break;
  }
  return "";
};

export class OrderDetailInfoComponent extends React.Component<IProps> {
  render() {
    if (
      !this.props.payment_method ||
      !this.props.order_type ||
      !this.props.order_number ||
      !this.props.order_status ||
      !this.props.shop_name ||
      !this.props.visit_at
    ) {
      return <div>注文がありません</div>;
    }

    switch (this.props.order_type) {
      case OrderType.TAKEOUT:
        return (
            <Wrapper>
              <OrderNumberWrapper>

                <OrderNumberText>予約番号</OrderNumberText>

                <ReceivedIcon>
                  <Number>{this.props.order_number}</Number>
                  {this.props.order_status === "done" ? (
                      <Image
                          src={received}
                          style={{
                            position: "absolute",
                            padding: "20px 0px 0px 200px"
                          }}
                      />
                  ) : (
                      <Image
                          src={unreceived}
                          style={{
                            position: "absolute",
                            padding: "20px 0px 0px 200px"
                          }}
                      />
                  )}
                </ReceivedIcon>

                <Text>こちらの番号を店員にお伝えください</Text>

                {this.props.is_canceled &&
                  <div>
                    <div style={{ fontSize: "20px", margin: "10px", color:"red" }}>キャンセル済み</div>
                  </div>
                }

                {this.props.nick_name &&
                  <div>
                    <div style={{ fontSize: "15px", margin: "10px" }}>受取者名: {this.props.nick_name}</div>
                  </div>
                }

              </OrderNumberWrapper>

              <OrderTypeAndPaymentMethod>
                <OrderTypeWrap>
                  <OrderTypeText>オーダー種別</OrderTypeText>
                  <OrderTypeName>{parseOrderType(this.props.order_type)}</OrderTypeName>
                </OrderTypeWrap>
                <PaymentMethodWrap>
                  <PaymentMethodText>決済方法</PaymentMethodText>
                  <PaymentMethodName>
                    {parsePaymentMethod(this.props.payment_method,
                                        this.props.order_type)}
                  </PaymentMethodName>
                </PaymentMethodWrap>
              </OrderTypeAndPaymentMethod>

              <ShopAndTime>
                <Shop>
                  <ShopText>店舗</ShopText>
                  <ShopName>{this.props.shop_name}</ShopName>
                </Shop>
                <Time>
                  <TimeText>受取時間</TimeText>
                  <GetDate>{parseDate(this.props.visit_at)}</GetDate>
                  <GetTime>{parseTime(this.props.visit_at)}</GetTime>
                </Time>
              </ShopAndTime>

            </Wrapper>
        );
      case OrderType.DELIVERY:
        return (
            <Wrapper>
              <OrderNumberWrapper>
                <OrderNumberText>予約番号</OrderNumberText>
                <ReceivedIcon>
                  <Number>{this.props.order_number}</Number>
                  {this.props.order_status === "done" ? (
                      <Image
                          src={received}
                          style={{
                            position: "absolute",
                            padding: "20px 0px 0px 200px"
                          }}
                      />
                  ) : (
                      <Image
                          src={unreceived}
                          style={{
                            position: "absolute",
                            padding: "20px 0px 0px 200px"
                          }}
                      />
                  )}
                </ReceivedIcon>
                <Text>こちらの番号を配達員にお伝えください</Text>

                {this.props.nick_name &&
                <div>
                  <div style={{ fontSize: "15px", margin: "10px" }}>受取者名: {this.props.nick_name}</div>
                </div>
                }

                {this.props.is_canceled &&
                  <div>
                    <div style={{ fontSize: "20px", margin: "10px", color:"red" }}>キャンセル済み</div>
                  </div>
                }

              </OrderNumberWrapper>

              <OrderTypeAndPaymentMethod>
                <OrderTypeWrap>
                  <OrderTypeText>オーダー種別</OrderTypeText>
                  <OrderTypeName>{parseOrderType(this.props.order_type)}</OrderTypeName>
                </OrderTypeWrap>
                <PaymentMethodWrap>
                  <PaymentMethodText>決済方法</PaymentMethodText>
                  <PaymentMethodName>
                    {parsePaymentMethod(this.props.payment_method,
                                        this.props.order_type)}
                  </PaymentMethodName>
                </PaymentMethodWrap>
              </OrderTypeAndPaymentMethod>

              <ShopAndTime>
                <Shop>
                  <ShopText>店舗</ShopText>
                  <ShopName>{this.props.shop_name}</ShopName>
                </Shop>
                <Time>
                  <TimeText>配達希望時刻</TimeText>
                  <GetDate>{parseDate(this.props.visit_at)}</GetDate>
                  <GetTime>{parseTime(this.props.visit_at)}</GetTime>
                  <DeliveryNote>
                    混雑状況によりお時間が前後する可能性がございます
                  </DeliveryNote>
                </Time>
              </ShopAndTime>

            </Wrapper>
        );
    }
  }
}

interface IProps {
  order_type?: OrderType;
  address_info?: AddressInfo;
  payment_method?: PaymentMethod;
  order_number?: string;
  order_status?: string;
  shop_name?: string;
  visit_at?: number;
  nick_name?: string;
  is_canceled?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
`;
const OrderNumberWrapper = styled.div`
  text-align: center;
`;
const OrderNumberText = styled.div`
  padding-top: 25px;
  font-size: 16px;
`;
const ReceivedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Number = styled.div`
  font-size: 48px;
  padding-top: 23px;
  height: 48px;
`;
const Text = styled.div`
  padding-top: 20px;
  padding-bottom:18px;
  font-size: 10px;
`;
const OrderTypeAndPaymentMethod = styled.div`
  margin: 0px 25px;
  border-top: 1px solid #f1f1f1;
  display: flex;
  padding: 10px 0px;
`;
const ShopAndTime = styled.div`
  margin: 0px 25px;
  border-top: 1px solid #f1f1f1;
  display: flex;
  padding: 10px 0px;
`;
const Shop = styled.div`
  border-right: 1px solid #f1f1f1;
  text-align: center;
  width: 50%;
`;
const ShopText = styled.div`
  padding-top: 14px;
`;
const ShopName = styled.div`
  padding: 9px 0px 17px 0px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;

`;
const Time = styled.div`
  width: 50%;
  text-align: center;
`;
const TimeText = styled.div`
  padding-top: 14px;
`;
const OrderTypeWrap = styled.div`
  border-right: 1px solid #f1f1f1;
  text-align: center;
  width: 50%;
`;
const OrderTypeText = styled.div`
  padding-top: 14px;
`;
const OrderTypeName = styled.div`
  padding: 9px 0px 17px 0px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`;
const PaymentMethodWrap = styled.div`
  border-right: 1px solid #f1f1f1;
  text-align: center;
  width: 50%;
`;
const PaymentMethodText = styled.div`
  padding-top: 14px;
`;
const PaymentMethodName = styled.div`
  padding: 9px 0px 17px 0px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`;
const GetDate = styled.div`
  padding: 9px 0px 5px 0px;
  font-size: 17px;
  font-weight: bold;
`;
const GetTime = styled.div`
  padding: 9px 0px 17px 0px;
  font-size: 25px;
  font-weight: bold;
`;
const DeliveryNote = styled.div`
  padding: 5px;
  font-size: 12px;
  color: #4a4a4a;
`;
