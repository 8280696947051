import styled from "styled-components";

const MailLoginButton = styled.button`
  width: ${(props: IProps) => (props.width ? props.width : "146px")};
  height: ${(props: IProps) => (props.height ? props.height : "44px")};
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  color: #777;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #777;
  display: block;
`;

interface IProps {
  width?: string;
  height?: string;
}

export default MailLoginButton;
