import React from "react";
import LINEButton from "../atoms/line_button";

export default class LINELoginButton extends React.Component<IProps, IState> {
  render() {
    return (
      <div>
        <div onClick={this.props.onLINEButtonClick}>
          <LINEButton />
        </div>
      </div>
    );
  }
}

interface IProps {
  width?: string;
  onLINEButtonClick: () => void;
}

interface IState {}
