import React from "react";
import OrderHistoryList from "../../organisms/order_history_list/container";

export class OrderHistoryComponent extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    props.initializeOrders();
  }

  render() {
    return(
      <OrderHistoryList/>
    );
  }
}

interface IProps {
  initializeOrders: () => void;
}
