import { connect } from "react-redux";
import { MenuDetailComponent } from "./component";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { Modal } from "../../../store/app/state";
import { updateModalAction } from "../../../store/app/actions";
import { OrderMenu, Menu } from "../../../infra/domain/entities";

const mapStateToProps = (state: State) => {
  const activeMenu = state.app.activeMenu as Menu;
  const isOpen = state.shop.activeShop.is_open;
  const closeText = state.shop.activeShop.close_text;
  const exTax = state.shop.activeShop.ex_tax || false;
  const disableRemark = !!state.shop.activeShop.disable_remark;
  const cartItem =
    activeMenu && activeMenu.options
      ? ({
        name: activeMenu.name,
        menu_id: activeMenu.menu_id,
        quantity: 1,
        remark: "",
        options: activeMenu.options!.map(option => ({
          option_id: option.option_id,
          name: option.name,
          choices: option.choices.map(choice => ({
            choice_id: choice.choice_id,
            name: choice.name,
            quantity: 0,
            price: choice.price,
            options: choice.sub_options
              ? choice.sub_options.map(sub_option => ({
                option_id: sub_option.option_id,
                name: sub_option.name,
                choices: sub_option.choices.map(subChoice => ({
                  choice_id: subChoice.choice_id,
                  name: subChoice.name,
                  quantity: 0,
                  price: subChoice.price
                }))
              }))
              : []
          }))
        }))
      } as OrderMenu)
      : undefined;

  return {
    menu: activeMenu,
    cartItem,
    isOpen,
    closeText,
    exTax,
    disableRemark
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clickAddCartItem: (cartItem: OrderMenu) =>
      dispatch(dispatchers.handleClickAddCart(cartItem)),
    clickCloseModal: () => dispatch(updateModalAction(Modal.NoModal)),
    loadMenu: (menuId: string) => dispatch(dispatchers.handleLoadMenu(menuId))
  };
};

export const MenuDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuDetailComponent);

export default MenuDetail;
