import {call, put, take} from "redux-saga/effects";
import actions from "./action";
import {Modal} from "../../../store/app/state";
import {updateActiveMenuItemAction, updateModalAction} from "../../../store/app/actions";
import {handleError} from "../../../store/sagas";
import AppRepository from "../../../infra/repository/AppRepository";

function handleClickMenuItem(
    appRepository: AppRepository
) {
  return function* () {
    while (true) {
      const action = yield take(actions.clickMenuItem);
      try {
        yield put(updateActiveMenuItemAction(action.menu));
        yield put(updateModalAction(Modal.ItemDetail));
        yield call(appRepository.fetchAndUpdateActiveMenu, action.menu.shop_id, action.menu.menu_id);
      } catch (e) {
        yield handleError(e);
      } finally {
      }
    }
  };
}

function createSagas(
    appRepository: AppRepository
) {
  return [
    handleClickMenuItem(appRepository),
  ];
}

export default createSagas;
