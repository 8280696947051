import { call, put, take, all, select } from "redux-saga/effects";
import actions from "./action";
import { handleError } from "../../../store/sagas";
import { push } from "connected-react-router";
import { createErrorRoute, ErrorDisplayType } from "../error_page/container";
import EntityRepository from "../../../infra/repository/EntityRepository";
import UserRepository from "../../../infra/repository/UserRepository";
import ShopRepository from "../../../infra/repository/ShopRepository";
import Routes from "../../routes";
import { Company } from "../../../infra/domain/entities";
import { getCompany } from "../../organisms/sign_in/saga";
import { updateAccessTokenAction } from "../../../store/user/actions";
import { updateCurrentErrorAction, updateModalAction, updatePopUpAction } from "../../../store/app/actions";
import { Modal, PopUpModal } from "../../../store/app/state";
import CustomError from "../../../infra/errors";

function lineLogin(
    userRepository: UserRepository,
    entityRepository: EntityRepository,
    shopRepository: ShopRepository
) {
  return function* () {
    while (true) {

      const lineLoginActions = yield take(actions.lineLogin);
      const navigateTo = lineLoginActions.navigateTo;
      const accessToken = lineLoginActions.accessToken;
      yield put(updateAccessTokenAction(accessToken));

      const shop = yield select(shopRepository.selectCurrentShop);
      const company: Company | undefined = yield select(getCompany);
      if (!company) {
        console.error("no company info");
        continue;
      }
      if (!company.liff_id) {
        console.error("no liff id");
        continue;
      }

      const firebaseCustomLoginError: Error | void = yield call(userRepository.firebaseCustomLoginByLineID,
                                                                company.liff_id!,
      );

      if (firebaseCustomLoginError) {
        yield put(updateCurrentErrorAction(new CustomError(
            "こちらの店舗ではLINEログインに対応しておりません",
            "メールアドレスでのログインをお試しください"
        )));
      } else {
        yield call(userRepository.userConnect);
        const shopId = yield call(shopRepository.selectCurrentShopId);
        yield all([
          call(entityRepository.fetchAndUpdateCart, shopId),
          shop.enable_stripe && call(userRepository.fetchCardsAndStore)
        ]);

        const cart = yield call(entityRepository.selectCart);

        console.log("check to add card if:", shop);
        if (shop.enable_stripe) {
          const cards = yield call(userRepository.selectCards);
          if (cards.length === 0) {
            yield put(updateModalAction(Modal.AddCard));
            continue;
          }
        }

        if (cart.order_menus.length > 0) {
          yield put(updateModalAction(Modal.NoModal));
          yield put(push(Routes.confirmOrder));
        } else {
          yield put(updateModalAction(Modal.NoModal));
          yield put(updatePopUpAction(PopUpModal.SignInComplete));
        }

        if (navigateTo) {
          yield put(push(Routes.menus));
        }
      }
    }
  };
}

function handleCheckIn(
    userRepository: UserRepository,
    entityRepository: EntityRepository,
    shopRepository: ShopRepository
) {
  return function* () {
    while (true) {

      yield take(actions.checkIn);

      try {

        yield call(userRepository.fetchAndStoreAccessToken);

        const isSignedIn = yield call(userRepository.isFirebaseSignedIn);

        if (isSignedIn) {
          yield call(userRepository.userConnect);
          const shop = yield call(shopRepository.selectCurrentShop);
          // NOTE: ここでカードを取得する必要はない
          yield call(entityRepository.fetchAndUpdateCart, shop.shop_id);
        }

        yield put(push(Routes.shops));

      } catch (error) {
        yield handleError(error);
        yield put(push(createErrorRoute(ErrorDisplayType.NO_TABLE)));
      }
    }
  };
}

const createSagas = (
  userRepository: UserRepository,
  entityRepository: EntityRepository,
  shopRepository: ShopRepository
) => [
  handleCheckIn(userRepository, entityRepository, shopRepository),
  lineLogin(userRepository, entityRepository, shopRepository)
];

export default createSagas;
