import State from "../store/state";
import URLs from "../config/URL";
import firebase from "firebase/app";
import "firebase/auth";
import { connect } from "react-redux";
import App from "./App";
import {
  addCurrentCommunicationAction,
  deleteCurrentCommunicationAction,
  updateCurrentErrorAction,
  updateModalAction,
  updatePopUpAction
} from "../store/app/actions";
import CustomError from "../infra/errors";
import { all, call, put, select, take } from "redux-saga/effects";
import { getCards, getCart, handleError } from "../store/sagas";
import { Modal, PopUpModal } from "../store/app/state";
import uuid from "uuid";
import UserRepository from "../infra/repository/UserRepository";
import EntityRepository from "../infra/repository/EntityRepository";
import ShopRepository from "../infra/repository/ShopRepository";
import { Cart } from "../infra/domain/entities";
import { push } from "connected-react-router";
import Routes from "./routes";

const handleRedirectAction = "APP_HANDLE_REDIRECT";
const handleRedirectDispatcher = (
  credential: firebase.auth.UserCredential
) => ({
  type: handleRedirectAction,
  credential
});

export function createAppRedirectSaga(
  userRepository: UserRepository,
  entityRepository: EntityRepository,
  shopRepository: ShopRepository
) {
  return function* handleRedirect() {
    while (true) {
      const action = yield take(handleRedirectAction);
      const communication = uuid();
      try {
        yield put(addCurrentCommunicationAction(communication));
        const credential = action.credential;
        yield put(updateModalAction(Modal.NoModal));

        if (
          !credential.user.emailVerified &&
          credential.signInMethod ===
            firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
        ) {
          yield call(credential.user.sendEmailVerification);
        }

        yield call(userRepository.userConnect);

        const shop = yield call(shopRepository.selectCurrentShop);

        yield all([
          shop.enable_stripe && call(userRepository.fetchCardsAndStore),
          call(entityRepository.fetchAndUpdateCart, shop.shop_id)
        ]);

        const cart: Cart = yield select(getCart);

        if (shop.enable_stripe) {
          const cards = yield select(getCards);
          if (shop.enable_stripe && cards.length === 0) {
            yield put(updateModalAction(Modal.AddCard));
            continue;
          }
        }

        if (cart.order_menus.length > 0) {
          yield put(updateModalAction(Modal.NoModal));
          yield put(push(Routes.confirmOrder));
        } else {
          yield put(updatePopUpAction(PopUpModal.SignInComplete));
        }
      } catch (e) {
        yield call(userRepository.signOut);
        yield handleError(e);
      } finally {
        yield put(deleteCurrentCommunicationAction(communication));
      }
    }
  };
}

const mapStateToProps = (state: State) => {
  let STP_KEY: string = URLs.STP_KEY_DINII;
  if (state.entity.company) {
    switch (state.entity.company.stripe_platform_id) {
      case "dinii":
        STP_KEY = URLs.STP_KEY_DINII;
        break;
      case "dinii_ff":
        STP_KEY = URLs.STP_KEY_DINII_FF;
        break;
    }
  }
  return {
    app: state.app,
    stripeKey: STP_KEY,
    isLoading: state.app.isLoading,
    appName: state.entity.company
      ? state.entity.company.company_name
      : "Dinii For"
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleRedirect: (credential: firebase.auth.UserCredential) =>
      dispatch(handleRedirectDispatcher(credential)),
    onRedirectError: () =>
      dispatch(
        updateCurrentErrorAction(
          new CustomError(
            "リダイレクトエラー",
            "リダイレクト処理に失敗しました"
          )
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
