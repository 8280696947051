import React from "react";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import timeIcon from "../../../assets/images/timeIcon.svg";

export default class ShopDate extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper color={this.props.color}>
        <Image src={timeIcon} style={{ Width: "14px", height: "14px" }} />
        {this.props.open} - {this.props.close}
      </Wrapper>
    );
  }
}

interface IProps {
  open: string;
  close: string;
  color?: string;
}
interface IState {}

const Image = styled(SemanticImage)`
  margin-right: 8px;
`;

const Wrapper = styled.div`
  color: ${(props: { color?: string }) =>
    props.color ? props.color : "black"};
  display: flex;
  line-height: 1.2;
  font-size: 12px;
`;
