import { Shop } from "../../infra/domain/shop";

export interface ShopState {
  activeShop: Shop;
  activeVisitTimes?: string[];
}

export const shopInitialState: ShopState = {
  activeShop: {
    company_id: undefined,
    shop_id: undefined,
    name: undefined,
    open: undefined,
    close: undefined,
    max_num: undefined,
    categories: [],
    menu_types: [],
    ex_tax: undefined,
    ready_time: 10,
    is_display: true,
    is_open: true,
    close_text: "定休日です",
    open_dates: true,
    top_image_url: undefined,
    map_image_url: undefined,
    is_show_how_to_get_item: true,
    delivery_scope_zip_codes: undefined,
    enable_delivery: true,
    enable_takeout: true,
    enable_stripe: true,
    enable_on_site_payment: false,
    require_nick_name: true,
    delivery_fee: undefined
  },
  activeVisitTimes: []
};

export enum PaymentMethod {
  CASH= "cash",
  CARD= "card"
}
