import React from "react";
import styled from "styled-components";
import SecondaryHeader from "../../components/atoms/secondary_header";
import OrderMenuItem, {
  OrderMenuItemOption
} from "../../components/molecules/order_menu_item";
import OrderMenuPayment from "../../components/molecules/order_menu_payment";
import { OrderOption, OrderMenu, OrderType } from "../../../infra/domain/entities";

export class OrderDetailMenusComponent extends React.Component<IProps> {
  render() {
    if (
      !this.props.order_menus ||
      !this.props.price ||
      !this.props.tax_price ||
      !this.props.price_all
    ) {
      return <div>注文がありません</div>;
    }
    return (
      <div>
        <SecondaryHeader>注文内容</SecondaryHeader>
        <Wrapper>
          {this.props.order_menus.map((menu, index) => {
            const options: OrderMenuItemOption[] = this.translateDomainOptionToOrderMenuItemOption(
              menu.options || []
            );
            return (
              <OrderMenuItem
                key={index}
                name={menu.name}
                price={menu.price}
                quantity={menu.quantity}
                options={options}
              />
            );
          })}
          <OrderMenuPayment
            price={this.props.price}
            delivery_fee_amount={this.props.delivery_fee_amount}
            delivery_price_all={this.props.delivery_price_all}
            tax_price={this.props.tax_price}
            discount_amount={this.props.discount_amount}
            price_all={this.props.price_all}
            order_type={this.props.order_type}
            ex_tax={this.props.ex_tax}
            refunded_amount={this.props.refunded_amount}
            is_canceled={this.props.is_canceled}
          />
        </Wrapper>
      </div>
    );
  }
  private translateDomainOptionToOrderMenuItemOption(
    options: OrderOption[]
  ): OrderMenuItemOption[] {
    return options.map(option => (
      {
        choices: option.choices.map(choice => (
          {
            name: choice.name,
            price: choice.price,
            options: choice.options
              ? choice.options.map(subOption => (
                {
                  choices: subOption.choices.map(subChoice => {
                    return {
                      name: subChoice.name
                    };
                  })
                }
              ))
              : undefined
          }
        ))
      }
  ));
  }
}

interface IProps {
  price?: number;
  tax_price?: number;
  discount_amount?: number;
  price_all?: number;
  order_menus?: OrderMenu[];
  order_type: OrderType;
  delivery_fee_amount? :number;
  delivery_fee_tax_amount? :number;
  delivery_price_all? :number;
  ex_tax :boolean;
  refunded_amount?: number;
  is_canceled?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
`;
