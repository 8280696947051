import React from "react";
import { Checkbox, Image } from "semantic-ui-react";
import styled from "styled-components";
import SubChoiceButton from "../../atoms/subchoice_button";

export default class SubOptionMultiple extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSubChoices: this.props.defaultSubChoiceIndexes
    };
  }
  render() {
    return (
      <RowWrapper>
      <Wrapper>
        <ChoiceHeader onClick={() => this.props.setSubChoices(this.state.selectedSubChoices)}>
          <Checkbox
            disabled={this.props.isSoldOut}
            checked={this.props.isSelected}
            label={this.props.name} />
          {!this.props.isSoldOut && this.props.isRecommended ? <RecommendLabel>おすすめ</RecommendLabel> : ""}
          {this.props.isSoldOut ? <SoldOutLabel>売切</SoldOutLabel> : ""}
          <div style={{ flexGrow: 1 }}/>
          {this.props.price === 0 ? "" : `¥${this.props.price}`}
        </ChoiceHeader>
        <ChoicesWrapper>
          {this.props.subChoices.map((choice, index) => {
            return (
              <SubChoiceButton
                isSoldOut={this.props.isSoldOut}
                key={index}
                isSelected={this.props.isSelected && this.state.selectedSubChoices.indexOf(index) >= 0}
                name={choice}
              onClick={() => this.tapSubChoice(index)} />);
          })}
        </ChoicesWrapper>
      </Wrapper>
        {this.props.imageUrl &&
        <div style={{
          display: "flex",
          width: "20%",
          flexDirection: "column",
          justifyContent:"center" }}>
          <Image src={this.props.imageUrl}/>
        </div>
        }
      </RowWrapper>
    );
  }

  private tapSubChoice(index: number) {
    // すでに選ばれている状態
    if (this.state.selectedSubChoices.indexOf(index) >= 0) {
      if (this.state.selectedSubChoices.length > this.props.minSubChoiceNum) {
        const newChoices = this.state.selectedSubChoices.filter(choice => choice !== index);
        this.setState({
          selectedSubChoices: newChoices
        });
        this.props.setSubChoices(newChoices);
      } else {
        this.props.setSubChoices(this.state.selectedSubChoices);
      }
      // 選ばれていない状態
    } else {
      let newChoices = this.state.selectedSubChoices.concat([index]);
      if (newChoices.length > this.props.maxSubChoiceNum) {
        newChoices = newChoices.slice(1);
      }
      this.setState({
        selectedSubChoices: newChoices
      });
      this.props.setSubChoices(newChoices);
    }
  }
}

interface State {
  selectedSubChoices: number[];
}

interface Props {
  isSelected: boolean;
  isRecommended: boolean;
  name: string;
  imageUrl?: string;
  subChoices: string[];
  maxSubChoiceNum: number;
  minSubChoiceNum: number;
  setSubChoices: (selectedSubChoices: number[]) => void;
  isSoldOut: boolean;
  defaultSubChoiceIndexes: number[];
  price: number;
}

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 4px;
  display: flex;
  flex-direction: column;
`;

const ChoiceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ChoicesWrapper = styled.div`
  display: flex;
  margin: 4px;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SoldOutLabel = styled.div`
  margin-left: 4px;
  color: white;
  font-size: x-small;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #c4c4c4;
`;

const RecommendLabel = styled.div`
  margin-left: 4px;
  color: white;
  font-size: x-small;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #f46f46;
`;
