import { connect } from "react-redux";
import State from "../../../store/state";
import { ShopMapComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {
    mapImageUrl: state.shop.activeShop.map_image_url
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const ShopMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopMapComponent);

export default ShopMap;
