import React from "react";
import styled from "styled-components";

export default class MyAccountMenuElement extends React.Component<
  IProps,
  IState
> {
  render() {
    return (
      <div onClick={() => this.props.onClick && this.props.onClick()}>
        <Title>{this.props.title}</Title>
      </div>
    );
  }
}

interface IProps {
  title: string;
  onClick?: () => void;
}
interface IState {}

const Title = styled.div`
  padding: 16px 12px;
  border-bottom: 1px solid #f1f1f1;
  text-align: left;
`;
