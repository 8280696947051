import { put, take } from "redux-saga/effects";
import actions from "./action";
import { Modal, PopUpModal } from "../../../store/app/state";
import {
  deleteCommunicationId,
  handleError,
  putCommunicationId
} from "../../../store/sagas";
import uuid from "uuid";
import { call, all } from "@redux-saga/core/effects";
import {
  updateModalAction,
  updatePopUpAction
} from "../../../store/app/actions";
import UserRepository from "../../../infra/repository/UserRepository";
import EntityRepository from "../../../infra/repository/EntityRepository";
import ShopRepository from "../../../infra/repository/ShopRepository";
import { push } from "connected-react-router";
import Routes from "../../routes";

function handleOnClickSignIn(
  userRepository: UserRepository,
  entityRepository: EntityRepository,
  shopRepository: ShopRepository
) {
  return function* () {
    while (true) {
      const action = yield take(actions.onClickMailSignInAction);
      const communication = uuid();
      try {
        yield call(putCommunicationId, communication);

        const mail = action.mail;
        const password = action.password;

        yield call(userRepository.signInWithEmailAndStoreUser, mail, password);

        yield call(userRepository.userConnect);

        const shop = yield call(shopRepository.selectCurrentShop);

        yield all([
          call(entityRepository.fetchAndUpdateCart, shop.shop_id),
          shop.enable_stripe && call(userRepository.fetchCardsAndStore)
        ]);
        const cart = yield call(entityRepository.selectCart);

        if (cart.order_menus.length > 0) {
          yield put(updateModalAction(Modal.NoModal));
          yield put(push(Routes.confirmOrder));
        } else {
          yield put(updateModalAction(Modal.NoModal));
          yield put(updatePopUpAction(PopUpModal.SignInComplete));
        }
      } catch (e) {
        yield call(userRepository.signOut);
        yield handleError(e);
      } finally {
        yield call(deleteCommunicationId, communication);
      }
    }
  };
}

function createSagas(
  userRepository: UserRepository,
  entityRepository: EntityRepository,
  shopRepository: ShopRepository
) {
  return [
    handleOnClickSignIn(userRepository, entityRepository, shopRepository)
  ];
}

export default createSagas;
