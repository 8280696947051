import React from "react";
import styled from "styled-components";

export default class OrderMenuItem extends React.Component<IProps> {
  render() {
    return (
      <Row>
        <Menu>
          <Name>{this.props.name}</Name>
          <Quantity>{this.props.quantity}</Quantity>
          <Price>¥{this.props.price}</Price>
        </Menu>
        {this.props.options &&
          this.props.options.length > 0 &&
          this.props.options.map((option, optionIndex) => {
            return option.choices.map((choice, choiceIndex) => {
              return (
                <div key={optionIndex.toString() + choiceIndex.toString()}>
                  {choice.name && (
                    <Choice>
                      <ChoiceName>
                        -{choice.name}
                        {choice.options &&
                          choice.options.map(
                            (subOption: { choices: any[] }, subOptionIndex) => {
                              return subOption.choices.map(
                                (
                                  subChoice: { name: string },
                                  subChoiceIndex
                                ) => {
                                  return (
                                    <div
                                      key={
                                        subOptionIndex.toString() +
                                        subChoiceIndex.toString()
                                      }
                                    >
                                      {subChoice.name && (
                                        <SubchoiceName>
                                          /{subChoice.name}
                                        </SubchoiceName>
                                      )}
                                    </div>
                                  );
                                }
                              );
                            }
                          )}
                      </ChoiceName>
                      {choice.price > 0 && (
                        <ChoicePrice>¥{choice.price}</ChoicePrice>
                      )}
                    </Choice>
                  )}
                </div>
              );
            });
          })}
        <Border />
      </Row>
    );
  }
}

interface IProps {
  name: string;
  price: number;
  quantity: number;
  options?: OrderMenuItemOption[];
}

export interface OrderMenuItemOption {
  choices: OrderMenuItemChoice[];
}

export interface OrderMenuItemChoice {
  name: string;
  price: number;
  options?: OrderMenuItemSubOption[];
}

export interface OrderMenuItemSubOption {
  choices: OrderMenuItemSubChoice[];
}

export interface OrderMenuItemSubChoice {
  name: string;
}

const Row = styled.div`
  padding: 15px 32px 0px 32px;
`;

const Border = styled.div`
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 15px;
`;
const Menu = styled.div`
  display: flex;
`;
const Name = styled.div`
  width: 50%;
`;
const Quantity = styled.div`
  width: 20%;
  text-align: right;
`;
const Price = styled.div`
  text-align: right;
  width: 30%;
`;

const Choice = styled.div`
  display: flex;
  font-size: 10px;
  padding: 0px 0px 0px 14px;
`;
const ChoiceName = styled.div`
  display: flex
  width: 80%
`;
const ChoicePrice = styled.div`
  width: 20%;
  text-align: right;
`;

const SubchoiceName = styled.div``;
