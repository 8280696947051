import React from "react";
import styled from "styled-components";
import ShopImage from "../atoms/shop_image";
import ShopDate from "../atoms/shop_date";

export default class ShopItem extends React.Component<IProps, any> {
  render() {
    return (
      <div>
        <Wrapper onClick={this.props.onClick}>
          <Info width={this.props.width || "100%"}>
            <ShopImage
              is_open={this.props.is_open}
              close_text={this.props.close_text}
              image_url={this.props.image_url}
              width={this.props.width}
            />
            <ShopInfo>
              {this.props.name}
              <div style={{ paddingRight: "5px" }}>
                <ShopDate
                  open={this.props.open}
                  close={this.props.close}
                  color={this.props.color}
                />
              </div>
            </ShopInfo>

            <ShopSubInfo>
              {this.props.enableDelivery &&
                <DeliveryFee>配送手数料￥{this.props.deliveryFee ? this.props.deliveryFee.toLocaleString() : 0}</DeliveryFee>
              }
            </ShopSubInfo>

          </Info>
        </Wrapper>
      </div>
    );
  }
}

interface IProps {
  width?: string;
  name: string;
  image_url?: string;
  is_open: boolean;
  open: string;
  close: string;
  color?: string;
  close_text: string;
  onClick: () => void;
  deliveryFee? :number;
  enableDelivery: boolean;
}
const Wrapper = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 12px;
`;

const Info = styled.div`
  width: ${(props: { width: string }) => props.width};
`;

const ShopInfo = styled.div`
  display: flex;
  padding: 13px 0px;
  justify-content: space-between;
`;

const ShopSubInfo = styled.div`
  display: flex;
  padding: 3px 0px;
  justify-content: start;
  font-size: 12px;
  flex-wrap: wrap;
`;

const DeliveryFee = styled.div`
  background-color: #eaeaea;
  padding: 3px 10px;
  margin-right: 5px;
`;
