import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import LoginMainImage from "../../../assets/images/login_main_image.svg";
import { RouteComponentProps } from "react-router";
import MailLoginButtonSet from "../../components/molecules/mail_login_button_set";
import { EventGa } from "../../components/middleware/ga";
import LoginSmallText from "../../components/atoms/login_smallText";
import LINELoginButton from "../../components/molecules/LINE_login_button";

export class SignInComponent extends React.Component<IProps, IState> {
  render() {
    return (
      <div
        style={{ marginBottom: "10px", maxHeight: "65vh", overflow: "scroll" }}
      >
        {/* isLoadingはAPI通信路のロード．LINE loginの処理はさらにバックグラウンドのため，
        API 通信が行われていない(isLoading==false)かつLINE処理が行われているときにこのローダーを表示する */}
        <Image
          src={LoginMainImage}
          style={{ width: "100%", marginBottom: "20px" }}
        />
        <Wrapper>
          <Text>
            アカウント登録すると、以降のご注文時に
            <br />
            ワンタップで注文をすることができるようになります
          </Text>
          <SNSWrapper>
            <LINELoginButton
              onLINEButtonClick={() => {
                EventGa.signout();
                this.props.onClickLINESignIn();
              }}
            />
          </SNSWrapper>
          {/* 次回ツイッターログインが復活する可能性があるためコメントアウトしています
          <SNSWrapper>
            <SNSLoginButton
              onTWButtonClick={() => {
                EventGa.signout();
                this.props.onClickTwitterSignIn();
              }}
              onLINEButtonClick={() => {}}
            />
          </SNSWrapper> */}
          <MailLoginButtonSet
            signupButtonClick={() => {
              EventGa.mailLogin();
              this.props.onClickMailSignUp();
            }}
            signinButtonClick={() => {
              EventGa.mailSignup();
              this.props.onClickMailSignIn();
            }}
          />
        </Wrapper>
        <Margin />
        <LoginSmallText />
      </div>
    );
  }
}

interface IProps extends RouteComponentProps<any> {
  onClickLINESignIn: () => void;
  onClickTwitterSignIn: () => void;
  onClickMailSignIn: () => void;
  onClickMailSignUp: () => void;
  isLoading: boolean;
}

interface IState {}

const Margin = styled.div`
  margin-top: 30px;
`;
const Wrapper = styled.div`
  width: 300px;
  margin: 0 auto;
`;

const SNSWrapper = styled.div`
  margin: 25px 0px;
`;

const Text = styled.div`
color:#d8d8d8
font-size:12px
text-align:center
padding-bottom:20px
`;
