import React from "react";
import AccountUserInfo from "../../organisms/account_user_info/container";
import AccountMenu from "../../organisms/account_menu/container";

export default class MyAccount extends React.Component<IProps, IState> {
  render() {
    return (
            <div>
                <AccountUserInfo />
                <AccountMenu />
            </div>
    );
  }
}

interface IProps {}
interface IState {}
