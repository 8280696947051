import styled from "styled-components";

const SecondaryHeader = styled.div`
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #333;
  font-weight: bold;
  background-color: #f1f1f1;
  padding-left: 32px;
`;

export default SecondaryHeader;
