import styled from "styled-components";

const PrimaryButton = styled.button`
  width: ${(props: IProps) => props.width};
  height: ${(props: IProps) => (props.height ? props.height : "44px")};
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  background-color: ${(props: IProps) =>
    props.color ? props.color : "#f46f46"};
  border-radius: 4px;
  border: 0px;
  display: block;
  -webkit-tap-heighlight-color: #d05a36;
  outline: none;
  font-family: "Lato", "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "Osaka", "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`;

interface IProps {
  width: string;
  height?: string;
  color?: string;
}

export default PrimaryButton;
