import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import CheckImage from "../../../../assets/images/check.svg";

export default class Complete extends React.Component<IProps, IState> {
  render() {
    return (
    <div>
        <Image src={CheckImage} onClick={this.props.modalClose} />
        <Text>{this.props.text}</Text>
    </div>
    );
  }
}
interface IProps extends RouteComponentProps<any> {
  text: string;
  modalClose: () => void;
}
interface IState {}

const Image = styled(SemanticImage)`
  width: 20%;
  object-fit: cover;
  margin: 0 auto 0;
`;

const Text = styled.div`
  text-align: center;
  color: white;
  font-size: 18px;
  line-height: 35px;
  margin-top: 10px;
`;
