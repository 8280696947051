import {
  CART_UPDATE_CART,
  CHOICE_UPDATE_ALL_CHOICE,
  EntityActionTypes,
  MENU_UPDATE_ALL_MENU,
  OPTION_UPDATE_ALL_OPTION,
  ORDERS_UPDATE_ORDERS,
  SHOP_UPDATE_ALL_SHOP,
  UPDATE_COMPANY_ACTION
} from "./types";
import { Choice, Company, Menu, Option, Order, Cart } from "../../infra/domain/entities";
import { Shop } from "../../infra/domain/shop";

export function updateCompanyAction(company: Company): EntityActionTypes {
  return {
    type: UPDATE_COMPANY_ACTION,
    company
  };
}
export function updateAllShopsAction(shops: Shop[]): EntityActionTypes {
  return {
    type: SHOP_UPDATE_ALL_SHOP,
    shops,
  };
}

export function updateAllMenusAction(menus: Menu[]): EntityActionTypes {
  return {
    type: MENU_UPDATE_ALL_MENU,
    menus,
  };
}

export function updateAllOptionsAction(options: Option[]): EntityActionTypes {
  return {
    type: OPTION_UPDATE_ALL_OPTION,
    options
  };
}

export function updateAllChoicesAction(choices: Choice[]): EntityActionTypes {
  return {
    type: CHOICE_UPDATE_ALL_CHOICE,
    choices,
  };
}

export function updateOrdersAction(orders: Order[]) {
  return {
    type: ORDERS_UPDATE_ORDERS,
    orders,
  };
}

export function updateCartAction(cart: Cart){
  return {
    type: CART_UPDATE_CART,
    cart,
  };
}
