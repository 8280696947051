import { connect } from "react-redux";
import State from "../../../store/state";
import { AddCardPageComponent } from "./component";
import { updateModalAction } from "../../../store/app/actions";
import { Modal } from "../../../store/app/state";

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClickClose: () => dispatch(updateModalAction(Modal.NoModal))
  };
};

export const AddCardPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCardPageComponent);

export default AddCardPage;
