import { connect } from "react-redux";
import State from "../../../store/state";
import ConfirmOrderButtonComponent from "./component";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export const ConfirmOrderButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderButtonComponent);

export default ConfirmOrderButton;
