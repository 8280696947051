import { connect } from "react-redux";
import { ConfirmOrderRecommendEmailVerifyComponent } from "./component";
import State from "../../../store/state";
import { dispatchers } from "./action";

const mapStateToProps = (state: State) => {
  return { };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendEmailVerification: () => {
      return dispatch(dispatchers.handleOnClickSignUp());
    }
  };
};

export const ConfirmOrderRecommendEmailVerify = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderRecommendEmailVerifyComponent);

export default ConfirmOrderRecommendEmailVerify;
