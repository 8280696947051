export const actions = {
  clickTwitterLoginAction: "SIGN_IN_TWITTER_LOGIN",
  clickLINELoginAction: "SIGN_IN_LINE_LOGIN",
};

export const dispatchers = {
  handleClickLINELoginAction: () => ({
    type: actions.clickLINELoginAction,
  }),
  handleClickTwitterLoginAction: () => ({
    type: actions.clickTwitterLoginAction,
  })
};

export default actions;
