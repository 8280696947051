import React from "react";
import styled from "styled-components";
import SecondaryHeader from "../../components/atoms/secondary_header";
import MyAccountList from "../../components/molecules/myaccount_list";
import { UserState } from "../../../store/user/state";
import MyAccountForm from "../../components/molecules/myaccount_form";
import PrimaryButton from "../../components/atoms/primary_button";
import { color } from "../../../assets/colors";

export class AccountUserInfoComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      name: this.props.user.user ? this.props.user.user.name || "" : ""
    };
    this.onNameChange = this.onNameChange.bind(this);
  }
  render() {
    const isSignIn = this.props.user.user ? true : false;
    const isSaveButtonActive = this.props.user.user
      ? (this.props.user.user.name !== this.state.name && this.state.name)
      : false;
    return (
      <div>
        <SecondaryHeader>ユーザー情報</SecondaryHeader>
        <Wrapper>
          <MyAccountForm
            title="名前"
            width={"100%"}
            value={this.state.name || ""}
            onChange={this.onNameChange}
            isSignIn={isSignIn}
          />
          <MyAccountList
            title="メールアドレス"
            data={this.props.user.user ? this.props.user.user.email || "" : ""}
          />
          {isSignIn && (
            <ButtonLayout>
              <PrimaryButton
                width={"100%"}
                onClick={
                  isSaveButtonActive
                    ? () => this.props.onSaveButtonClick(this.state.name!)
                    : () => {}
                }
                color={isSaveButtonActive ? color.orange : color.greyLight}
              >
                保存する
              </PrimaryButton>
            </ButtonLayout>
          )}
        </Wrapper>
      </div>
    );
  }
  private onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: event.target.value });
  }
}

interface IProps {
  user: UserState;
  onSaveButtonClick: (name: string) => void;
}
interface IState {
  name?: string;
}

const Wrapper = styled.div`
  margin: 0 20px;
`;

const ButtonLayout = styled.div`
  padding: 23px 50px;
`;
