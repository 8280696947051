export const actions = {
  updateActiveOrderId: "ORDER_HISTORY_LIST_UPDATE_ACTIVE_ORDER_ID"
};

export const dispatchers = {
  handleUpdateActiveOrderId: (orderId: string) => ({
    type: actions.updateActiveOrderId,
    orderId
  })
};

export default actions;
