import { initialState, UserState } from "./state";
import { RESET_USER, UPDATE_ACCESS_TOKEN, UPDATE_CARDS, UPDATE_USER, UserActionTypes } from "./types";

export default (state: UserState = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case RESET_USER:
      return initialState;
    case UPDATE_ACCESS_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.accessToken
      });
    case UPDATE_USER:
      return Object.assign({}, state, {
        user: {
          ...state.user,
          ...action.user,
        }
      });
    case UPDATE_CARDS:
      return Object.assign({}, state, {
        cards: action.cards
      });
    default:
      return state;
  }
};
