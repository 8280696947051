import { call, take } from "redux-saga/effects";
import actions from "./action";
import uuidv4 from "uuid";
import { deleteCommunicationId, handleError, putCommunicationId, } from "../../../store/sagas";
import EntityRepository from "../../../infra/repository/EntityRepository";
import ShopRepository from "../../../infra/repository/ShopRepository";

function initializeInformation(
    entityRepository: EntityRepository,
    shopRepository: ShopRepository
) {
  return function* () {
    while (true) {
      yield take(actions.initializeInformation);
      const communication = uuidv4();
      try {
        yield putCommunicationId(communication);
        const shopId = yield call(shopRepository.selectCurrentShopId);
        yield call(entityRepository.fetchAndUpdateOneShopInformation, shopId);
      } catch (e) {
        yield handleError(e);
      } finally {
        yield deleteCommunicationId(communication);
      }
    }
  };
}

function createSagas(
    entityRepository: EntityRepository,
    shopRepository: ShopRepository
) {
  return [
    initializeInformation(entityRepository, shopRepository)
  ];
}

export default createSagas;
