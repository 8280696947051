import React from "react";
import styled from "styled-components";
import PrimaryButton from "../../components/atoms/primary_button";
import { EventGa } from "../../components/middleware/ga";

export default class ConfirmOrderButtonComponent extends React.Component<
  IProps
> {
  render() {
    return (
      <Wrapper>
        <Text>
          注文を確定することで<Link onClick={this.openTos}>利用規約</Link>
          に同意します。
          <br />
          注文確定後のキャンセルは承っておりません。ご注意ください。
        </Text>
        <PrimaryButton
          style={{ margin: "14px auto", fontSize: "16px" }}
          width={"100%"}
          color={this.props.disabled ? "#f1f1f1" : undefined}
          onClick={() => {
            EventGa.order();
            this.props.onConfirm();
          }}
        >
          注文を確定する
        </PrimaryButton>
      </Wrapper>
    );
  }
  private openTos() {
    window.open("https://info.dinii.jp", "_blank");
  }
}

interface IProps {
  onConfirm: () => void;
  disabled: boolean;
}

const Wrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  text-align: center;
`;
const Text = styled.div`
  font-size: 12px;
  padding: 30px 0px 14px 0px;
`;
const Link = styled.span`
  font-size: 12px;
  color: #3273dc;
`;
