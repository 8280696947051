import { ShopState } from "./state";
import { ShopActionTypes, UPDATE_ACTIVE_VISIT_TIMES, UPDATE_SHOP } from "./types";

export function updateShopAction(shop: ShopState): ShopActionTypes {
  return {
    type: UPDATE_SHOP,
    shop,
  };
}

export function updateActiveVisitTimesAction(activeVisitTimes: string[]): ShopActionTypes {
  return {
    type: UPDATE_ACTIVE_VISIT_TIMES,
    activeVisitTimes,
  };
}
