import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "./style.css";
import "node-waves/dist/waves.min.css";
import "node-waves/dist/waves.min.js";
import configureStore from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import firebase from "firebase/app";
import "firebase/auth";
import * as Sentry from "@sentry/browser";
import URLs from "./config/URL";
import ReactGA from "react-ga";
import App from "./presentation/AppContainer";

const uuidv4 = require("uuid/v4");

if (process.env.REACT_APP_STAGE === "prod") {
  ReactGA.initialize("UA-110465890-29");
  ReactGA.set(uuidv4());
}

Sentry.init({
  dsn: URLs.SENTRY,
  environment: process.env.REACT_APP_STAGE
});

const redux = configureStore();

let firebaseConfig = {
  apiKey: "AIzaSyCTcy-GJwW9C-j7G-hylhZp53tcJ-dvCc0",
  authDomain: "dinii-for-dev.firebaseapp.com",
  databaseURL: "https://dinii-for-dev.firebaseio.com",
  projectId: "dinii-for-dev",
  storageBucket: "",
  messagingSenderId: "562587290906",
  appId: "1:562587290906:web:3a605f02e9b2a8d34cf0a3",
  measurementId: "G-B4HE87C74C"
};

if (process.env.REACT_APP_STAGE === "stag") {
  firebaseConfig = {
    apiKey: "AIzaSyBbFfUrkrjXfgZe08yCj-0nlvddbw0SBbc",
    authDomain: "dinii-for-stag.firebaseapp.com",
    databaseURL: "https://dinii-for-stag.firebaseio.com",
    projectId: "dinii-for-stag",
    storageBucket: "dinii-for-stag.appspot.com",
    messagingSenderId: "195585275834",
    appId: "1:195585275834:web:979e8bbde1a4d857704f83",
    measurementId: "G-H6435HR2PL"
  };
} else if (process.env.REACT_APP_STAGE === "prod") {
  firebaseConfig = {
    apiKey: "AIzaSyBs8ckb-6MHEtVUQkbW8qLKDQFHam-seqI",
    authDomain: "dinii-for-user.firebaseapp.com",
    databaseURL: "https://dinii-for-user.firebaseio.com",
    projectId: "dinii-for-user",
    storageBucket: "dinii-for-user.appspot.com",
    messagingSenderId: "16168397102",
    appId: "1:16168397102:web:407bfde6df251c1abeb2bf",
    measurementId: "G-3L8C6F9JKP"
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
ReactDOM.render(
    <Provider store={redux.store}>
      <PersistGate loading={null} persistor={redux.persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("root")
);
