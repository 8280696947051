import { connect } from "react-redux";
import State from "../../../store/state";
import { ConfirmOrderComponent } from "./component";
import { dispatchers } from "./action";
import { PostOrderArguments } from "../../../infra/api/ApiService";

const mapStateToProps = (state: State) => {
  return {
    deliveryScopeZipCodes: state.shop.activeShop.delivery_scope_zip_codes,
    enableTakeout: state.shop.activeShop.enable_takeout,
    enableDelivery: state.shop.activeShop.enable_delivery,
    enableStripe: state.shop.activeShop.enable_stripe,
    enableOnSitePayment: state.shop.activeShop.enable_on_site_payment,
    requireNickName: state.shop.activeShop.require_nick_name,
    cards: state.user.cards
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCart: () => {
      dispatch(dispatchers.updateCart());
    },
    postOrder: (args: PostOrderArguments) => {
      dispatch(dispatchers.postOrder(args));
    },
    fetchCard: () => {
      dispatch(dispatchers.fetchCard());
    }
  };
};

export const ConfirmOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderComponent);

export default ConfirmOrder;
