import { connect } from "react-redux";
import { withRouter } from "react-router";
import State from "../../../store/state";
import { Modal, PopUpModal } from "../../../store/app/state";
import { deleteCurrentErrorAction, updateModalAction, updatePopUpAction } from "../../../store/app/actions";
import { ModalManagerComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {
    modal: state.app.modal,
    popUp: state.app.popUp,
    currentError: state.app.currentError,
    loading: state.app.isLoading
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => dispatch(
        updateModalAction(Modal.NoModal)
    ),
    closeErrorModal: () => dispatch(
        deleteCurrentErrorAction()
    ),
    closePopUp: () => dispatch(
      updatePopUpAction(PopUpModal.None)
    )
  };
};

export const ModalManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalManagerComponent));

export default ModalManager;
