export const actions = {
  handleRedirect: "HANDLE_REDIRECT",
  initializeUser: "INITIALIZE_USER",
  initializeInformation: "INITIALIZE_INFORMATION",
};

export const dispatchers = {
  handleRedirect: () => ({
    type: actions.handleRedirect
  }),
  initializeUser: () => ({
    type: actions.initializeUser
  }),
  initializeInformation: () => ({
    type: actions.initializeInformation
  }),
};

export default actions;
