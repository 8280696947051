import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import { color } from "../../../assets/colors";
import timeIcon from "../../../assets/images/time_icon_gray.svg";
import mapIcon from "../../../assets/images/map_icon_gray.svg";
import checkIcon from "../../../assets/images/check_icon_orange.svg";
import { OrderType } from "../../../infra/domain/entities";

export default class OrderHistoryItem extends React.Component<IProps> {
  render() {
    return (
      <Wrapper onClick={this.props.onClick}>

        <DateAndPlace>
          <InfoComponent>
            <IconWrapper>
              <Image src={timeIcon} />
            </IconWrapper>
            <InfoText>{this.props.dateTime}</InfoText>
          </InfoComponent>
          <InfoComponent>
            <IconWrapper>
              <Image src={mapIcon} />
            </IconWrapper>
            <InfoText>{this.props.place}</InfoText>
          </InfoComponent>
          <InfoComponent>
            <OrderTypeText>{this.props.orderType === OrderType.TAKEOUT ? "テイクアウト" : "デリバリー"}</OrderTypeText>
          </InfoComponent>
          {this.props.isCanceled &&
            <InfoComponent>
              <CancelText>キャンセル済み</CancelText>
            </InfoComponent>
          }
          {this.props.isPartialRefunded &&
            <InfoComponent>
              <CancelText>一部返金済み</CancelText>
            </InfoComponent>
          }
        </DateAndPlace>

        <OrderNumberWrapper>
          <OrderNumberLabel>予約番号</OrderNumberLabel>
          <OrderNumber>{this.props.orderNumber}</OrderNumber>
        </OrderNumberWrapper>

        {this.props.orderStatus === "done" && (
          <IsReceivedWrapper>
            <Image src={checkIcon} style={{ marginTop: "25px" }} />
          </IsReceivedWrapper>
        )}

      </Wrapper>
    );
  }
}

interface IProps {
  dateTime: string;
  place: string;
  orderNumber: string;
  orderStatus: string;
  orderType: OrderType;
  onClick: () => void;
  isCanceled: boolean;
  isPartialRefunded: boolean;
}

const Wrapper = styled.div`
  display: flex;
  margin: 20px;
`;
const DateAndPlace = styled.div`
  width: 50%;
`;
const InfoComponent = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const IconWrapper = styled.div`
  margin-right: 5px;
`;
const InfoText = styled.div`
  color: ${color.grayText};
`;
const OrderTypeText = styled.div`
  color: ${color.grayText};
  margin-left: 20px;
`;
const CancelText = styled.div`
  color: red;
  margin-left: 20px;
`;
const OrderNumberWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const OrderNumberLabel = styled.div`
  text-align: center;
  font-size: 16px;
`;
const OrderNumber = styled.div`
  text-align: center;
  font-size: 32px;
  line-height: 38px;
`;
const IsReceivedWrapper = styled.div`
  width: 10%;
`;
