import axios from "axios";

const baseURL = "https://apis.postcode-jp.com/api/v3";
const apiKey = "bTna3DvF8ea45FiR0K5QX3dOlbTvQcUiennzZXf";

export interface PostcodeApiResponse {
  "allCode": string;
  "prefCode": string;
  "cityCode": string;
  "postcode": string;
  "oldPostcode": string;
  "pref": string;
  "city": string;
  "town": string;
  "allAddress": string;
  "office": string;
  "hiragana": {
    "pref": string,
    "city": string,
    "town": string,
    "office": string,
    "allAddress": string
  };
  "halfWidthKana": {
    "pref": string,
    "city": string,
    "town": string,
    "office": string,
    "allAddress": string
  };
  "fullWidthKana": {
    "pref": string,
    "city": string,
    "town": string,
    "office": string,
    "allAddress": string
  };
  "generalPostcode": boolean;
  "officePostcode": boolean;
  "location": {
    "latitude": number,
    "longitude": number
  };
}

export class PostcodeApi {
  static async queryAddressFromZipCode(zipCode: string): Promise<PostcodeApiResponse | null> {
    const response = await axios.get<{ data: PostcodeApiResponse[] }>(
        `${baseURL}/postcodes?postcode=${zipCode}&limit=1`,
        { headers: { apiKey } }
    );
    if (response.data.data.length === 0) {
      return null;
    }
    return response.data.data[0];
  }
}
