import State from "../../../store/state";
import { connect } from "react-redux";
import { OrderDetailComponent } from "./component";
import { OrderType } from "../../../infra/domain/entities";

const mapStateToProps = (state: State) => {
  const id = state.app.activeOrderId;
  const filteredOrders = state.entity.orders.filter(
      item => item.order_id === id
  );

  const order =
      filteredOrders && filteredOrders.length > 0 ? filteredOrders[0] : undefined;

  const shop = state.shop.activeShop;

  return {
    isShowHowToGetItem: shop.is_show_how_to_get_item,
    orderType: order ? order.order_type : OrderType.TAKEOUT,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const OrderDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailComponent);

export default OrderDetail;
