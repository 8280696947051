import { select, call, take, all, put } from "redux-saga/effects";
import actions from "./action";
import { deleteCommunicationId, getShop, handleError, putCommunicationId, } from "../../../store/sagas";
import uuidv4 from "uuid";
import ShopRepository from "../../../infra/repository/ShopRepository";
import EntityRepository from "../../../infra/repository/EntityRepository";
import AppRepository from "../../../infra/repository/AppRepository";
import { replace } from "connected-react-router";
import Routes from "../../routes";
import { updatePopUpAction } from "../../../store/app/actions";
import { PopUpModal } from "../../../store/app/state";
import { PostOrderArguments } from "../../../infra/api/ApiService";
import UserRepository from "../../../infra/repository/UserRepository";

function fetchCard(userRepository: UserRepository) {
  return function* () {
    while (true) {
      yield take(actions.fetchCard);
      const communication = uuidv4();
      try {
        yield putCommunicationId(communication);
        yield call(userRepository.fetchCardsAndStore);
      } catch (e) {
        yield handleError(e);
      } finally {
        yield deleteCommunicationId(communication);
      }
    }
  };
}

function updateCart(
  entityRepository: EntityRepository,
  shopRepository: ShopRepository
) {
  return function* () {
    while (true) {
      yield take(actions.updateCart);
      const communication = uuidv4();
      try {
        yield putCommunicationId(communication);
        const shopId = yield call(shopRepository.selectCurrentShopId);
        yield call(entityRepository.fetchAndUpdateCart, shopId);
      } catch (e) {
        yield handleError(e);
      } finally {
        yield deleteCommunicationId(communication);
      }
    }
  };
}

function postOrder(
  entityRepository: EntityRepository,
  appRepository: AppRepository
) {
  return function* () {
    while (true) {
      const action = yield take(actions.postOrderAction);
      const args: PostOrderArguments = action.args;

      const communication = uuidv4();
      try {
        yield putCommunicationId(communication);
        const shopState = yield select(getShop);
        const orderId = yield call(
          entityRepository.postOrder,
          shopState.activeShop.shop_id,
          args
      );
        yield call(appRepository.updateActiveOrderId, orderId);
        yield all([
          call(entityRepository.fetchAndUpdateOrders),
          call(entityRepository.fetchAndUpdateCart, shopState.activeShop.shop_id)
        ]);
        yield deleteCommunicationId(communication);
        yield put(replace(`${Routes.orderDetail}?back_to=2`));
        yield put(updatePopUpAction(PopUpModal.OrderComplete));
      } catch (e) {
        yield handleError(e);
      } finally {
        yield deleteCommunicationId(communication);
      }
    }
  };
}

const createSagas = (
  shopRepository: ShopRepository,
  entityRepository: EntityRepository,
  appRepository: AppRepository,
  userRepository: UserRepository
) => [
  fetchCard(userRepository),
  updateCart(entityRepository, shopRepository),
  postOrder(entityRepository, appRepository)
];

export default createSagas;
