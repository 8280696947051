import { connect } from "react-redux";
import { ConfirmOrderInfoComponent } from "./component";
import State from "../../../store/state";

const mapStateToProps = (state: State) => {
  return {
    shop_name: state.shop.activeShop.name,
    mapUrl: state.shop.activeShop.map_image_url
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { };
};

export const ConfirmOrderInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderInfoComponent);

export default ConfirmOrderInfo;
