import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import CardVisa from "../../../assets/images/visa.svg";
import CardMaster from "../../../assets/images/mastercard.svg";
import CardAmerican from "../../../assets/images/americanExpress.svg";

export default class CardAll extends React.Component<IProps, IState> {
  render() {
    return (
      <CardImageList>
        <Image src={CardVisa} />
        <Image src={CardMaster} />
        <Image src={CardAmerican} />
      </CardImageList>
    );
  }
}

interface IProps {
  width?: string;
}
interface IState {}

const CardImageList = styled.div`
  width: ${(props: IProps) => (props.width ? props.width : "50vw")};
  display: flex;
  justify-content: space-between;
  margin: auto;
`;
