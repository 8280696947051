import { put, take } from "redux-saga/effects";
import { actions } from "./action";
import { Modal, PopUpModal } from "../../../store/app/state";
import { getCurrentModal, handleError } from "../../../store/sagas";
import uuid from "uuid";
import { call, select } from "@redux-saga/core/effects";
import {
  addCurrentCommunicationAction,
  deleteCurrentCommunicationAction,
  updateCurrentErrorAction,
  updateModalAction,
  updatePopUpAction
} from "../../../store/app/actions";
import CustomError from "../../../infra/errors";
import { push } from "connected-react-router";
import Routes from "../../routes";
import UserRepository from "../../../infra/repository/UserRepository";

function handleCardToken(
    userRepository: UserRepository
) {
  return function* () {
    while (true) {
      const communication = uuid();
      const action = yield take(actions.onReceiveCardToken);
      const backTo: string | undefined = action.backTo;

      try {
        yield put(addCurrentCommunicationAction(communication));

        yield call(userRepository.sendCard, action.token);
        yield call(userRepository.fetchCardsAndStore);

        const modal = yield select(getCurrentModal);
        yield put(updateModalAction(Modal.NoModal));

        // NOTE: 基本的にはbackToで戻る位置を制御するロジックにしていきたい
        if (backTo) {
          yield put(push(backTo));
          continue;
        }

        if (modal === Modal.AddCard) {
          yield put(push(Routes.confirmOrder));
        } else {
          yield put(push(Routes.shops));
          yield put(updatePopUpAction(PopUpModal.CardRegisterComplete));
        }
      } catch (e) {
        yield handleError(e);
      } finally {
        yield put(deleteCurrentCommunicationAction(communication));
      }
    }
  };
}

function handleCardTokenError(
) {
  return function* () {
    while (true) {
      yield take(actions.onReceiveCardTokenError);
      yield put(updateCurrentErrorAction(new CustomError("カード登録に失敗しました", "対応しているカードはVisa/MasterCard/American Expressです")));
    }
  };
}

function createSagas(
    userRepository: UserRepository
) {
  return [
    handleCardToken(userRepository),
    handleCardTokenError()
  ];
}

export default createSagas;
