import React from "react";
import Loader from "./organisms/dinii_loader";
import { StripeProvider } from "react-stripe-elements";
import { AppState } from "../store/app/state";
import { ConnectedRouter } from "connected-react-router";
import { history } from "../store/store";
import ModalManager from "./pages/modal_manager/container";
import firebase from "firebase/app";
import "firebase/auth";
import Drawer from "./pages/drawer/container";
import Header from "./organisms/navigation_header/container";
import styled from "styled-components";
import { getDisplayHeight } from "../util/window";
import { Router } from "./router";

export default class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
      displayHeight: getDisplayHeight()
    };
    firebase
        .auth()
        .getRedirectResult()
        .then(result => {
          if (result.user) {
            this.props.handleRedirect(result);
          }
        })
        .catch(e => {
          this.props.onRedirectError();
        })
        .finally(() => this.setState({ isLoading: false }));

    window.addEventListener("resize", () => {
      this.setState({ displayHeight: getDisplayHeight() });
    },                      false);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.props.appName !== document.title) {
      document.title = this.props.appName;
    }
  }

  render() {
    return (
      <StripeProvider apiKey={this.props.stripeKey}>
          <ConnectedRouter history={history}>
            <div>
              <Loader visible={this.props.isLoading || this.state.isLoading} />
              <ContentWrapper>
                <Header>header</Header>
                <Content style={{ height: this.state.displayHeight - 60 }}>
                  <Drawer />
                  <ModalManager />
                  <Router />
                </Content>
              </ContentWrapper>
            </div>
          </ConnectedRouter>
      </StripeProvider>
    );
  }
}

interface IProps {
  app: AppState;
  stripeKey: string;
  handleRedirect: (credential: firebase.auth.UserCredential) => void;
  onRedirectError: () => void;
  isLoading: boolean;
  appName: string;
}

interface IState {
  isLoading: boolean;
  displayHeight: number;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Content = styled.div`
  overflow: scroll;
  flex-grow: 1;
  height: ${getDisplayHeight() - 60}px;
`;
