import React from "react";
import { RouteComponentProps } from "react-router";
import { Image } from "semantic-ui-react";
import StepLogin from "../../../assets/images/step_signup.svg";
import MailSignupForm from "../../components/molecules/mail_signup_form";
import ModalHeader, { ActionImageType } from "../../components/molecules/bottom_modal_header";
import styled from "styled-components";

export class MailSignUpSection extends React.Component<IProps, IState> {
  render() {
    return (
            <div>
                <ModalHeader text={"メールアドレスで登録"} actionImageSrcType={ActionImageType.back} onActionClick={this.props.onClickBackButton} />
                <Content>
                <Image src={StepLogin} style={{ margin: "0 auto 18px" }} />
                <div style={{ margin: "28px auto" }}>
                    <MailSignupForm
                        onRegistrationButtonClick={(
                            name: string,
                            email: string,
                            password: string
                        ) => {
                          this.props.mailSignUp(name, email, password);
                        }}
                    />
                </div>
                </Content>
            </div>
    );
  }
}

interface IProps extends RouteComponentProps<any> {
  mailSignUp: (name: string, email: string, password: string) => void;
  onClickBackButton: () => void;
}
interface IState {}

const Content = styled.div`
  max-height: 65vh;
  overflow: scroll;
`;
