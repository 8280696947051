import { AppState, initialState } from "./state";
import {
  ADD_CURRENT_COMMUNICATION,
  AppActionTypes,
  DELETE_CURRENT_COMMUNICATION,
  DELETE_CURRENT_ERROR,
  UPDATE_ACTIVE_FREE_PLAN_ITEM,
  UPDATE_ACTIVE_MENU_ITEM,
  UPDATE_ACTIVE_ORDER_ID,
  UPDATE_ACTIVE_SHOP,
  UPDATE_CURRENT_ERROR,
  UPDATE_IS_LOADING,
  UPDATE_IS_SHOWING_DRAWER,
  UPDATE_MODAL,
  UPDATE_POP_UP_MODAL
} from "./types";

export default function (
  state = initialState,
  action: AppActionTypes
): AppState {
  switch (action.type) {
    case UPDATE_MODAL:
      return Object.assign({}, state, {
        modal: action.modal
      });
    case ADD_CURRENT_COMMUNICATION: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case DELETE_CURRENT_COMMUNICATION: {
      return Object.assign({}, state, {
        isLoading: false
      });
    }
    case UPDATE_CURRENT_ERROR: {
      return Object.assign({}, state, { currentError: action.currentError });
    }
    case DELETE_CURRENT_ERROR: {
      return Object.assign({}, state, { currentError: undefined });
    }
    case UPDATE_ACTIVE_MENU_ITEM: {
      return Object.assign({}, state, { activeMenu: action.menu });
    }
    case UPDATE_ACTIVE_FREE_PLAN_ITEM: {
      return Object.assign({}, state, { activeFreePlanItemId: action.planId });
    }
    case UPDATE_ACTIVE_SHOP: {
      return Object.assign({}, state, { activeShop: action.shopId });
    }
    case UPDATE_IS_LOADING: {
      return Object.assign({}, state, {
        isLoading: action.isLoading
      });
    }
    case UPDATE_POP_UP_MODAL: {
      return Object.assign({}, state, {
        popUp: action.popUp
      });
    }
    case UPDATE_IS_SHOWING_DRAWER: {
      return Object.assign({}, state, {
        isShowingDrawer: action.isShowing
      });
    }
    case UPDATE_ACTIVE_ORDER_ID: {
      return Object.assign({}, state, {
        activeOrderId: action.orderId
      });
    }
    default:
      return state;
  }
}
