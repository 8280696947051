import { connect } from "react-redux";
import State from "../../../store/state";
import ConfirmOrderAddressComponent from "./component";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const ConfirmOrderAddress = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderAddressComponent);

export default ConfirmOrderAddress;
