
export const actions = {
  onClickMailSignUpAction: "MAIL_SIGN_UP_ON_CLICK_SIGN_UP_ACTION",
};

export const dispatchers = {
  handleOnClickSignUp: (name: string, mail: string, password: string)  => ({
    type: actions.onClickMailSignUpAction,
    name,
    mail,
    password
  })
};

export default actions;
