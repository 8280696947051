import React from "react";
import { Dropdown } from "semantic-ui-react";

export default class TimeDropdown extends React.Component<IProps, any> {

  render() {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "100px" }}>
          <Dropdown
            placeholder={"お受け取り時間"}
            value={this.props.currentTime || ""}
            fluid
            selection
            options={this.makeOptions(this.props.timeList)}
            onChange={(event, data) => this.props.onChange(data.value as string)}
          />
        </div>
      </div>
    );
  }
  private makeOptions(time_list: any[]) {
    return time_list.map(v => {
      return {
        text: v,
        value: v
      };
    });
  }
}
interface IProps {
  currentTime?: string;
  onChange: (data: string) => void;
  timeList: string[];
}
