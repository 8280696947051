import React from "react";
import styled from "styled-components";
import { color } from "../../../assets/colors";
import NoImg from "../../../assets/images/no_image.svg";

const MenuItem = (props: IProps) => {
  return (
    <div key={props.tagKey}>
      <Wrapper
        onClick={props.onClick}
        width={props.width ? props.width : "100%"}
      >
        <Image src={props.image_url ? props.image_url : NoImg} />
        <Contents color={props.grayOut ? "gray" : "black"}>
          {props.is_soldout ? (
            <div>
              <Title>{props.name}</Title>
              <Soldout>（売切）</Soldout>{" "}
            </div>
          ) : (
              <div>
                <Title>{props.name}</Title>
              </div>
            )}
          <Description>{props.description}</Description>
          <PriceWrapper>
            <Price>¥{props.price}</Price>
            <TaxText>{props.exTax ? "(税抜)" : "(税込)"}</TaxText>
          </PriceWrapper>
        </Contents>
      </Wrapper>
    </div>
  );
};

interface IProps {
  width?: string;
  tagKey: string;
  name: string;
  image_url?: string;
  is_soldout: boolean;
  price: string;
  description: string;
  grayOut: boolean;
  exTax: boolean;
  onClick: () => void;
}

const Wrapper = styled.div`
  width: ${(props: { width: string }) => props.width};
  display: flex;
  display: -webkit-flex;
  padding: 15px 0;
`;

const Image = styled.img`
  margin: 0 20px;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 8px;
  object-fit: cover;
`;

const Contents = styled.div`
  padding: 7px 10px 0 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${(props: { color: string }) => props.color};
`;

const Title = styled.span`
  font-size: 16px;
`;
const Soldout = styled.span`
  color: red;
`;

const Description = styled.div`
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 3;
  margin-top: 5px;
  color: ${color.grayText}
  font-size: 12px;
  line-height: 14px;
`;

const PriceWrapper = styled.div`
`;
const Price = styled.span`
  font-size: 16px;
`;
const TaxText = styled.span`
  margin-left: 5px;
`;

export default MenuItem;
