import React from "react";
import styled from "styled-components";
import SecondaryHeader from "../../components/atoms/secondary_header";
import CardRegistered from "../../components/molecules/card_registered";
import AddCard from "../add_card/container";

export default class ConfirmOrderCardComponent extends React.Component<IProps> {
  render() {
    return (
      <Wrapper>
        {(this.props.brand && this.props.last) ? (
          <div>
            <SecondaryHeader>カード情報</SecondaryHeader>
            <Description>下記のカードを使用します</Description>
            <Card>
              <CardRegistered brand={this.props.brand} last={this.props.last} />
            </Card>
          </div>
          ) : (
            <AddCard
              addCardText={"カード登録"}
              sectionHeaderText={"クレジットカード登録"}
              backTo={"confirm_order"}
            />
          )
        }
      </Wrapper>
    );
  }
}

interface IProps {
  hasCard: boolean;
  brand?: string;
  last?: string;
}

const Wrapper = styled.div`
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  padding-left: 32px;
  margin-top: 20px;
  margin-bottom: 36px;
`;

const Description = styled.div`
  padding-left:32px
  margin-top:27px
`;
