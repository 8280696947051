import { connect } from "react-redux";
import { dispatchers } from "./action";
import State from "../../../store/state";
import { LaunchScreenComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    checkIn: () =>
      dispatch(dispatchers.handleCheckIn()),
    lineLogin: (accessToken: string, navigateTo: string) =>
      dispatch(dispatchers.handleLineLogin(accessToken, navigateTo))
  };
};

export const LaunchScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(LaunchScreenComponent);

export default LaunchScreen;
