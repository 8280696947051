export default class Routes {
  private constructor() {}

  static default = "/";
  static shops = "/shops";
  static menus = "/menus";
  static history = "/history";
  static account = "/account";
  static addCard = "/add_card";
  static aboutMobileOrder = "/about_mo";
  static faq = "/faq";
  static error = "/error/:type";
  static logoutConfirm = "/logoutConfirm";
  static confirmOrder = "/confirm_order";
  static orderDetail = "/order_detail";
}
