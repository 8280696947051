import { ApiService } from "../api/ApiService";
import { FirebaseService } from "../firebase/FirebaseService";
import { call, put, select } from "@redux-saga/core/effects";
import {
  updateAccessTokenAction,
  updateCards,
  updateUserAction,
} from "../../store/user/actions";
import { getAccessToken, getUser, getCards } from "../../store/sagas";

const liff = window.liff;

export const initLiff = async (liffId: string): Promise<string> => {
  try {
    // NOTE: initされたかどうかを判定するために、適当なAPIを呼んでいる
    liff.isLoggedIn();
  } catch (e) {
    await liff.init(
        { liffId },
        async () => {
          console.log("success");
        },
        (error: any) => console.log(error),
    );
    // NOTE: 大丈夫か？
    // NOTE: liff.state がおかしいとエラーが出るがうまく動く
    console.log(e);
  }
  return liffId;
};

const customLineSignIn = async (firebaseClient: FirebaseService, apiClient: ApiService, accessToken: string) => {
  if (!firebaseClient.currentUser) {
    const customToken = await apiClient.getLineFirebaseToken(
        "company_id_here",
        accessToken
  );
    await firebaseClient.signInWithCustomToken(customToken);
  }
};

export default class UserRepository {
  constructor(
    private apiClient: ApiService,
    private firebaseClient: FirebaseService
  ) {
    this.fetchAndStoreAccessToken = this.fetchAndStoreAccessToken.bind(this);
    this.fetchCardsAndStore = this.fetchCardsAndStore.bind(this);
    this.isFirebaseSignedIn = this.isFirebaseSignedIn.bind(this);
    this.isDiniiSignedIn = this.isDiniiSignedIn.bind(this);
    this.signInWithEmailAndStoreUser = this.signInWithEmailAndStoreUser.bind(this);
    this.signUpWithEmailAndStoreUser = this.signUpWithEmailAndStoreUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.twitterSignInWithRedirect = this.twitterSignInWithRedirect.bind(this);
    this.firebaseCustomLoginByLineID = this.firebaseCustomLoginByLineID.bind(this);
    this.redirectToLineAuthPage = this.redirectToLineAuthPage.bind(this);
    this.signOut = this.signOut.bind(this);
    this.userConnect = this.userConnect.bind(this);
    this.sendCard = this.sendCard.bind(this);
    this.sendEmailVerification = this.sendEmailVerification.bind(this);
  }

  *signOut() {
    const idToken = yield call(this.firebaseClient.getIdTokenIfExist);
    if (idToken) {
      yield call(this.firebaseClient.signOut);
      yield call(this.apiClient.signOut, idToken);
    }
  }

  *userConnect() {
    const accessToken = yield select(getAccessToken);
    const idToken = yield call(this.firebaseClient.getIdToken);
    yield call(this.apiClient.userConnect, accessToken, idToken);
    const user = yield call(this.firebaseClient.getCurrentUser);
    yield put(updateUserAction({
      name: user.displayName,
      user_id: user.id,
      email: user.email
    }));
  }

  async twitterSignInWithRedirect() {
    await this.firebaseClient.twitterSignInWithRedirect();
  }

  async isFirebaseSignedIn(): Promise<boolean> {
    return await this.firebaseClient.isSignedIn();
  }

  async isDiniiSignedIn(): Promise<boolean> {
    const isDiniiSignedIn = await select(getUser);
    return !!isDiniiSignedIn;
  }

  async firebaseCustomLoginByLineID(liffId: string): Promise<Error | void> {
    await initLiff(liffId);
    const liffAccessToken = liff.getAccessToken();
    if (!liffAccessToken) {
      return new Error("cannot fetch line access token");
    }
    await customLineSignIn(this.firebaseClient, this.apiClient, liffAccessToken);
  }

  async redirectToLineAuthPage(liffId: string, diniiAccessToken: string): Promise<void> {
    await initLiff(liffId);
    const queryString = decodeURIComponent(`?navigateTo=menus&accessToken=${diniiAccessToken}`);
    liff.login({
      redirectUri: `${window.location.origin}${queryString}`,
    });
  }

  *signInWithEmailAndStoreUser(mail: string, password: string) {
    const user = yield call(this.firebaseClient.signInWithEmail, mail, password);
    yield put(updateUserAction({
      user_id: user.uid,
      name: user.displayName,
      email: mail,
      provider: "MAIL",
    }));
  }

  *signUpWithEmailAndStoreUser(name: string, mail: string, password: string) {
    const user = yield call(this.firebaseClient.signUpWithEmail, name, mail, password);
    yield put(updateUserAction({
      user_id: user.uid,
      name: user.displayName,
      email: mail,
      provider: "MAIL",
    }));
  }

  *sendEmailVerification() {
    yield call(this.firebaseClient.sendEmailVerification);
  }

  *updateUser(name: string) {
    yield call(this.firebaseClient.updateUserProfile, name);
    yield put(updateUserAction({
      name
    }));
  }

  *fetchAndStoreAccessToken() {
    const response = yield call(this.apiClient.getAccessToken);
    yield put(updateAccessTokenAction(response.access_token));
  }

  *sendCard(token: string) {
    const idToken = yield call(this.firebaseClient.getIdToken);
    yield call(this.apiClient.addCard, idToken, token);
  }

  *fetchCardsAndStore() {
    const idToken = yield call(this.firebaseClient.getIdToken);
    const card = yield call(this.apiClient.listCards, idToken);
    yield put(updateCards(card));
  }

  *selectCards() {
    return yield select(getCards);
  }
}
