import React from "react";
import styled from "styled-components";

export default class FAQComponent extends React.Component<IProps, IState> {
  render() {
    return (
            <div>
                <Wrapper>
                    <TitleText>ログインができません</TitleText>
                    <Text>
                        {" "}
                        前回と異なるログイン方法だとログインが上手くできない場合がございます。
                        それぞれのログイン方法をもう一度お試しください。
                    </Text>
                    <TitleText>
                        前回登録したパスワードを忘れてしまったのですが、どうずればいいですか?
                    </TitleText>
                    <Text>
                        ログイン画面に「パスワード再設定」のボタンがあるのでこちらをタップし、
                        パスワードを再設定してください。
                    </Text>
                    <TitleText>
                        クレジットカード以外の支払い方法はありませんか?
                    </TitleText>
                    <Text>
                        現状、クレジットカード以外での支払いはできません。
                        <br />
                        クレジットカード以外での支払いは、直接レジにて、ご注文・会計をお願いいたします。
                    </Text>
                    <TitleText>
                        前回クレジットカードを登録したはずなのに、今回ログインしたらカード情報が消えています。大丈夫なのですか?
                    </TitleText>
                    <Text>
                        前回とログイン方法が異なっている可能性があります。
                        <br />
                        一度マイページからログアウト後、前回と同様の方法でログインし、登録情報をご確認ください。
                    </Text>
                    <TitleText>
                        間違えてオーダーしてしまったのですが、どうすればいいですか?
                    </TitleText>
                    <Text>
                        こちらでキャンセルしますので、取り消したい注文内容をお伝えください。
                    </Text>
                    <TitleText>
                        2人で同じQRから注文した場合はどのように支払いがされるのですか?
                    </TitleText>
                    <Text>
                        それぞれのアプリからご注文いただいた金額を、それぞれの登録カードからお支払いただきます。
                        {/* お帰りの際に、1
            人の方にまとめて全ての注文分をお支払いいただきます。
            <br />
            現状、割り勘や個別会計などはできません。 */}
                    </Text>
                </Wrapper>
            </div>
    );
  }
}

interface IProps {}
interface IState {}

const Wrapper = styled.div`
  width: 90%;
  margin: 10px auto;
  margin-bottom: 40px;
  overflow: scroll;
`;
const TitleText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 40px auto 12px;
`;
const Text = styled.div`
  font-size: 14px;
`;
