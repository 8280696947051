import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import thanksImage from "../../../assets/images/thanks.svg";
import OrderDetailInfo from "../../organisms/order_detail_info/container";
import OrderDetailMap from "../../organisms/order_detail_map/container";
import HowToGetItem from "../../organisms/how_to_get_item/container";
import OrderDetailMenus from "../../organisms/order_detail_menus/container";
import OrderDetailBackButton from "../../organisms/order_detail_backbutton/container";
import { OrderType } from "../../../infra/domain/entities";

export class OrderDetailComponent extends React.Component<IProps> {
  render() {
    return (
      <Wrapper>
        <Image src={thanksImage} style={{ width: "100%" }} />
        <OrderDetailInfo />
        {this.props.isShowHowToGetItem &&
        this.props.orderType === OrderType.TAKEOUT &&
          <HowToGetItem />
        }
        {this.props.orderType === OrderType.TAKEOUT &&
          <OrderDetailMap />
        }
        <OrderDetailMenus order_type={this.props.orderType}  />
        <ButtonLayout>
          <OrderDetailBackButton />
        </ButtonLayout>
      </Wrapper>
    );
  }
}

interface IProps {
  isShowHowToGetItem: boolean;
  orderType: OrderType;
  paidAmount?: number;
}

const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
  width: 100%;
`;

const ButtonLayout = styled.div`
  padding: 30px 32px;
`;
