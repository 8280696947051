import { User } from "../../infra/domain/User";
import { StpCard } from "../../infra/domain/StpCard";

export interface UserState {
  user?: User;
  accessToken?: string;
  cards: StpCard[];
}

export const initialState: UserState = {
  user: undefined,
  accessToken: undefined,
  cards: []
};
