import CustomError from "../errors";
import { Company, Cart } from "../domain/entities";
import { ApiService, PostOrderArguments } from "./ApiService";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

const handleErrors = async (res: AxiosResponse) => {
  if (res.status === 200) {
    return res;
  }
  const message = res.data;
  if (message.hasOwnProperty("user_title")) {
    throw new CustomError(
      message.user_title,
      message.user_message,
      res.status,
      undefined,
      message.code
    );
  } else {
    switch (res.status) {
      // case 400: throw Error('INVALID_TOKEN');
      default:
        throw new Error("api error");
    }
  }
};

const createAxiosInstance = (baseURL: string) => {
  // Create Axios Service
  const service = axios.create({
    baseURL,
    withCredentials: false,
    timeout: 15000
  });

  service.interceptors.response.use(
    async response => {
      try {
        await handleErrors(response);
      } catch (error) {
        throw error;
      }
      return response.data.data;
    },
    async error => {
      try {
        await handleErrors(error.response);
      } catch (e) {
        throw e;
      }
    }
  );

  return service;
};

const createAxiosConfigWithAccessToken: (
  accessToken: string
) => AxiosRequestConfig = (accessToken: string) => {
  return {
    headers: {
      "x-access-token": accessToken,
      "Content-Type": "application/json"
    }
  };
};

const createAxiosConfigWithIdToken: (idToken: string) => AxiosRequestConfig = (
  idToken: string
) => {
  return {
    headers: {
      "x-auth-key": idToken,
      "Content-Type": "application/json"
    }
  };
};

const createAxiosConfigWithAccessTokenAndIdToken: (
  accessToken: string,
  idToken: string
) => AxiosRequestConfig = (accessToken: string, idToken: string) => {
  return {
    headers: {
      "x-access-token": accessToken,
      "x-auth-key": idToken,
      "Content-Type": "application/json"
    }
  };
};

const createDeleteAxiosConfigWithIdToken: (
  idToken: string,
  params: object
) => AxiosRequestConfig = (idToken: string, params: object) => {
  return {
    headers: {
      "x-auth-key": idToken,
      "Content-Type": "application/json"
    },
    params
  };
};

export default class ApiClient implements ApiService {
  private axiosInstance: AxiosInstance;

  constructor(readonly url: string) {
    this.url = url;
    this.getAccessToken = this.getAccessToken.bind(this);
    this.userConnect = this.userConnect.bind(this);
    this.signOut = this.signOut.bind(this);
    this.listVisitTimes = this.listVisitTimes.bind(this);
    this.listVisitDates = this.listVisitDates.bind(this);
    this.getUser = this.getUser.bind(this);
    this.listCards = this.listCards.bind(this);
    this.getShop = this.getShop.bind(this);
    this.listMenus = this.listMenus.bind(this);
    this.getCartByAccessToken = this.getCartByAccessToken.bind(this);
    this.getCompanyInfo = this.getCompanyInfo.bind(this);
    this.getCartByAuthKey = this.getCartByAuthKey.bind(this);
    this.updateCartByAccessToken = this.updateCartByAccessToken.bind(this);
    this.updateCartByAuthKey = this.updateCartByAuthKey.bind(this);
    this.orderRequest = this.orderRequest.bind(this);
    this.listShop = this.listShop.bind(this);
    this.addCard = this.addCard.bind(this);
    this.getMenu = this.getMenu.bind(this);
    this.listOrders = this.listOrders.bind(this);
    this.axiosInstance = createAxiosInstance(url);
  }

  async userConnect(accessToken: string, idToken: string) {
    return await this.axiosInstance.get(
      `${this.url}/connect`,
      createAxiosConfigWithAccessTokenAndIdToken(accessToken, idToken)
    );
  }

  async getAccessToken() {
    return await this.axiosInstance.get(`${this.url}/access_token`);
  }

  async signOut(idToken: string) {
    return await this.axiosInstance.get(
      `${this.url}/signout`,
      createAxiosConfigWithIdToken(idToken)
    );
  }

  async getUser(accessToken: string, idToken: string) {
    return await this.axiosInstance.get(
      `${this.url}/users/_user`,
      createAxiosConfigWithAccessTokenAndIdToken(accessToken, idToken)
    );
  }

  async getLineFirebaseToken(
      companyId: string,
      lineAccessToken: string,
  ): Promise<string> {
    const option = {
      headers: {
        "x-liff-access-token": lineAccessToken,
      },
    };
    try {
      return await this.axiosInstance.get(
          `${this.url}/liff/sign_in`,
          option
      );
    } catch (err) {
      console.error("getlinefirebase api error:", err);
      throw err;
    }
  }

  async getCompanyInfo(accessToken: string): Promise<Company> {
    return await this.axiosInstance.get(
      `${this.url}/company/_user`,
      createAxiosConfigWithAccessToken(accessToken)
    );
  }

  async getCartByAccessToken(accessToken: string, shopId: string) {
    return await this.axiosInstance.get(
      `${this.url}/shops/${shopId}/cart`,
      createAxiosConfigWithAccessToken(accessToken)
    );
  }

  async getCartByAuthKey(idToken: string, shopId: string) {
    return await this.axiosInstance.get(
      `${this.url}/shops/${shopId}/cart`,
      createAxiosConfigWithIdToken(idToken)
    );
  }

  async updateCartByAccessToken(
    accessToken: string,
    shopId: string,
    cart: Cart
  ) {
    return await this.axiosInstance.post(
      `${this.url}/shops/${shopId}/cart`,
      { order_menus: cart.order_menus },
      createAxiosConfigWithAccessToken(accessToken)
    );
  }

  async updateCartByAuthKey(
    idToken: string,
    shopId: string,
    cart: Cart
  ) {
    return await this.axiosInstance.post(
      `${this.url}/shops/${shopId}/cart`,
      { order_menus: cart.order_menus },
      createAxiosConfigWithIdToken(idToken)
    );
  }

  async listVisitDates(shopId: string, accessToken: string, idToken: string, isDelivery: boolean): Promise<string[]> {
    const query = isDelivery ? "?is_delivery=true" : "";
    return await this.axiosInstance.get(
        `${this.url}/shops/${shopId}/visit_dates${query}`,
        createAxiosConfigWithAccessTokenAndIdToken(accessToken, idToken)
    );
  }

  async listVisitTimes(shopId: string, accessToken: string, idToken: string, isDelivery: boolean, visitDate? :string): Promise<string[]> {
    return await this.axiosInstance.get(
      `${this.url}/shops/${shopId}/visit_times`,
      {...createAxiosConfigWithAccessTokenAndIdToken(accessToken, idToken),
        params: {
          is_delivery: isDelivery,
          visit_date: visitDate
        }}
    );
  }

  async orderRequest(shopId: string, idToken: string, args: PostOrderArguments) {
    const { visitTime, visitDate, addressInfo, orderType, paymentMethod, phoneNumber, nickName } =  args;
    return await this.axiosInstance.post(
      `${this.url}/shops/${shopId}/order`,
      { visit_time: visitTime,
        visit_date: visitDate,
        address_info: addressInfo ? {
          address1: addressInfo.address1,
          address2: addressInfo.address2,
          zip_code: addressInfo.zipCode
        } : undefined,
        order_type: orderType,
        payment_method: paymentMethod,
        phone_number: phoneNumber,
        nick_name: nickName},
      createAxiosConfigWithIdToken(idToken)
    );
  }

  async listMenus(accessToken: string, shopId: string) {
    return await this.axiosInstance.get(
      `${this.url}/shops/${shopId}/menus/_user`,
      createAxiosConfigWithAccessToken(accessToken)
    );
  }

  async getMenu(accessToken: string, shopId: string, menuId: string) {
    return await this.axiosInstance.get(
      `${this.url}/shops/${shopId}/menus/${menuId}/_user`,
      createAxiosConfigWithAccessToken(accessToken)
    );
  }

  async listShop(accessToken: string) {
    return await this.axiosInstance.get(
      `${this.url}/shops/_user`,
      createAxiosConfigWithAccessToken(accessToken)
    );
  }

  async listOrders(accessToken: string, idToken: string) {
    return await this.axiosInstance.get(
      `${this.url}/orders`,
      createAxiosConfigWithAccessTokenAndIdToken(accessToken, idToken)
    );
  }

  async getOrder(accessToken: string, idToken: string, orderId: string) {
    return await this.axiosInstance.get(
      `${this.url}/orders/${orderId}`,
      createAxiosConfigWithAccessTokenAndIdToken(accessToken, idToken)
    );
  }

  async getShop(accessToken: string, shopId: string) {
    return await this.axiosInstance.get(
      `${this.url}/shops/${shopId}/_user`,
      createAxiosConfigWithAccessToken(accessToken)
    );
  }

  async addCard(idToken: string, token: string) {
    return await this.axiosInstance.post(
      `${this.url}/cards`,
      { card: token },
      createAxiosConfigWithIdToken(idToken)
    );
  }

  async deleteCard(idToken: string, cardId: string) {
    return await this.axiosInstance.delete(
      `${this.url}/card`,
      createDeleteAxiosConfigWithIdToken(idToken, { cardId })
    );
  }

  async listCards(idToken: string) {
    return await this.axiosInstance.get(
      `${this.url}/cards`,
      createAxiosConfigWithIdToken(idToken)
    );
  }
}
