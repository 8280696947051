import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";
import WarningImg from "../../../assets/images/warning.svg";
import ModalTitle from "../../components/atoms/modal_title";
import { ErrorDisplayType } from "./container";
import PrimaryButton from "../../components/atoms/primary_button";

export class ErrorPageComponent extends React.Component<IProps, IState> {
  render() {
    const type = this.props.match.params.typeString;

    let title = "";
    let message = "";

    switch (type) {
      case ErrorDisplayType.NO_TABLE:
        title = "チェックインに失敗しました";
        message = "QRコードを読み込み直してください";
        break;
      default:
        title = "不適切なURLです";
        message = "";
        break;
    }

    return (
      <div>
        <Image
          src={WarningImg}
          style={{ width: "100%", height: "146px", objectFit: "cover" }}
        />
        <TextSection>
          <ModalTitle>{title}</ModalTitle>
          <Text>{message}</Text>
        </TextSection>
        <ButtonLayout>
          <PrimaryButton width={"100%"} onClick={this.props.backToHome}>
            ホームに戻る
          </PrimaryButton>
        </ButtonLayout>
      </div>
    );
  }
}

interface IProps
  extends RouteComponentProps<{
    typeString: string;
  }> {
  backToHome: () => void;
}

interface IState {}

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 18px auto;
`;
const TextSection = styled.div`
  text-align: center;
  margin: 32px auto;
`;

const ButtonLayout = styled.div`
  padding: 50px;
`;
