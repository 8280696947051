import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import SecondaryHeader from "../../components/atoms/secondary_header";
import MyAccountMenuElement from "../../components/atoms/myaccount_menu_element";
import Routes from "../../routes";

export class AccountMenuComponent extends React.Component<IProps, IState> {
  render() {
    return (
            <div>
                <SecondaryHeader>その他</SecondaryHeader>
                <Wrapper>
                    <MyAccountMenuElement
                        title="お問い合わせ"
                        onClick={() =>
                            window.open(
                                "https://docs.google.com/forms/d/128wmVoTK1k2UirGAyy0R7PZDedOle1s-YUckz0TiRRY/edit",
                                "_blank"
                            )
                        }
                    />
                    <MyAccountMenuElement
                        title="利用規約"
                        onClick={() => window.open("https://info.dinii.jp", "_blank")}
                    />
                    <MyAccountMenuElement
                        title="プライバシーポリシー"
                        onClick={() =>
                            window.open("https://info.dinii.jp/privacy.html", "_blank")
                        }
                    />
                    {this.props.isSignedIn && (
                        <div>
                            <MyAccountMenuElement
                                title="ログアウト"
                                onClick={() => this.props.history.push(Routes.logoutConfirm)}
                            />
                            {/* <MyAccountMenuElement title="退会" onClick={() => { }} /> */}
                        </div>
                    )}
                </Wrapper>
            </div>
    );
  }
}

interface IProps extends RouteComponentProps<any> {
  isSignedIn: boolean;
}

interface IState {
}

const Wrapper = styled.div`
  margin: 0 20px;
`;
