import { OrderMenu } from "../../../infra/domain/entities";

export const actions = {
  clickAddCart: "MENU_ITEM_DETAIL_ADD_CART",
  loadMenu: "MENU_ITEM_DETAIL_LOAD_MENU",
};

export const dispatchers = {
  handleClickAddCart: (cartItem: OrderMenu) => ({
    type: actions.clickAddCart,
    cartItem
  }),
  handleLoadMenu: (menuId: string) => ({
    type: actions.loadMenu,
    menuId
  })
};

export default actions;
