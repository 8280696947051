import React from "react";
import { Modal as SemanticModal, Transition } from "semantic-ui-react";
import { modalGa } from "./ga";
import { ZIndexSettings } from "../../config/ZIndexSettings";
import { Modal } from "../../../store/app/state";
import { ConnectedComponent } from "react-redux";

export default (ModalWindow: typeof React.Component | ConnectedComponent<any, any>): typeof React.Component =>
  class extends React.Component<IProps, IState> {
    private isCloseCalled = false;

    private closeModal() {
      if (this.props.onModalHide !== undefined) {
        this.props.onModalHide();
        this.isCloseCalled = true;
      }
      if (this.props.modalClose !== undefined) this.props.modalClose();
    }

    private onShow() {
      modalGa(this.props.modalName);
      this.isCloseCalled = false;
    }

    private onHide() {
      if (!this.isCloseCalled) {
        if (this.props.onModalHide !== undefined) {
          this.props.onModalHide();
        }
      }
    }

    render() {
      return this.props.isBottomModal ? (
          <Transition
            visible={this.props.isModal}
            animation={"fade up"}
            unmountOnHide={true}
            transitionOnMount={false}
            onShow={() => this.onShow()}
            onHide={() => this.onHide()}
            duration={500}
          >
            <SemanticModal
              onClose={() => this.closeModal()}
              open={true}
              style={{
                bottom: "0",
                left: "0",
                width: "100%",
                position: "fixed",
                padding: this.props.noPadding ? "0px" : "30px 20px",
                zIndex: ZIndexSettings.BOTTOM_SHEET,
                borderRadius: 0,
                backgroundColor: "#FFFFFF"
              }}
            >
              <div id={`target-container-${this.props.modalName}`}>
              <ModalWindow {...this.props} />
              </div>
            </SemanticModal>
          </Transition>
        ) :
      (
        <Transition
          visible={this.props.isModal}
          animation={"fade"}
          unmountOnHide={true}
          onShow={() => this.onShow()}
          onHide={() => this.onHide()}
          duration={500}
        >
          <SemanticModal
            onClose={() => this.closeModal()}
            open={true}
            basic={this.props.isTransparent}
            style={{
              overflow: "hidden",
              borderRadius: 20,
              width: this.props.width,
              zIndex: ZIndexSettings.MODAL
            }}
          >
            <div id={`target-container-${this.props.modalName}`}>
              <ModalWindow {...this.props} />
            </div>
          </SemanticModal>
        </Transition>
      );
    }
  };

interface IProps {
  isModal: boolean;
  modalName: Modal;
  modalClose?: () => void;
  onModalHide?: () => void;
  width: string;
  isBottomModal?: boolean;
  isTransparent?: boolean;
  noPadding?: boolean;
}

interface IState {
}
