import State from "../../../store/state";
import { connect } from "react-redux";
import { ErrorModalComponent } from "./component";
import { deleteCurrentErrorAction } from "../../../store/app/actions";

const mapStateToProps = (state: State) => {
  return {
    title: state.app.currentError ? state.app.currentError.title : "",
    message: state.app.currentError ? state.app.currentError.message : "",
    isShow: state.app.currentError !== undefined
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onHide: () => dispatch(deleteCurrentErrorAction())
  };
};

export const ErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorModalComponent);

export default ErrorModal;
