import React from "react";
import styled from "styled-components";
import { Link as HashLink } from "react-scroll";
import MenuItem from "../../components/molecules/menu_item";
import { color } from "../../../assets/colors";
import { Menu } from "../../../infra/domain/entities";
import { CategorizedMenu } from "./container";
import "./tab.css";

export class MenuItemListComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleScrollSetActive = this.handleScrollSetActive.bind(this);
  }

  /**
   * Render
   */
  render() {
    return (
      <div style={{ marginBottom: "150px" }}>
        <MenuTabsWrapper className="menu_tab" id="menu_tab">
          {this.props.categorizedMenus.map(
            (categorizedMenu: CategorizedMenu) => {
              return (
                <HashLink
                  id={`link:${categorizedMenu.category_id}`}
                  key={`hash_key:${categorizedMenu.category_id}`}
                  className="tab-parent"
                  to={categorizedMenu.category_id}
                  onSetActive={this.handleScrollSetActive}
                  spy={true}
                  activeClass={"active-tab"}
                  containerId="target-list"
                  smooth={true}
                  offset={-110}
                  duration={400}
                  isDynamic={false}
                >
                  <div className="tab" style={{ fontSize: "14px" }}>
                    {categorizedMenu.name}
                  </div>
                </HashLink>
              );
            }
          )}
        </MenuTabsWrapper>
        <div>
          {this.props.categorizedMenus.map(
            (categorizedMenu: CategorizedMenu) => {
              return (
                <div
                  key={`menu_key:${categorizedMenu.category_id}`}
                  id={categorizedMenu.category_id}
                >
                  <MenuType>{categorizedMenu.name}</MenuType>
                  {categorizedMenu.menus
                    .filter((menu: Menu) => menu.is_display)
                    .map((menu: Menu) => {
                      return (
                        <MenuItem
                          key={`menuitemwrapper-${menu.menu_id}`}
                          tagKey={`menuitem-${menu.menu_id}`}
                          name={menu.name}
                          grayOut={false}
                          image_url={
                            menu.image_url ? menu.image_url : undefined
                          }
                          is_soldout={menu.is_soldout}
                          price={menu.price.toString()}
                          description={menu.description ? menu.description : ""}
                          exTax={this.props.exTax}
                          onClick={() => {
                            if (!menu.is_soldout) {
                              this.props.onClickMenuItem(menu);
                            }
                          }}
                        />
                      );
                    })}
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  }

  /**
   * Functions
   */
  private handleScrollSetActive(e: string) {
    const element = document.getElementById(`link:${e}`)!;
    const rect = element.getBoundingClientRect();
    const elemWidth = rect.right - rect.left;
    const scrollTo =
      window.parent.screen.width / 2 - (rect.left + elemWidth / 2);
    const menuTab = document.getElementById("menu_tab")!;
    const startPosition = menuTab.scrollLeft;
    const scrollValue = startPosition - scrollTo;
    menuTab.scrollLeft = scrollValue;
  }
}

interface IProps {
  categorizedMenus: CategorizedMenu[];
  onClickMenuItem: (item: Menu) => void;
  exTax: boolean;
}

const MenuTabsWrapper = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  background: white;
  border-bottom: 1px solid ${color.greyLight};
  z-index: 3;
  position: sticky;
  font-size: 20px;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 4px;
`;

const MenuType = styled.div`
  margin: 25px 0 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: ${color.greyDark};
`;
