import React from "react";
import { Divider, Image } from "semantic-ui-react";
import styled from "styled-components";
import plusButton from "../../../assets/images/plusButton.svg";
import minusButton from "../../../assets/images/minusButton.svg";
import closeButton from "../../../assets/images/closeButton.svg";
import NoImg from "../../../assets/images/no_image.svg";
import { color } from "../../../assets/colors";
import { EventGa } from "../../components/middleware/ga";
import {
  Menu,
  OrderChoice,
  OrderMenu,
  OrderOption
} from "../../../infra/domain/entities";
import OptionForm from "../../components/molecules/menu_option/option_form";
import { MoleculesSelectedChoice } from "../../components/molecules/menu_option/option_content";
import { Mutex } from "async-mutex";
import { ZIndexSettings } from "../../config/ZIndexSettings";
import TextAreaForm from "../../components/atoms/text_area_form";
import PrimaryButton from "../../components/atoms/primary_button";

const { CircleSpinner } = require("react-spinners-kit");

export class MenuDetailComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      cartItem: this.props.cartItem,
      activeIndex: 0,
      quantity: 1,
      remark: ""
    };
    this.plusButtonClick = this.plusButtonClick.bind(this);
    this.minusButtonClick = this.minusButtonClick.bind(this);
    this.pushCartButtonClick = this.pushCartButtonClick.bind(this);
    this.onRemarkChange = this.onRemarkChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (!prevState.cartItem && this.props.cartItem) {
      this.setState({
        cartItem: this.props.cartItem
      });
    }
  }

  render() {
    const menu = this.props.menu;
    if (!menu) {
      return <div>存在しない商品です</div>;
    }

    let optionPrice = 0;
    let totalPrice = 0;
    if (this.state.cartItem && this.state.cartItem.options) {
      this.state.cartItem.options.forEach(option => {
        option.choices.forEach(choice => {
          if (choice.quantity) {
            optionPrice += choice.price * choice.quantity;
          }
        });
      });
      totalPrice =
        this.state.cartItem.quantity * (this.props.menu!.price + optionPrice);
    } else {
      totalPrice = -1;
    }
    return (
      <ItemDetailWrap>
        <HeaderImageWrapper
          onClick={this.onClose}
          style={{ backgroundColor: "transparent", margin: 0, padding: 0 }}
        >
          <CloseButton src={closeButton} onClick={this.onClose} />
          <HeaderImage src={menu.image_url ? menu.image_url : NoImg} />
        </HeaderImageWrapper>
        <Content>
          <Title> {menu.name} </Title>

            <Description> {menu.description} </Description>

          {menu.options ? (
            menu.options!.map((option, optionIndex) => (
              <OptionForm
                defaultIndexes={option.default_indexes || []}
                isSoldOut={option.is_soldout}
                key={option.option_id}
                isMultiple={option.is_multiple}
                isRequired={option.is_required}
                maxNum={option.max_choice_num}
                minNum={option.min_choice_num}
                name={option.name}
                applyChoices={async (
                  selectedChoices: MoleculesSelectedChoice[]
                ) => await this.applyChoices(optionIndex, selectedChoices)}
                // FIXME: subOptionが複数の場合は修正
                choices={option.choices.map(choice => {
                  return {
                    defaultSubChoiceIndexes:
                      choice.sub_options && choice.sub_options.length > 0
                        ? choice.sub_options[0].default_indexes
                        : [],
                    isRecommended: choice.is_recommended,
                    isSoldOut: choice.is_soldout,
                    maxNum:
                      choice.sub_options && choice.sub_options.length > 0
                        ? choice.sub_options[0].max_choice_num
                        : 1,
                    minNum:
                      choice.sub_options && choice.sub_options.length > 0
                        ? choice.sub_options[0].min_choice_num
                        : 1,
                    name: choice.name,
                    imageUrl: choice.image_url,
                    price: choice.price,
                    subChoices:
                      choice.sub_options && choice.sub_options.length > 0
                        ? choice.sub_options[0].choices.map(subChoice => {
                          return subChoice.name;
                        })
                        : []
                  };
                })}
              />
            ))
          ) : (
            <div />
          )}

          {menu.options && !this.props.disableRemark && (
          <div style={{ padding: "20px" }}>
            <div style={{ padding: "0 0 5px 5px" }}>備考欄</div>
            <TextAreaForm onChange={this.onRemarkChange} />
          </div>
          ) }

            <QuantityForm>
              <QuantityButton
                src={minusButton}
                onClick={this.minusButtonClick}
              />
              {this.state.cartItem ? this.state.cartItem.quantity : 1}
              <QuantityButton src={plusButton} onClick={this.plusButtonClick} />
            </QuantityForm>

          {menu.options ? <div/> :
          <Loader>
            <CircleSpinner color={"#686769"} />
            <div style={{ margin: "20px" }}>読み込み中</div>
          </Loader>
          }

        </Content>

        <StickFooter>
          <Divider fitted={true} />
          <FormBottom>
            <PriceTextWrapper>
              <PriceText>¥{totalPrice === -1 ? "" : totalPrice}</PriceText>
              <TaxText>{this.props.exTax ? "(税抜)" : "(税込)"}</TaxText>
            </PriceTextWrapper>
            <ButtonLayout>
              {this.props.isOpen ? (
                <PrimaryButton
                  width={"100%"}
                  height={"100%"}
                  onClick={this.pushCartButtonClick}
                >
                  カートに入れる
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  width={"100%"}
                  height={"100%"}
                  color={color.grey}
                >
                  {this.props.closeText}
                </PrimaryButton>
              )}
            </ButtonLayout>
          </FormBottom>
        </StickFooter>
      </ItemDetailWrap>
    );
  }

  private mutex = new Mutex();
  private async applyChoices(
    optionIndex: number,
    choices: MoleculesSelectedChoice[]
  ) {
    const release = await this.mutex.acquire();
    try {
      if (this.state.cartItem) {
        const newOptions =
          this.state.cartItem.options &&
          this.state.cartItem.options.map((option: OrderOption, index) => {
            if (index === optionIndex) {
              const newChoices =
                option.choices &&
                option.choices.map((choice: OrderChoice, choiceIndex) => {
                  const filteredChoices = choices.filter(
                    moleculesChoice =>
                      moleculesChoice.choiceIndex === choiceIndex
                  );
                  if (filteredChoices.length > 0) {
                    const newSubOptions =
                      choice.options &&
                      choice.options.map(subOption => {
                        const newSubChoices = subOption.choices.map(
                          (subChoice, subChoiceIndex) => {
                            if (
                              filteredChoices[0].selectedSubChoices.filter(
                                selectedSubChoiceIndex =>
                                  selectedSubChoiceIndex === subChoiceIndex
                              ).length > 0
                            ) {
                              return Object.assign({}, subChoice, {
                                quantity: 1
                              });
                            }
                            return Object.assign({}, subChoice, {
                              quantity: 0
                            });
                          }
                        );
                        return Object.assign({}, subOption, {
                          choices: newSubChoices
                        });
                      });
                    return Object.assign({}, choice, {
                      quantity: 1,
                      options: newSubOptions
                    });
                  }
                  return Object.assign({}, choice, { quantity: 0 });
                });
              return Object.assign({}, option, { choices: newChoices });
            }
            return option;
          });
        const newCartItem = Object.assign({}, this.state.cartItem, {
          options: newOptions
        });
        this.setState({
          cartItem: newCartItem
        });
      }
    } finally {
      release();
    }
  }

  private plusButtonClick() {
    EventGa.incrementItem();
    if (!this.state.cartItem) return;
    const newCartItem = Object.assign({}, this.state.cartItem, {
      quantity: this.state.cartItem.quantity + 1
    });
    this.setState({
      cartItem: newCartItem
    });
  }

  private minusButtonClick() {
    EventGa.decrementItem();
    if (!this.state.cartItem) return;
    if (this.state.cartItem.quantity <= 1) return;
    const newCartItem = Object.assign({}, this.state.cartItem, {
      quantity: this.state.cartItem.quantity - 1
    });
    this.setState({
      cartItem: newCartItem
    });
  }

  private pushCartButtonClick() {
    EventGa.addCart();
    if (!this.state.cartItem) return;
    this.props.clickAddCartItem(this.state.cartItem);
    this.setState({ quantity: 1, remark: this.state.remark });
  }

  private onClose() {
    EventGa.itemDetailClose();
    this.setState({ quantity: 1 });
    this.closeModal();
  }

  private closeModal() {
    this.props.clickCloseModal();
  }

  private onRemarkChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newCartItem = Object.assign({}, this.state.cartItem, {
      remark: e.target.value
    });
    this.setState({
      cartItem: newCartItem
    });
  }
}

interface IProps {
  cartItem?: OrderMenu;
  menu?: Menu;
  isOpen: boolean;
  closeText: string;
  exTax: boolean;
  clickCloseModal: () => void;
  clickAddCartItem: (cartItem: OrderMenu) => void;
  loadMenu: (menuId: string) => void;
  disableRemark: boolean;
}

interface IState {
  cartItem?: OrderMenu;
  activeIndex: number;
  quantity: number;
  remark: string;
}

const ItemDetailWrap = styled.div`
  border-radius: 20px;
`;

const Content = styled.div`
  background-color: transparent;
  height: 40vh;
  overflow: scroll;
`;
const Title = styled.div`
  font-size: 18px;
  line-height: 40px;
  margin-left: 20px;
`;
const Description = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 20px;
`;

const CloseButton = styled(Image)`
  z-index: ${ZIndexSettings.MODAL};
  position: absolute !important;
  width: 35px;
  filter: drop-shadow(2px 3px 3px rgba(1, 1, 1, 0.3));
  padding: 3px;
  margin: 10px;
  background-color: transparent;
`;
const HeaderImageWrapper = styled.div`
  overflow: hidden;
  background-color: transparent;
`;
const HeaderImage = styled(Image)`
  height: 200px;
  width: 100%;
  background-color: transparent;
  object-fit: cover;
`;
const QuantityForm = styled.div`
  margin: 30px auto;
  display: flex;
  justify-content: center;
  font-size: 18px;
`;
const QuantityButton = styled(Image)`
  margin: 0 20px;
`;
const StickFooter = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
`;

const FormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const PriceTextWrapper = styled.div`
  padding-bottom: 3px;
`;

const PriceText = styled.span`
  font-size: 22px;
`;

const TaxText = styled.span`
  margin-left: 5px;
`;

const ButtonLayout = styled.div`
  width: 145px;
  height: 40px;
  color: white;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  line-height: 40px;
  padding: 0 5px;
`;

const Loader = styled.div`
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
