import { connect } from "react-redux";
import { ShopsComponent } from "./component";
import { dispatchers } from "./action";
import State from "../../../store/state";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    listShops: () => dispatch(dispatchers.listShops())
  };
};

export const Shops = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopsComponent);

export default Shops;
