import { put, take } from "redux-saga/effects";
import actions from "./action";
import { deleteCommunicationId, handleError, putCommunicationId } from "../../../store/sagas";
import uuid from "uuid";
import { call } from "@redux-saga/core/effects";
import UserRepository from "../../../infra/repository/UserRepository";
import { updatePopUpAction } from "../../../store/app/actions";
import { PopUpModal } from "../../../store/app/state";
import CustomError from "../../../infra/errors";

function handleOnClickSendEmailVerification(
  userRepository: UserRepository,
) {
  return function* () {
    while (true) {
      yield take(actions.onClickSendEmailVerification);

      const communication = uuid();

      try {
        yield call(putCommunicationId, communication);

        yield call(userRepository.sendEmailVerification);

        yield put(updatePopUpAction(PopUpModal.SendEmailVerificationComplete));

      } catch (e) {
        console.error(e);
        yield handleError(new CustomError("認証メールの再送に失敗しました。", "時間をおいて再度お試しください。"));
      } finally {
        yield call(deleteCommunicationId, communication);
      }
    }
  };
}

function createSagas(
  userRepository: UserRepository,
) {
  return [handleOnClickSendEmailVerification(userRepository)];
}

export default createSagas;
