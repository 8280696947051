import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import CardVisa from "../../../assets/images/visa.svg";
import CardJCB from "../../../assets/images/JCB.svg";
import CardMaster from "../../../assets/images/mastercard.svg";
import CardAmerican from "../../../assets/images/americanExpress.svg";

export default class CardRegistered extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper>
        <Image
          src={this.getCardLogo(this.props.brand)}
          style={{ margin: "0 10px 0 0" }}
        />
        <Number>----</Number>
        <Number>----</Number>
        <Number>----</Number>
        <Number>{this.props.last}</Number>
      </Wrapper>
    );
  }

  private getCardLogo(brand: string) {
    switch (brand) {
      case "Visa":
        return CardVisa;
      case "MasterCard":
        return CardMaster;
      case "American Express":
        return CardAmerican;
      case "JCB":
        return CardJCB;
      default:
        return undefined;
    }
  }
}

interface IProps {
  brand: string,
  last: string
}
interface IState {}

const Number = styled.span`
  margin-left: 10px;
  letter-spacing: 1px;
`;

const Wrapper = styled.div`
  display: flex;
`;
