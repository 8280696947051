import React from "react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment-timezone";
import { dayIndexToText } from "../../../util/date_text_utils";

const makeOptions = (date_list: any[]) => {
  return date_list.map(v => {
    const m = moment(v).tz("Asia/Tokyo");
    return {
      text: `${m.format("YYYY年MM月DD日")}  (${dayIndexToText(m.day())})`,
      value: v
    };
  });
};

export default class DateDropdown extends React.Component<IProps, any> {

  render() {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Dropdown
            placeholder={"お受け取り日付"}
            value={this.props.currentDate || ""}
            fluid
            selection
            options={makeOptions(this.props.dateList)}
            onChange={(event, data) => this.props.onChange(data.value as string)}
          />
        </div>
      </div>
    );
  }
}
interface IProps {
  currentDate?: string;
  onChange: (data: string) => void;
  dateList: string[];
}
