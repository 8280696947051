import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { EventGa } from "../../components/middleware/ga";
import SecondaryButton from "../../components/atoms/secondary_button";
import { Image } from "semantic-ui-react";
import PersonImage from "../../../assets/images/person.svg";
import CheckRed from "../../../assets/images/check_red.svg";

export class LogoutConfirmComponent extends React.Component<IProps, IState> {
  render() {
    return (
            <div>
                <Wrapper>
                    <div>
                        <Image src={PersonImage} style={{ margin: "auto" }} />
                        <Title>本当にログアウトしますか？</Title>
                        <CheckList>
                            <Image src={CheckRed} />
                            <TextRed>次回利用時、再度ログインが必要になります</TextRed>
                        </CheckList>
                        <CheckList>
                            <Image src={CheckRed} />
                            <TextRed>カード情報が初期化され、再度登録が必要になります</TextRed>
                        </CheckList>
                        <Text>次回以降のスムーズなお食事のためにログアウトされないことをおすすめします</Text>
                        <ButtonSection>
                            <SecondaryButton
                                style={{ margin: "4px" }}
                                width="130px"
                                onClick={() => this.handleCancel()}
                            >
                                キャンセル
                            </SecondaryButton>
                            <div/>
                            <SecondaryButton
                                style={{ margin: "4px" }}
                                width="130px"
                                onClick={() => this.handleLogout()}
                            >
                                ログアウトする
                            </SecondaryButton>
                        </ButtonSection>
                    </div>
                </Wrapper>
            </div>
    );
  }
  private handleLogout() {
    EventGa.signout();
    this.props.signOut();
  }
  private handleCancel() {
    this.props.history.goBack();
  }
}

interface IProps extends RouteComponentProps<any> {
  signOut: () => void;
}
interface IState {}

const Wrapper = styled.div`
  width: 90%;
  margin: 60px auto;
`;

const CheckList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 10px;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 16px auto 20px;
`;

const Text = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
  white-space: pre-line;
`;
const TextRed = styled.div`
  width: 90%;
  font-size: 14px;
  text-align: left;
  color: #eb0e00;
  padding: 0 0 0 10px;
`;

const ButtonSection = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin: 100px auto;
`;
