import CustomError from "../../infra/errors";
import { Menu } from "../../infra/domain/entities";
import { Shop } from "../../infra/domain/shop";

export interface AppState {
  activeShop: Shop | undefined;
  isLoading: boolean;
  isShowingDrawer: boolean;
  modal: Modal;
  popUp: PopUpModal;
  currentError?: CustomError;
  activeMenu?: Menu;
  activeOrderId?: string;
  cartPriceAll?: number;
  cartTaxPrice?: number;
}

export enum Modal {
  NoModal = "noModal",
  IsLoading = "isLoading",
  ItemDetail = "itemDetail",
  Navigation = "navigation",
  Callstaff = "callstaff",
  Payment = "payment",
  FreePlan = "freePlan",
  CartConfirm = "cart",
  SignIn = "signIn",
  Login = "login",
  MailSignin = "mailSignin",
  MailSignup = "mailSignup",
  SelectNumPeople = "selectNumPeople",
  AfterCallStaff = "afterCallStaff",
  NetworkError = "networkError",
  AddCard = "AddCard",
  CloseTableUser = "CloseTableUser",
  ShopMap = "ShopMap"
}

export enum PopUpModal {
  None = "None",
  OrderComplete = "orderComplete",
  CardRegisterComplete = "cardRegisterComplete",
  SignOutComplete = "SignOutComplete",
  SignInComplete = "SignInComplete",
  UpdateUserComplete = "UpdateUserComplete",
  SendEmailVerificationComplete = "SendEmailVerificationComplete",
}

export const initialState: AppState = {
  activeShop: undefined,
  isShowingDrawer: false,
  modal: Modal.NoModal,
  popUp: PopUpModal.None,
  isLoading: false,
  activeMenu: undefined,
  currentError: undefined,
};
