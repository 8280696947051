export const actions = {
  updateUserName: "ACCOUNT_USER_INFO_UPDATE_USER_NAME",
};

export const dispatchers = {
  handleClickSaveButton: (name: string) => ({
    type: actions.updateUserName,
    name
  }),
};
