import React from "react";
import styled from "styled-components";
import { Divider } from "semantic-ui-react";
import OrderHistoryItem from "../../components/molecules/order_history_item";
import { OrderType } from "../../../infra/domain/entities";

export class OrderHistoryListComponent extends React.Component<IProps> {
  render() {
    return (
      <div>
        {this.props.orders.map(order => (
          <ItemWrapper key={this.props.orders.indexOf(order)}>
            <OrderHistoryItem
              dateTime={order.dateTime}
              place={order.place}
              orderNumber={order.orderNumber}
              orderStatus={order.orderStatus}
              onClick={() => this.props.onOrderClick(order.orderId)}
              orderType={order.orderType}
              isCanceled={order.isCanceled}
              isPartialRefunded={typeof order.refundedAmount !== "undefined" && order.refundedAmount > 0}
            />
            <Divider />
          </ItemWrapper>
        ))}
      </div>
    );
  }
}

interface IProps {
  orders: OrderHistoryListOrder[];
  onOrderClick: (orderId: string) => void;
}

export interface OrderHistoryListOrder {
  orderId: string;
  dateTime: string;
  place: string;
  orderNumber: string;
  orderStatus: string;
  orderType: OrderType;
  isCanceled: boolean;
  refundedAmount?: number;
}

const ItemWrapper = styled.div``;
