import React from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import ChoicesMultipleSelect from "./choice_multiple_select";
import ChoiceSingleSelect from "./choice_single_select";

export default class OptionContent extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const array: MoleculesSelectedChoice[] = [];

    this.props.defaultIndexes.forEach((index) => {
      /**
       * 初期選択subOptionはこの時点で取得可能なのでここで設定する
       */
      const choice = this.props.choices[index];
      const defaultSelectedSubChoiceIndexes = choice ? choice.defaultSubChoiceIndexes : [];
      array.push({
        choiceIndex: index,
        selectedSubChoices: defaultSelectedSubChoiceIndexes
      });
    });

    this.state = {
      selectedChoices: array
    };
    this.props.applyChoices(this.state.selectedChoices);
  }

  render() {
    return (
      <Wrapper>
        <div style={{ flexGrow: 1 }}>
        <Form>
          {this.props.choices.map(((choice, index) => {
            if (this.props.isMultiple) {
              return (<Form.Field key={choice.name}>
                <ChoicesMultipleSelect
                  defaultSubChoiceIndexes={choice.defaultSubChoiceIndexes}
                  isRecommended={choice.isRecommended}
                  isSoldOut={this.props.isSoldOut || choice.isSoldOut}
                  isSelected={this.state.selectedChoices.filter(option => option.choiceIndex === index).length > 0}
                  setSubChoices={(selectedSubChoices: number[]) =>
                      this.props.isSoldOut || choice.isSoldOut
                          ? {}
                          : this.setMultipleSelection(index, selectedSubChoices)}
                  maxSubChoiceNum={choice.maxNum}
                  minSubChoiceNum={choice.minNum}
                  imageUrl={choice.imageUrl}
                  name={choice.name}
                  price={choice.price}
                  subChoices={choice.subChoices}/>
              </Form.Field>);
            }
            return (<Form.Field key={choice.name}>
                <ChoiceSingleSelect
                  defaultSubChoiceIndexes={choice.defaultSubChoiceIndexes}
                  isRecommended={choice.isRecommended}
                  isSoldOut={this.props.isSoldOut || choice.isSoldOut}
                  isSelected={this.state.selectedChoices.filter(option => option.choiceIndex === index).length > 0}
                  setSelectSubChoices={(selectedSubChoices: number[]) =>
                      this.props.isSoldOut || choice.isSoldOut
                          ? {}
                          : this.setSingleSelection(index, selectedSubChoices)
                  }
                  maxSubChoiceNum={choice.maxNum}
                  minSubChoiceNum={choice.minNum}
                  price={choice.price}
                  imageUrl={choice.imageUrl}
                  name={choice.name}
                  subChoices={choice.subChoices}/>
              </Form.Field>);
          }))}
        </Form>
        </div>
      </Wrapper>
    );
  }

  setMultipleSelection(index: number, selectedSubChoices: number[]) {
    const sameSelectedOptions = this.state.selectedChoices.filter((option) => option.choiceIndex === index);
    // すでにある場合
    if (sameSelectedOptions.length > 0) {
      // 変更がない場合, 最低個数を上回っていなければ消す
      if (selectedSubChoices === sameSelectedOptions[0].selectedSubChoices) {
        if (this.state.selectedChoices.length > this.props.minSelectOrderNumber) {
          const newOptions = this.state.selectedChoices.filter(option => option.choiceIndex !== index);
          this.setState({
            selectedChoices: newOptions
          });
          this.props.applyChoices(newOptions);
        }
      // 変更があった場合は更新する
      } else {
        const newChoices = this.state.selectedChoices.map(choice => choice.choiceIndex === index
            ? { choiceIndex: index, selectedSubChoices } as MoleculesSelectedChoice
            : choice);
        this.setState({
          selectedChoices: newChoices
        });
        this.props.applyChoices(newChoices);
      }
      // 新しく追加する場合
    } else {
      let newOptions = this.state.selectedChoices.concat([{
        choiceIndex: index,
        selectedSubChoices
      }]);
      if (newOptions.length > this.props.maxSelectOrderNumber) {
        newOptions = newOptions.slice(1);
      }
      this.setState({
        selectedChoices: newOptions
      });
      this.props.applyChoices(newOptions);
    }
  }

  setSingleSelection(index: number, selectedSubChoices: number[]) {
    const newChoices = [{
      choiceIndex: index,
      selectedSubChoices
    }];
    this.setState({
      selectedChoices: newChoices
    });
    this.props.applyChoices(newChoices);
  }
}

interface Props {
  isSoldOut: boolean;
  choices: MoleculesChoice[];
  imageUrl?: string;
  isMultiple: boolean;
  minSelectOrderNumber: number;
  maxSelectOrderNumber: number;
  applyChoices: (selectedChoices: MoleculesSelectedChoice[]) => void;
  defaultIndexes: number[];
}

interface State {
  selectedChoices: MoleculesSelectedChoice[];
}

export interface MoleculesChoice {
  name: string;
  price: number;
  subChoices: string[];
  imageUrl?: string;
  maxNum: number;
  minNum: number;
  isSoldOut: boolean;
  isRecommended: boolean;
  defaultSubChoiceIndexes: number[];
}

export interface MoleculesSelectedChoice {
  choiceIndex: number;
  selectedSubChoices: number[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
`;
