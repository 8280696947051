import React from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import SecondaryHeader from "../../components/atoms/secondary_header";
import topImage from "../../../assets/images/HowToGetItemImage1.svg";
import middleImage from "../../../assets/images/HowToGetItemImage2.svg";
import bottomImage from "../../../assets/images/HowToGetItemImage3.svg";

export class HowToGetItemComponent extends React.Component<IProps> {
  render() {
    return (
      <Wrapper>
        <SecondaryHeader>受取方法</SecondaryHeader>
        <Top>
          <Image src={topImage} style={{ width: "100%" }} />
        </Top>
        <Middle>
          <Image src={middleImage} style={{ width: "100%" }} />
        </Middle>
        <Bottom>
          <Image src={bottomImage} style={{ width: "100%" }} />
        </Bottom>
      </Wrapper>
    );
  }
}

interface IProps {}

const Wrapper = styled.div`
  width: 100%;
`;

const Top = styled.div`
  padding-top: 16px;
  padding-bottom: 40px;
`;

const Middle = styled.div`
  padding-bottom: 40px;
`;

const Bottom = styled.div`
  padding-bottom: 40px;
`;
