export const actions = {
  onClickMailSignInAction: "MAIL_SIGN_IN_ON_CLICK_MAIL_SIGN_IN_ACTION"
};

export const dispatchers = {
  handleOnClickSignIn: (mail: string, password: string)  => ({
    type: actions.onClickMailSignInAction,
    mail,
    password
  })
};

export default actions;
