import React from "react";
import posed from "react-pose";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "../../../assets/colors";
import Routes from "../../routes";
import { ZIndexSettings } from "../../config/ZIndexSettings";

export class DrawerComponent extends React.Component<IProps, any> {
  render() {
    const navItemsUpper = [
      {
        url: Routes.account,
        name: "マイアカウント",
        show: true,
        onClick: () => {
          this.props.closeModal();
        }
      },
      {
        url: Routes.addCard,
        name: "クレジットカード登録",
        show: this.props.isSignedIn && this.props.enableStripe,
        onClick: () => this.props.closeModal()
      },
      {
        url: Routes.history,
        name: "注文履歴",
        show: this.props.isSignedIn,
        onClick: () => this.props.closeModal()
      }
    ];
    const navItemsLower = [
      {
        url: Routes.aboutMobileOrder,
        name: "モバイルオーダーって？",
        show: true,
        onClick: () => {
          this.props.closeModal();
        }
      },
      {
        url: Routes.faq,
        name: "よくある質問",
        show: true,
        onClick: () => {
          this.props.closeModal();
        }
      },
      {
        url: "/",
        name: "退会について",
        show: true,
        onClick: () => {
          window.open("https://docs.google.com/forms/d/e/1FAIpQLSeOWdkjKQG-0qL_swO7A8ZRZGKm3blXdBK5B66R69D5-3n4lA/viewform");
        }
      }
    ];
    return (
      <div>
        <Sidebar pose={this.props.visible ? "open" : "closed"}>
          <div style={{ padding: "0  12px" }}>
            {navItemsUpper
              .filter(item => item.show)
              .map(
                (navItem: {
                  url: string;
                  name: string;
                  onClick: () => void;
                }) => (
                    <Link
                      key={navItem.name}
                      to={navItem.url}
                      style={{ color: color.greyDark }}
                      onClick={navItem.onClick}
                    >
                      <NavItem style={{ height: "50px" }}>
                        <div style={{ margin: "auto 10px" }}>
                          {navItem.name}
                        </div>
                      </NavItem>
                    </Link>
                  )
              )}
          </div>
          <div style={{ padding: "0  12px" }}>
            {navItemsLower.map(
              (navItem: { url: string; name: string; onClick: () => void }) => (
                  <Link
                    key={navItem.name}
                    to={navItem.url}
                    style={{ color: color.greyDark }}
                    onClick={navItem.onClick}
                  >
                    <NavItem style={{ height: "50px" }}>
                      <div style={{ margin: "auto 10px" }}>{navItem.name}</div>
                    </NavItem>
                  </Link>
                )
            )}
          </div>
        </Sidebar>
        <ModalShade
          isOpen={this.props.visible}
          handleClick={() => this.props.closeModal()}
        />
      </div>
    );
  }
}

interface IProps {
  visible: boolean;
  isSignedIn: boolean;
  closeModal: () => void;
  isActive: boolean;
  naviImageUrl?: string;
  enableStripe: boolean;
}

const NavItemPose = posed.div({
  open: { opacity: 1 },
  closed: { opacity: 0 }
});

const ShadePose = posed.div({
  open: { opacity: 1, zIndex: ZIndexSettings.DRAWER_SHADOW },
  closed: { opacity: 0, zIndex: -1 }
});

const ModalShade = (props: any) => (
  <Shade pose={props.isOpen ? "open" : "closed"} onClick={props.handleClick} />
);

const NavItem = styled(NavItemPose)`
  font-size: 14px;
  display: flex;
  border-bottom: 1px solid #f1f1f1;
`;

const SidebarPose = posed.div({
  open: { x: "0%", staggerChildren: 100 },
  closed: { x: "-100%" }
});

const Sidebar = styled(SidebarPose)`
  position: fixed;
  z-index: ${ZIndexSettings.DRAWER};
  height: 100%;
  width: 230px;
  background: white;
  margin: 0;
  /* to avoid the bounce showing background through */
  padding-left: 20px;
  margin-left: -20px;
`;

const Shade = styled(ShadePose)`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
`;
