import { call, put, select, take } from "redux-saga/effects";
import actions from "./action";
import { getAccessToken, handleError, putCommunicationId } from "../../../store/sagas";
import EntityRepository from "../../../infra/repository/EntityRepository";
import UserRepository from "../../../infra/repository/UserRepository";
import { deleteCurrentCommunicationAction } from "../../../store/app/actions";
import uuid from "uuid";
import { push } from "connected-react-router";
import Routes from "../../routes";

function listShop(
  userRepository: UserRepository,
  entityRepository: EntityRepository,
) {
  return function* () {
    while (true) {
      yield take(actions.listShops);
      const communication = uuid();
      yield putCommunicationId(communication);
      try {

        const accessToken = yield select(getAccessToken);
        if (!accessToken) {
          yield put(push(Routes.default));
          continue;
        }

        yield call(entityRepository.fetchAndUpdateShopsAndCompany);
      } catch (error) {
        yield handleError(error);
      } finally {
        yield put(deleteCurrentCommunicationAction(communication));
      }
    }
  };
}

const createSagas = (
  userRepository: UserRepository,
  entityRepository: EntityRepository,
) => [listShop(userRepository, entityRepository)];

export default createSagas;
