import React from "react";
import { Modal as SemanticModal, Transition } from "semantic-ui-react";
import { ZIndexSettings } from "../../config/ZIndexSettings";

export default (ModalWindow: typeof React.Component): typeof React.Component =>
    class extends React.Component<IProps, IState> {

      private onShow(shouldClose: boolean) {
        if (shouldClose) {
          setTimeout(this.props.popUpClose, 1000);
        }
      }

      render() {
        return (
                <Transition
                    visible={this.props.isPopUp}
                    animation={"scale"}
                    onShow={() => this.onShow(this.props.autoClose)}
                    unmountOnHide={true}
                    duration={500}
                >
                    <SemanticModal
                        open={true}
                        basic={true}
                        style={{
                          overflow: "hidden",
                          borderRadius: 20,
                          zIndex: ZIndexSettings.POP_UP,
                        }}
                    >
                        <ModalWindow {...this.props} />
                    </SemanticModal>
                </Transition>
        );
      }
    };

interface IProps {
  isPopUp: boolean;
  autoClose: boolean;
  popUpClose: () => void;
}

interface IState {
  show: boolean;
}
