import React from "react";
import { Image } from "semantic-ui-react";
import NoImage from "../../../assets/images/no_image.svg";

export class ShopMapComponent extends React.Component<IProps> {
  render() {
    return (
      <Image src={this.props.mapImageUrl || NoImage}/>
    );
  }
}

interface IProps {
  mapImageUrl?: string
}
