import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

export default class OptionHeader extends React.Component<Props, {}> {
  render() {
    return (
      <Header>
        {this.props.name}
        <div style={{ padding: "0 18px 0 18px" }}>
          <Icon name="dropdown" />
        </div>
        <div style={{ flexGrow: 1 }}/>
          {this.props.isRequired ? <Label>必須</Label> : <div style={{ padding: "4px 10px", color: "transparent" }}>必須</div>}
      </Header>
    );
  }
}

interface Props {
  isRequired: boolean;
  name: string;
  isSoldOut: boolean;
}

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  width: 100%;
  background-color: #f1f1f1;
`;

const Label = styled.div`
  padding: 4px 10px;
  color: white;
  background-color: orangered;
`;
