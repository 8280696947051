import { ApiService } from "../api/ApiService";
import { select } from "@redux-saga/core/effects";
import { FirebaseService } from "../firebase/FirebaseService";
import State from "../../store/state";

const getCurrentShop = (state: State) => state.shop.activeShop;

export default class ShopRepository {
  constructor(private apiClient: ApiService, private firebaseClient: FirebaseService) { }

  *selectCurrentShopId() {
    const currentShop = yield select(getCurrentShop);
    return currentShop.shop_id;
  }

  *selectCurrentShop() {
    return yield select(getCurrentShop);
  }
}
