import { connect } from "react-redux";
import { withRouter } from "react-router";
import State from "../../../store/state";
import { MailSignInComponent } from "./component";
import { dispatchers } from "./action";
import { updateModalAction } from "../../../store/app/actions";
import { Modal } from "../../../store/app/state";

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signInWithEmail: (email:string, password: string) => {
      dispatch(dispatchers.handleOnClickSignIn(email, password));
    },
    onClickBackButton: () => {
      dispatch(updateModalAction(Modal.SignIn));
    }
  };
};

export const MailSignIn = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MailSignInComponent)
);

export default MailSignIn;
