import React from "react";
import styled, { css } from "styled-components";

export default class MyAccountForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isFocus: false
    };
  }
  render() {
    return (
      <Wrapper>
        <Title>{this.props.title}</Title>
        {this.props.isSignIn && (
          <Data>
            <StyledInput
              onChange={this.props.onChange}
              onFocus={() => this.setState({ isFocus: true })}
              onBlur={() => this.setState({ isFocus: false })}
              width={this.props.width ? this.props.width : "300px"}
              placeholder={this.props.placeholder ? this.props.placeholder : ""}
              value={this.props.value}
              title={this.props.title}
              isFocus={this.state.isFocus}
            />
          </Data>
        )}
      </Wrapper>
    );
  }
}

interface IProps {
  title: string;
  width?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: any) => void;
  placeholder?: string;
  isSignIn: boolean;
}
interface IState {
  isFocus: boolean;
}

const Wrapper = styled.div`
  display: flex;
  padding: 16px 0px 16px 12px;
  border-bottom: 1px solid #f1f1f1;
  text-align: left;
  width: 100%;
`;

const Title = styled.div`
  width: 40%;
`;
const Data = styled.div`
  width: 60%;
  overflow: scroll;
`;

const StyledInput = styled.input`
  text-align: left;
  background: #fff;
  border: none;
  box-shadow: none;
  font-size: 16px;
  border-radius: 0;
  outline: none
    ${(props: { isFocus: boolean }) =>
      props.isFocus &&
      css`
        border-bottom: 2px solid #419eff;
      `};
`;
