import React from "react";
import styled from "styled-components";
import Input from "../atoms/input";
import PrimaryButton from "../atoms/primary_button";
import validator from "validator";

export default class MailLoginForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailError: undefined,
      passwordError: undefined
    };
    this.onChange = this.onChange.bind(this);
    this.onFocusout = this.onFocusout.bind(this);
  }
  render() {
    return (
      <div>
        <Wrapper>
          <Input
            name="email"
            placeholder="メールアドレス"
            onChange={this.onChange}
            onFocusout={this.onFocusout}
            value={this.state.email}
            message={this.state.emailError}
          />
        </Wrapper>
        <Wrapper>
          <Input
            name="password"
            placeholder="パスワード"
            onChange={this.onChange}
            onFocusout={this.onFocusout}
            value={this.state.password}
            isPassword={true}
            message={this.state.passwordError}
          />
        </Wrapper>
        <SmallText>パスワードは6文字以上です</SmallText>
        <PrimaryButton
          width="200px"
          style={{ margin: "40px auto 0" }}
          onClick={() => {
            if (this.state.email.length === 0 || this.state.password.length === 0) {
              return;
            }
            if (!this.state.emailError && !this.state.passwordError) {
              this.props.onLoginButtonClick(
                this.state.email.trim(),
                this.state.password
              )
            }
          }}
        >
          ログイン
        </PrimaryButton>
      </div>
    );
  }
  private onChange (event: React.ChangeEvent<HTMLInputElement>) {
    const name: string = event.target.name
    const value: string = event.target.value
    switch(name) {
      case "email":
        this.setState({ email: value, emailError: undefined });
        break;
      case "password":
        this.setState({ password: value, passwordError: undefined });
        break;
      default:
        break;
    }
  }
  private onFocusout (event: any) {
    const name: string = event.target.name
    const value: string = event.target.value
    switch( name ) {
      case "email":
        if (value.length <= 0) this.setState({emailError: "メールアドレスを入力してください"});
        else if (!validator.isEmail(value)) this.setState({emailError: "正しいメールアドレスを入力してください"});
        break;
      case "password":
        if (value.length <= 0) this.setState({passwordError: "パスワードを入力してください"});
        else if (value.length < 6) this.setState({passwordError: "パスワードは6文字以上で入力してください"});
        break;
      default:
        break;
    }
  }
}

interface IProps {
  width?: string;
  onLoginButtonClick: (email: string, password: string) => void;
}
interface IState {
  email: string;
  password: string;
  emailError: string | undefined;
  passwordError: string | undefined;
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 14px;
`;

const SmallText = styled.div`
  font-size: 14px;
  color: #777777;
  margin-bottom: 20px;
  text-align: center;
`;
