import React from "react";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import mapIcon from "../../../assets/images/map_icon.svg";

export default class ShopMap extends React.Component<IProps> {
  render() {
    return (
      <Wrapper onClick={this.props.onClick}>
        <Image
          src={mapIcon}
          style={{ width: "20px", height: "20px", margin: "0px 16px 0px 0px" }}
        />
        <ShpoName>{this.props.shop_name}</ShpoName>
      </Wrapper>
    );
  }
}

interface IProps {
  shop_name?: string;
  onClick: () => void;
}

const Image = styled(SemanticImage)`
  position: relative;
  width: 400px;
  height: 200px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const ShpoName = styled.span`
  text-decoration: underline;
`;
