import React from "react";
import { ConfirmOrderInfo } from "../../organisms/confirm_order_info/container";
import { ConfirmOrderCard } from "../../organisms/confirm_order_card/container";
import { ConfirmOrderMenus } from "../../organisms/confirm_order_menus/conatiner";
import ConfirmOrderButton from "../../organisms/confirm_order_button/container";
import { getBrowserDocumentHiddenProp, getBrowserVisibilityProp } from "../../../util/window";
import ConfirmOrderRecommendEmailVerify from "../../organisms/confirm_order_recommend_verify_email/container";
import ConfirmOrderAddress from "../../organisms/confirm_order_address/container";
import { OrderType } from "../../../infra/domain/entities";
import { PostcodeApi } from "../../../infra/api/PostcodeApi";
import { Message } from "semantic-ui-react";
import { PostOrderArguments } from "../../../infra/api/ApiService";
import { PaymentMethod } from "../../../store/shop/state";
import ConfirmOrderSelectPaymentMethod from "../../organisms/confirm_order_select_payment_method/container";
import { StpCard } from "../../../infra/domain/StpCard";

const validatePhoneNumber = (phoneNumber: string) : boolean => {
  const pattern = new RegExp(/^[0-9]+$/);
  return !!phoneNumber.match(pattern);
};

export class ConfirmOrderComponent extends React.Component<IProps, IState> {

  visibilityProp = getBrowserVisibilityProp();
  hidden = getBrowserDocumentHiddenProp();

  componentDidMount() {
    document.addEventListener(this.visibilityProp, this.handleVisibilityChange, false);
    if (this.props.enableStripe) {
      this.props.fetchCard();
    }
  }

  componentWillUnmount() {
    document.removeEventListener(this.visibilityProp, this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    try {
      // @ts-ignore
      if (document[this.hidden]) {
      } else {
        this.props.updateCart();
        this.setState({
          visitTime: undefined
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  constructor(props: IProps) {
    super(props);

    // deliveryが有効であれば優先する
    const defaultOrderType = props.enableDelivery ? OrderType.DELIVERY  : OrderType.TAKEOUT;
    const defaultPaymentMethod = props.enableStripe ? PaymentMethod.CARD  : PaymentMethod.CASH;

    this.state = {
      visitTime: undefined,
      visitDate: undefined,
      orderType: defaultOrderType,
      paymentMethod: defaultPaymentMethod,
      autoComplementedAddress: "",
      address: "",
      zipCode: "",
      phoneNumber: "",
    };

    this.onChangeVisitTime = this.onChangeVisitTime.bind(this);
    this.onChangeVisitDate = this.onChangeVisitDate.bind(this);
    this.onChangeOrderType = this.onChangeOrderType.bind(this);
    this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeZipCode = this.onChangeZipCode.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeNickName = this.onChangeNickName.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.onClickOrderButton = this.onClickOrderButton.bind(this);
    this.queryAddress = this.queryAddress.bind(this);
  }

  render() {
    return (
      <div>
        <ConfirmOrderInfo
          visitTime={this.state.visitTime}
          visitDate={this.state.visitDate}
          onChangeVisitTime={this.onChangeVisitTime}
          onChangeVisitDate={this.onChangeVisitDate}
          orderType={this.state.orderType}
          onChangeOrderType={this.onChangeOrderType}
          enableTakeout={this.props.enableTakeout}
          enableDelivery={this.props.enableDelivery}
        />

        <ConfirmOrderAddress
          onChangeAddress={this.onChangeAddress}
          onChangeZipCode={this.onChangeZipCode}
          onChangePhoneNumber={this.onChangePhoneNumber}
          onChangeNickName={this.props.requireNickName ? this.onChangeNickName : undefined}
          queryAddress={this.queryAddress}
          autoComplementedAddress={this.state.autoComplementedAddress}
          addressErrorMessage={this.state.addressErrorMessage}
          orderType={this.state.orderType}
        />

        {this.props.enableOnSitePayment && this.props.enableStripe &&
          <ConfirmOrderSelectPaymentMethod
            orderType={this.state.orderType}
            paymentMethod={this.state.paymentMethod}
            enableStripe={this.props.enableStripe}
            enableOnSitePayment={this.props.enableOnSitePayment}
            onChangePaymentMethod={this.onChangePaymentMethod}
          />
        }

        {this.state.paymentMethod === PaymentMethod.CARD && <ConfirmOrderCard /> }

        <ConfirmOrderMenus order_type={this.state.orderType} />

        {this.state.orderErrorMessage &&
          <Message negative style={{ marginLeft: "15px", marginRight: "15px" }}>
            <Message.Header>{this.state.orderErrorMessage}</Message.Header>
          </Message>
        }

        <ConfirmOrderButton
          disabled={!this.state.visitTime ||
            (this.state.paymentMethod === PaymentMethod.CARD && this.props.cards.length === 0)}
          onConfirm={() => this.onClickOrderButton() }/>

        <ConfirmOrderRecommendEmailVerify />

      </div>
    );
  }

  private onChangeVisitTime(visitTime: string) {
    this.setState({ visitTime });
  }

  private onChangeVisitDate(visitDate: string) {
    this.setState({ visitTime: undefined });
    this.setState({ visitDate });
  }

  private onChangeOrderType(orderType: OrderType) {
    this.setState({ orderType, visitTime: undefined, visitDate: undefined });
  }

  private onChangePaymentMethod(paymentMethod: PaymentMethod) {
    this.setState({ paymentMethod });
  }

  private onChangeAddress(address: string) {
    this.setState({ address });
  }

  private onChangeZipCode(zipCode: string) {
    this.setState({ zipCode });
  }

  private onChangePhoneNumber(phoneNumber: string) {
    this.setState({ phoneNumber });
  }

  private onChangeNickName(nickName: string) {
    this.setState({ nickName });
  }

  private queryAddress() {

    const pattern = new RegExp("^[0-9]{7}$");
    const regExpResult = this.state.zipCode.match(pattern);
    if (!regExpResult) {
      this.setState({ addressErrorMessage: "郵便番号の形式が不正です" });
      return;
    }

    if (this.props.deliveryScopeZipCodes) {
      if (!this.props.deliveryScopeZipCodes.includes(this.state.zipCode)) {
        this.setState({ addressErrorMessage: "配送対象範囲外です" });
        return;
      }
    }

    if (this.state.zipCode) {
      PostcodeApi.queryAddressFromZipCode(this.state.zipCode).then(ret => {
        if (ret) {
          this.setState({
            autoComplementedAddress: `${ret.pref}${ret.city}${ret.town}`,
            addressErrorMessage: undefined});
        } else {
          this.setState({ addressErrorMessage: "住所が存在しません" });
        }
      });
    }
  }

  private onClickOrderButton() {
    if (!this.state.visitTime) {
      this.setState({ orderErrorMessage: "受け取り予定時刻を選択してください" });
      return;
    }

    if (this.state.paymentMethod === PaymentMethod.CARD && this.props.cards.length === 0) {
      this.setState({ orderErrorMessage: "クレジットカードをご登録ください" });
      return;
    }

    if (!this.state.visitDate) {
      this.setState({ orderErrorMessage: "受け取り予定の日付を選択してください" });
      return;
    }

    if (this.state.orderType === OrderType.TAKEOUT) {
      /**
       * NOTE: テイクアウトは電話番号だけとる
       */
      if (!validatePhoneNumber(this.state.phoneNumber)) {
        this.setState({ orderErrorMessage: "電話番号を正しく記入してください" });
        return;
      }

      if (this.props.requireNickName && (!this.state.nickName || this.state.nickName === "")) {
        this.setState({ orderErrorMessage: "受け取り用のお名前を記入してください" });
        return;
      }

      this.props.postOrder(
        {
          visitTime: this.state.visitTime,
          visitDate: this.state.visitDate,
          orderType: this.state.orderType,
          paymentMethod: this.state.paymentMethod,
          phoneNumber: this.state.phoneNumber,
          nickName: this.state.nickName
        }
      );
    } else {

      if (this.state.zipCode ===  ""  || this.state.autoComplementedAddress === "") {
        this.setState({ orderErrorMessage: "郵便番号を記入してください" });
        return;
      }

      if (this.state.address === "") {
        this.setState({ orderErrorMessage: "番地以下の住所を記入してください" });
        return;
      }

      if (!validatePhoneNumber(this.state.phoneNumber)) {
        this.setState({ orderErrorMessage: "電話番号を記入してください(ハイフンなし)" });
        return;
      }

      if (this.props.requireNickName && (!this.state.nickName || this.state.nickName === "")) {
        this.setState({ orderErrorMessage: "受け取り用のお名前を記入してください" });
        return;
      }

      const addressInfo = {
        address1: this.state.autoComplementedAddress,
        address2: this.state.address,
        zipCode: this.state.zipCode,
      };

      this.props.postOrder(
        {
          visitTime: this.state.visitTime,
          visitDate: this.state.visitDate,
          orderType: this.state.orderType,
          addressInfo,
          paymentMethod: this.state.paymentMethod,
          phoneNumber: this.state.phoneNumber,
          nickName: this.state.nickName
        }
      );
    }
  }
}

interface IProps {
  fetchCard: () => void;
  updateCart: () => void;
  postOrder: (args: PostOrderArguments) => void;
  deliveryScopeZipCodes?: string[];
  enableTakeout: boolean;
  enableDelivery: boolean;
  enableStripe: boolean;
  enableOnSitePayment: boolean;
  requireNickName: boolean;
  cards: StpCard[];
}

interface IState {
  visitTime?: string;
  visitDate?: string;
  orderType: OrderType;
  paymentMethod: PaymentMethod;
  autoComplementedAddress: string;
  address: string;
  zipCode: string;
  phoneNumber: string;
  addressErrorMessage?: string;
  orderErrorMessage?: string;
  nickName?: string;
}
