import styled from "styled-components";
import { Height } from "../../config/Height";
import { Image } from "semantic-ui-react";
import React from "react";
import LeftArrowIcon from "../../../assets/images/leftArrowIcon.svg";
import Close from "../../../assets/images/close.svg";

export enum ActionImageType {
  back,
  close
}

const ModalHeader = (props: IProps) => (
  <Header>
    <div
      onClick={props.onActionClick}
      style={{
        position: "absolute",
        height: Height.BottomModalHeader,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "10px"
      }}
    >
      {props.actionImageSrcType === ActionImageType.back ? (
        <Image src={LeftArrowIcon} />
      ) : props.actionImageSrcType === ActionImageType.close ? (
        <Image src={Close} />
      ) : (
        <div />
      )}
    </div>
    {props.text}
  </Header>
);

interface IProps {
  actionImageSrcType: ActionImageType;
  onActionClick?: () => void;
  text: string;
}

const Header = styled.div`
  position: relative;
  top: 0;
  height: ${Height.BottomModalHeader};
  line-height: ${Height.BottomModalHeader};
  text-align: center;
  color: #333333;
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
`;

export default ModalHeader;
