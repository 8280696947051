import State from "../../../store/state";
import { connect } from "react-redux";
import { OrderDetailMapComponent } from "./component";

const mapStateToProps = (state: State) => {
  return {
    mapUrl: state.shop.activeShop.map_image_url
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const OrderDetailMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailMapComponent);

export default OrderDetailMap;
