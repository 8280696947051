import { ShopActionTypes, UPDATE_ACTIVE_VISIT_TIMES, UPDATE_SHOP } from "./types";
import { shopInitialState } from "./state";

export default (state = shopInitialState, action: ShopActionTypes) => {
  switch (action.type) {
    case UPDATE_SHOP: {
      return Object.assign({}, state, { activeShop: action.shop });
    }
    case UPDATE_ACTIVE_VISIT_TIMES: {
      return Object.assign({}, state, { activeVisitTimes: action.activeVisitTimes });
    }
    default:
      return state;
  }
};
