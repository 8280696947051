
export const actions = {
  onClickSendEmailVerification: "CONFIRM_ORDER_RECOMMEND_VERIFY_EMAIL_SEND_EMAIL_VERIFICATION",
};

export const dispatchers = {
  handleOnClickSignUp: ()  => ({ type: actions.onClickSendEmailVerification })
};

export default actions;
