import { Modal, PopUpModal } from "./state";
import CustomError from "../../infra/errors";
import { Menu } from "../../infra/domain/entities";

export const UPDATE_IS_SHOWING_DRAWER = "UPDATE_IS_SHOWING_DRAWER";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const ADD_CURRENT_COMMUNICATION = "ADD_CURRENT_COMMUNICATION";
export const DELETE_CURRENT_COMMUNICATION = "DELETE_CURRENT_COMMUNICATION";
export const UPDATE_IS_LINE_LOGIN_PROCESSING = "UPDATE_IS_LINE_LOGIN_PROCESSING";
export const RESET_COMMUNICATION = "RESET_COMMUNICATION";
export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";
export const UPDATE_ACTIVE_SHOP = "UPDATE_ACTIVE_SHOP";
export const UPDATE_ACTIVE_MENU_ITEM = "UPDATE_ACTIVE_MENU_ITEM";
export const UPDATE_ACTIVE_FREE_PLAN_ITEM = "UPDATE_ACTIVE_FREEPLAN_ITEM";
export const UPDATE_CURRENT_ERROR = "UPDATE_CURRENT_ERROR";
export const DELETE_CURRENT_ERROR = "DELETE_CURRENT_ERROR";
export const UPDATE_POP_UP_MODAL = "UPDATE_POP_UP_MODAL";
export const UPDATE_CART_PRICE = "UPDATE_CART_PRICE";
export const UPDATE_ACTIVE_ORDER_ID = "UPDATE_ACTIVE_ORDER_ID";

interface UpdateIsLoadingAction {
  type: typeof UPDATE_IS_LOADING;
  isLoading: boolean;
}

interface UpdateCartPriceAction {
  type: typeof UPDATE_CART_PRICE;
  priceAll: number;
  taxPrice: number;
}

interface UpdateModalAction {
  type: typeof UPDATE_MODAL;
  modal: Modal;
}

interface AddCurrentCommunicationAction {
  type: typeof ADD_CURRENT_COMMUNICATION;
  communicationId: string;
}

interface DeleteCurrentCommunicationAction {
  type: typeof DELETE_CURRENT_COMMUNICATION;
  communicationId: string;
}

interface ResetCommunicationAction {
  type: typeof RESET_COMMUNICATION;
}

interface UpdateActiveMenuItemAction {
  type: typeof UPDATE_ACTIVE_MENU_ITEM;
  menu: Menu;
}

interface UpdateActiveShop {
  type: typeof UPDATE_ACTIVE_SHOP;
  shopId: string;
}

interface UpdateActiveFreePlanIdAction {
  type: typeof UPDATE_ACTIVE_FREE_PLAN_ITEM;
  planId: string;
}

interface UpdateCurrentErrorAction {
  type: typeof UPDATE_CURRENT_ERROR;
  currentError: CustomError;
}

interface DeleteCurrentErrorAction {
  type: typeof DELETE_CURRENT_ERROR;
}

interface UpdatePopUpModalAction {
  type: typeof UPDATE_POP_UP_MODAL;
  popUp: PopUpModal;
}

interface UpdateIsShowingDrawerAction {
  type: typeof UPDATE_IS_SHOWING_DRAWER;
  isShowing: boolean;
}

interface UpdateActiveOrderIdAction {
  type: typeof UPDATE_ACTIVE_ORDER_ID;
  orderId: string;
}

export type AppActionTypes =
  | UpdateIsShowingDrawerAction
  | UpdateModalAction
  | AddCurrentCommunicationAction
  | DeleteCurrentCommunicationAction
  | ResetCommunicationAction
  | UpdateActiveShop
  | UpdateActiveMenuItemAction
  | UpdateActiveFreePlanIdAction
  | UpdateCurrentErrorAction
  | DeleteCurrentErrorAction
  | UpdateIsLoadingAction
  | UpdatePopUpModalAction
  | UpdateActiveOrderIdAction
  | UpdateCartPriceAction;
