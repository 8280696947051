import { call, put, take } from "redux-saga/effects";
import { actions } from "./action";
import { updatePopUpAction } from "../../../store/app/actions";
import { push } from "connected-react-router";
import { resetUserAction } from "../../../store/user/actions";
import { PopUpModal } from "../../../store/app/state";
import Routes from "../../routes";
import UserRepository from "../../../infra/repository/UserRepository";
import { deleteCommunicationId, handleError, putCommunicationId, } from "../../../store/sagas";
import uuidv4 from "uuid";

function handleSignOut(
  userRepository: UserRepository
) {
  return function* () {
    while (true) {
      yield take(actions.onClickSignOutAction);
      const communication = uuidv4();
      try {
        yield putCommunicationId(communication);
        yield call(userRepository.signOut);
        yield put(resetUserAction());
        yield put(updatePopUpAction(PopUpModal.SignOutComplete));
        yield put(push(Routes.default));
      } catch (e) {
        yield handleError(e);
      } finally {
        yield deleteCommunicationId(communication);
      }
    }
  };
}

function createSagas(
  userRepository: UserRepository
) {
  return [
    handleSignOut(userRepository),
  ];
}

export default createSagas;
