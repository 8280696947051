import { connect } from "react-redux";
import State from "../../../store/state";
import { CartButtonComponent } from "./component";
import { Modal } from "../../../store/app/state";
import { updateModalAction } from "../../../store/app/actions";

const mapStateToProps = (state: State) => {
  return {
    isCartIn: state.entity.cart.order_menus.length > 0,
    cartCount: state.entity.cart.order_menus.map((cartItem) => cartItem.quantity).reduce((sum, current) => sum + current, 0),
    modal: state.app.modal
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showOrderConfirmModal: () => dispatch(updateModalAction(Modal.CartConfirm))
  };
};

export const CartButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartButtonComponent);

export default CartButton;
