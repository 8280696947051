import React from "react";
import styled from "styled-components";
import { OrderType } from "../../../infra/domain/entities";
import SecondaryHeader from "../../components/atoms/secondary_header";
import { PaymentMethod } from "../../../store/shop/state";
import { Button, ButtonGroup } from "semantic-ui-react";

export default class ConfirmOrderSelectPaymentMethodComponent extends React.Component<IProps> {
  render() {
    return (
      <Wrapper>
        <SecondaryHeader>お支払方法</SecondaryHeader>

          <ButtonGroupWrap>
            <ButtonGroup style={{ width: "80%" }}>
              <Button
                inverted
                color="black"
                onClick={() => this.props.onChangePaymentMethod(PaymentMethod.CARD)}
                style={this.props.paymentMethod === PaymentMethod.CARD ? activeButtonStyle : inactiveButtonStyle}>
                ネット決済
              </Button>
              <Button
                inverted
                color="black"
                onClick={() => this.props.onChangePaymentMethod(PaymentMethod.CASH)}
                style={this.props.paymentMethod === PaymentMethod.CASH ? activeButtonStyle : inactiveButtonStyle}>
                現地決済
              </Button>
            </ButtonGroup>
          </ButtonGroupWrap>

          {/* TODO: */}
          {/*{this.props.orderType === OrderType.TAKEOUT ?*/}
          {/*  <Description>現地で現金でお支払いください</Description> :*/}
          {/*  <Description>配達員に現金でお支払いください</Description>*/}
          {/*}*/}

      </Wrapper>
    );
  }
}

interface IProps {
  orderType: OrderType;
  paymentMethod: PaymentMethod;
  enableStripe: boolean;
  enableOnSitePayment:boolean;
  onChangePaymentMethod: (paymentMethod: PaymentMethod) => void;
}

const Wrapper = styled.div`
  width: 100%;
`;

// const Description = styled.div`
//   padding-left:32px
//   margin-left:27px
// `;

const ButtonGroupWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const activeButtonStyle = {
  width:"50%",
  background: "#f47a55",
  color: "#ffffff"
};

const inactiveButtonStyle = {
  width:"50%",
  background: undefined,
  color: "#bbbbbb"
};
