import { connect } from "react-redux";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { AccountUserInfoComponent } from "./component";

const mapStateToProps = (state:State) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveButtonClick: (name: string) => {
      dispatch(dispatchers.handleClickSaveButton(name))
    }
  };
};

export const AccountUserInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountUserInfoComponent);

export default AccountUserInfo;
