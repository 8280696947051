import React from "react";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import CardCVC from "../../../assets/images/card_cvc.jpg";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  ReactStripeElements
} from "react-stripe-elements";
import PrimaryButton from "../atoms/primary_button";

const uuidv4 = require("uuid/v4");

class CardInfo extends React.Component<
  IProps & ReactStripeElements.InjectedStripeProps,
  IState
> {
  constructor(props: IProps & ReactStripeElements.InjectedStripeProps) {
    super(props);
    this.state = {
      completeCardNumber: false,
      completeCardExp: false,
      completeCardCvc: false,
      isStripeLoading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCardNumber = this.handleCardNumber.bind(this);
    this.handleCardExp = this.handleCardExp.bind(this);
    this.handleCardCvc = this.handleCardCvc.bind(this);
  }

  render() {
    return (
      <div>
        <div onSubmit={this.handleSubmit}>
          <ItemName>カード番号</ItemName>
          <InputContainer>
            <CardNumberElement
              style={{ base: inputStyle }}
              onChange={this.handleCardNumber}
            />
          </InputContainer>
          <ItemName>有効期限</ItemName>
          <InputContainer>
            <CardExpiryElement
              id="stp-card-exp"
              style={{ base: inputStyle }}
              onChange={this.handleCardExp}
            />
          </InputContainer>
          <ItemName>セキュリティコード</ItemName>
          <CVCsection>
            <InputCvcContainer>
              <CardCVCElement
                id="stp-card-cvc"
                style={{ base: inputStyle }}
                onChange={this.handleCardCvc}
              />
            </InputCvcContainer>
            <Image
              src={CardCVC}
              margin="0 0 10px 10px"
              style={{ width: "80px", height: "40px" }}
            />
          </CVCsection>
        </div>
        <SmallText>
          デビットカード追加時100円の決済確認が行われる場合があります。決済確認分は10分以内に返金されます。
        </SmallText>
        <PrimaryButton
          color={
            this.state.completeCardCvc &&
            this.state.completeCardExp &&
            this.state.completeCardNumber
              ? "#f46f46"
              : "#c4c4c4"
          }
          width="200px"
          onClick={this.handleSubmit}
          style={{ margin: "15px auto 0" }}
        >
          { this.props.addCardText || "このカードで注文する" }
        </PrimaryButton>
      </div>
    );
  }

  handleCardNumber(e: any) {
    if (e["complete"]) {
      this.setState({ completeCardNumber: true });
      const next = document.getElementById("stp-card-exp");
      next!.getElementsByTagName("input")[0].focus();
    } else {
      this.setState({ completeCardNumber: false });
    }
  }

  handleCardExp(e: any) {
    if (e["complete"]) {
      this.setState({ completeCardExp: true });
      const next = document.getElementById("stp-card-cvc");
      next!.getElementsByTagName("input")[0].focus();
    } else {
      this.setState({ completeCardExp: false });
    }
  }

  handleCardCvc(e: any) {
    if (e["complete"]) {
      this.setState({ completeCardCvc: true });
    } else {
      this.setState({ completeCardCvc: false });
    }
  }

  handleSubmit() {
    if (
      !this.state.completeCardCvc ||
      !this.state.completeCardExp ||
      !this.state.completeCardNumber
    ) {
      return;
    }
    const communicationID = uuidv4();
    this.props.addCommunication(communicationID);
    // ここにバリデーション処理
    this.props
      .stripe!.createToken()
      .then(result => {
        if (result.error || !result.token) {
          this.props.handleCardTokenError(result.error);
          this.props.deleteCommunication(communicationID);
        } else {
          this.props.handleCardToken(result.token.id);
        }
      })
      .catch(error => {
        this.props.deleteCommunication(communicationID);
        throw error;
      });
    return false;
  }
}

interface IProps {
  width: string;
  handleCardToken: (token: string) => void;
  handleCardTokenError: (error: any) => void;
  addCommunication: (id: string) => void;
  deleteCommunication: (id: string) => void;
  addCardText?: string;
}

interface IState {
  completeCardNumber: boolean;
  completeCardExp: boolean;
  completeCardCvc: boolean;
  isStripeLoading: boolean;
}

const inputStyle = {
  color: "#32325d",
  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  fontSmoothing: "antialiased",
  lineHeight: "25px",
  fontSize: "16px",
  "::placeholder": {
    color: "#aab7c4"
  }
};

const InputContainer = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  // width: 275px;
  padding: 8px 13px;
  margin-bottom: 12px;
`;

const InputCvcContainer = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  width: 195px;
  padding: 8px 13px;
  margin-bottom: 12px;
  display: inline-block;
`;

const ItemName = styled.div`
  margin: 0 auto 5px;
`;

const SmallText = styled.div`
  font-size: 12px;
  color: #adadad;
  margin: 0 10px;
`;
const CVCsection = styled.div`
  display: flex;
`;

const Image = styled(SemanticImage)`
  padding: 0 0 10px 20px;
`;

export default injectStripe(CardInfo);
