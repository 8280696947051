import { connect } from "react-redux";
import State from "../../../store/state";
import ConfirmOrderMenusComponent from "./component";
import { Cart } from "../../../infra/domain/entities";

const mapStateToProps = (state: State) => {
  try {
    const cart: Cart = state.entity.cart;
    const shop = state.shop.activeShop;
    return {
      price: cart.price,
      tax_price: cart.tax_price,
      discount_amount: cart.discount_amount,
      price_all: cart.price_all,
      order_menus: cart.order_menus,
      delivery_fee_amount: cart.delivery_fee_amount,
      delivery_price_all: cart.delivery_price_all,
      ex_tax: shop ? shop.ex_tax! : true
    };
  } catch (error) {
    throw error;
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const ConfirmOrderMenus = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderMenusComponent);

export default ConfirmOrderMenus;
