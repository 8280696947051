import { ApiService } from "./api/ApiService";
import ApiClient from "./api/ApiClient";
import URLs from "../config/URL";
import { FirebaseService } from "./firebase/FirebaseService";
import FirebaseClient from "./firebase/FirebaseClient";

const apiClient: ApiService = new ApiClient(URLs.API);
const firebaseClient: FirebaseService = new FirebaseClient();

const injector = {
  apiClient,
  firebaseClient,
};

export default injector;
