import React from "react";
import styled from "styled-components";
import PrimaryButton from "../../components/atoms/primary_button";

export class OrderDetailBackButtonComponent extends React.Component<IProps> {
  render() {
    return (
      <div>
        <Wrapper>
          <PrimaryButton width={"100%"} onClick={this.props.onClick}>
            ホームに戻る
          </PrimaryButton>
        </Wrapper>
      </div>
    );
  }
}

interface IProps {
  onClick: () => void;
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;
