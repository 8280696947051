import React from "react";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import NoImg from "../../../assets/images/no_image.svg";

export default class ShopImage extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper>
        {this.props.is_open || (
          <CloseImage width={this.props.width}>
            <div style={{ margin: "auto" }}>{this.props.close_text}</div>
          </CloseImage>
        )}
        <Image
          src={this.props.image_url || NoImg}
          style={{ width: this.props.width || "100%" }}
        />
      </Wrapper>
    );
  }
}

interface IProps {
  is_open: boolean;
  image_url?: string;
  width?: string;
  close_text: string;
}
interface IState {}

const CloseImage = styled.div`
  width: ${(props: { width?: string }) => props.width || "100%"};
  height: 200px;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  color: white;
  text-align: center;
  font-size: 25px;
  display: flex;
  justify-contet: center;
  align-items: center;
`;

const Image = styled(SemanticImage)`
  position: relative;
  width: 400px;
  height: 200px;
`;

const Wrapper = styled.div`
  position: relative;
`;
