import { connect } from "react-redux";
import State from "../../../store/state";
import ConfirmOrderCardComponent from "./component";

const mapStateToProps = (state: State) => {
  return {
    hasCard: state.user.cards && state.user.cards.length > 0,
    brand: state.user.cards && state.user.cards.length > 0
      ? state.user.cards[0].brand
      : undefined,
    last: state.user.cards && state.user.cards.length > 0
      ? state.user.cards[0].last
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const ConfirmOrderCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderCardComponent);

export default ConfirmOrderCard;
