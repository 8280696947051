import React from "react";
import styled from "styled-components";
import SecondaryHeader from "../../components/atoms/secondary_header";
import OrderMenuItem, { OrderMenuItemOption } from "../../components/molecules/order_menu_item";
import OrderMenuPayment from "../../components/molecules/order_menu_payment";
import { OrderMenu, OrderOption, OrderType } from "../../../infra/domain/entities";

export default class ConfirmOrderInfoComponent extends React.Component<
  IProps,
  IState
> {
  render() {
    return (
      <div>
        <SecondaryHeader>注文内容</SecondaryHeader>
        <Wrapper>
          {this.props.order_menus.map((menu, index) => {
            const options: OrderMenuItemOption[] = this.translateDomainOptionToOrderMenuItemOption(menu.options || []);
            return (
              <OrderMenuItem
                key={index}
                name={menu.name}
                price={menu.price}
                quantity={menu.quantity}
                options={options}
              />
            );
          })}
          <OrderMenuPayment
            price={this.props.price}
            tax_price={this.props.tax_price}
            discount_amount={this.props.discount_amount}
            price_all={this.props.price_all}
            delivery_fee_amount={this.props.delivery_fee_amount}
            delivery_price_all={this.props.delivery_price_all}
            order_type={this.props.order_type}
            ex_tax={this.props.ex_tax}
          />
        </Wrapper>
      </div>
    );
  }

  /**
   *  Functions
   */
  private translateDomainOptionToOrderMenuItemOption(options: OrderOption[]): OrderMenuItemOption[] {
    return options.map(option => {
      return {
        choices: option.choices.map(choice => {
          return {
            name: choice.name,
            price: choice.price,
            options: choice.options ? choice.options.map(subOption => {
              return {
                choices: subOption.choices.map(subChoice => {
                  return {
                    name: subChoice.name
                  };
                })
              };
            }) : undefined
          };
        })
      };
    });
  }
}

interface IProps {
  // From container
  price: number;
  tax_price: number;
  discount_amount?: number;
  delivery_price_all?: number;
  delivery_fee_amount?: number;
  price_all: number;
  order_menus: OrderMenu[];
  order_type: OrderType;
  ex_tax: boolean;
}
interface IState {}

const Wrapper = styled.div`
  width: 100%;
`;
