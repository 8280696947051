import { Dispatch } from "react";
import { updateCurrentErrorAction } from "../store/app/actions";

export enum Errors {
  NOT_FIREBASE_AUTHORIZED = 0
}

export default class CustomError {
  shouldShowMessage = true;
  title = "";
  message = "";
  status: number | undefined;
  errorCode?: number;

  constructor(title: string, message: string, status?: Errors, shouldShowMessage?: boolean, errorCode?: number) {
    this.title = title;
    this.message = message;
    this.status = status;
    this.errorCode = errorCode;
    if (shouldShowMessage !== undefined) {
      this.shouldShowMessage = shouldShowMessage;
    }
  }
}

export function errorActionCreator(error: any) {
  return {};
}

export const dispatchError = async (error: any, dispatch: Dispatch<any>) =>  {
  console.log("handle error: ", error);
  if (error !== null) {
    if (error.shouldShowMessage) {
      dispatch(updateCurrentErrorAction(error));
    } else {
      dispatch(
        updateCurrentErrorAction(
          new CustomError("ページの読み込みに失敗しました", "電波状況をお確かめの上、再度読み込み直してください")
        )
      );
      throw error;
    }
  }
};
