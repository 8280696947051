import React from "react";
import LogoutConfirm from "../../organisms/logout_confirm/container";

export default class LogoutConfirmPageComponent extends React.Component<IProps, IState> {
  render() {
    return (
            <div>
                <LogoutConfirm/>
            </div>
    );
  }
}

interface IProps {}
interface IState {}
