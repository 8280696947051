import { connect } from "react-redux";
import State from "../../../store/state";
import {
  updateIsShowingDrawerAction
} from "../../../store/app/actions";
import { DrawerComponent } from "./component";
import "firebase/auth";
import * as firebase from "firebase/app";

const mapStateToProps = (state: State) => {
  return {
    isSignedIn: firebase.auth().currentUser !== null,
    visible: state.app.isShowingDrawer,
    isActive: firebase.auth().currentUser !== null,
    naviImageUrl: state.entity.company ? state.entity.company.navi_image_url : undefined,
    enableStripe: state.entity.shops.map(shop => shop.enable_stripe).some(v => v)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => dispatch(updateIsShowingDrawerAction(false)),
  };
};

export const Drawer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawerComponent);

export default Drawer;
