import React from "react";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import minusButton from "../../../assets/images/minusButton_gray.svg";
import plusButton from "../../../assets/images/plusButton_gray.svg";

export class CartMenuItem extends React.Component<IProps, {}> {
  render() {
    return (
      <Wrapper>
        <Content>
          <OrderDetailWrapper>
            <OrderDetail>
              <div style={{ flexBasis: "75%" }}>{this.props.cartItem.name}</div>
              <Image
                style={{ flexBasis: "10%", height: "21px" }}
                src={minusButton}
                onClick={(event: any) => this.onItemCut(this.props.cartItem)}
              />
              <div
                style={{
                  flexBasis: "10%",
                  textAlign: "center",
                  lineHeight: "21px",
                  padding: "0 4px"
                }}
              >
                {this.props.cartItem.quantity}
              </div>
              <Image
                style={{ flexBasis: "10%", height: "21px" }}
                src={plusButton}
                onClick={(event: any) => this.onItemAdd(this.props.cartItem)}
              />
              <div>
                <PriceText>¥{this.props.cartItem.price_all}</PriceText>
                <TaxText>{this.props.exTax ? "(税抜)" : "(税込)" }</TaxText>
              </div>
            </OrderDetail>
            {this.props.cartItem.options &&
              this.props.cartItem.options.length > 0 &&
              this.props.cartItem.options.map(option => {
                return option.choices.map((choice, index) => {
                  return (
                    <div key={index}>
                      {choice.quantity > 0 && (
                        <Option>
                          -{choice.name}
                          {choice.options &&
                            choice.options.length > 0 &&
                            choice.options.map((subOption, index) => {
                              return subOption.choices.map(subChoice => {
                                return (
                                  <div key={index}>
                                    {subChoice.quantity > 0 && (
                                      <div>/{subChoice.name}</div>
                                    )}
                                  </div>
                                );
                              });
                            })}
                        </Option>
                      )}
                    </div>
                  );
                });
              })}
          </OrderDetailWrapper>
        </Content>
      </Wrapper>
    );
  }

  private onItemAdd(cartItem: CartMenuItemCartItem) {
    cartItem.quantity += 1;
    this.props.updateCartItem(cartItem);
  }

  private onItemCut(cartItem: CartMenuItemCartItem) {
    cartItem.quantity -= 1;
    this.props.updateCartItem(cartItem);
  }
}
interface IProps {
  cartItem: CartMenuItemCartItem;
  updateCartItem: (cartItem: CartMenuItemCartItem) => void;
  exTax: boolean;
}
export interface CartMenuItemCartItem {
  name: string;
  quantity: number;
  price?: number;
  price_all?: number;
  options?: CartMenuItemOption[];
}
export interface CartMenuItemOption {
  choices: CartMenuItemChoice[];
}
export interface CartMenuItemChoice {
  name: string;
  quantity: number;
  price: number;
  options?: CartMenuItemSubOption[];
}
export interface CartMenuItemSubOption {
  choices: CartMenuItemSubChoice[];
}
export interface CartMenuItemSubChoice {
  name: string;
  quantity: number;
}
const Wrapper = styled.div`
  width: 100%;
  max-height: 70vh;
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
`;
const Content = styled.div`
  width: 100%;
  padding: 0 26px;
`;
const OrderDetail = styled.div`
  font-size: 16px;
  display: flex;
  width: 100%;
  line-height: 20px;
`;
const OrderDetailWrapper = styled.div`
  font-size: 16px;
  margin: 18px 0 11px 0;
`;
const Option = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  margin-top: 5px;
  margin-left: 10px;
  display: flex;
`;

const PriceText = styled.div`
  width: 20%;
  padding: 1px 0px 1px 15px;
  textalign: right;
`;

const TaxText = styled.div`
  font-size: 12px;
  text-align: right;
`
