import { connect } from "react-redux";
import { withRouter } from "react-router";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { MailSignUpSection } from "./component";
import { updateModalAction } from "../../../store/app/actions";
import { Modal } from "../../../store/app/state";

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    mailSignUp: (name: string, email: string, password: string) => {
      dispatch(dispatchers.handleOnClickSignUp(name, email, password));
    },
    onClickBackButton: () => {
      dispatch(updateModalAction(Modal.SignIn));
    }
  };
};

export const MailSignUp = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MailSignUpSection)
);

export default MailSignUp;
