import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import LeftArrowIcon from "../../../assets/images/leftArrowIcon.svg";
import Close from "../../../assets/images/close.svg";
import hamburger from "../../../assets/images/hamburger.svg";
import { ZIndexSettings } from "../../config/ZIndexSettings";
import Routes from "../../routes";
import { Height } from "../../config/Height";
import { Link } from "react-router-dom";
const queryString = require("query-string");

export enum ActionType {
  hamburger,
  back,
  close
}

export class NavigationHeaderComponent extends React.Component<IProps, IState> {
  private getTitle(): string {
    let title = "";
    switch (this.props.history.location.pathname) {
      case Routes.default:
        title = "ダイニー for";
        break;
      case Routes.shops:
        title = this.props.brandName || "";
        break;
      case Routes.history:
        title = "注文一覧";
        break;
      case Routes.menus:
      case Routes.confirmOrder:
        title = this.props.shopName || "";
        break;
      case Routes.account:
        title = "マイアカウント";
        break;
      case Routes.addCard:
        title = "クレジットカード登録";
        break;
      case Routes.aboutMobileOrder:
        title = "モバイルオーダーって?";
        break;
      case Routes.faq:
        title = "よくある質問";
        break;
      case Routes.error:
        title = "エラー";
        break;
      case Routes.logoutConfirm:
        title = "ログアウト";
        break;
      case Routes.orderDetail:
        title = "注文詳細";
        break;
    }
    return title;
  }

  private actionType(): ActionType {
    let ret = ActionType.hamburger;
    switch (this.props.history.location.pathname) {
      case Routes.account:
      case Routes.addCard:
      case Routes.aboutMobileOrder:
      case Routes.faq:
      case Routes.error:
      case Routes.logoutConfirm:
      case Routes.history:
      case Routes.menus:
        ret = ActionType.back;
        break;
      case Routes.orderDetail:
      case Routes.confirmOrder:
        ret = ActionType.close;
        break;
    }
    return ret;
  }

  private backTo() {
    let ret = undefined;
    switch (this.props.history.location.pathname) {
      case Routes.menus:
        ret = Routes.shops;
        break;
    }
    return ret;
  }

  private onClose() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.back_to) {
      this.props.history.go(-1 * parseInt(query.back_to) || -1);
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    const type = this.actionType();
    let specificBackPath: string | undefined = undefined;
    if (type === ActionType.back) {
      specificBackPath = this.backTo();
    }
    return (
      <PrimaryHeaderSection>
        <ImageWrapper>
          {type === ActionType.hamburger
              ? (
                  <Image
                    src={hamburger}
                    key="sidebar_button"
                    onClick={this.props.onClickHamburger}
                  />)
              : type === ActionType.back
                ? (<Image
                    src={LeftArrowIcon}
                    onClick={() => {
                      if (!specificBackPath) {
                        this.props.history.goBack();
                      } else {
                        this.props.history.push(specificBackPath);
                      }
                    }}
                  />)
                : <Image
                    src={Close}
                    onClick={() => {
                      this.onClose();
                    }}
                  />
          }
        </ImageWrapper>
        <PrimaryHeaderTitle>
          <HeaderText>{this.getTitle()}</HeaderText>
        </PrimaryHeaderTitle>
        {type === ActionType.hamburger && this.props.isSignedIn &&
          <HistoryButtonWrapper>
            <Link to={Routes.history} >
              <HistoryButton>注文履歴</HistoryButton>
            </Link>
          </HistoryButtonWrapper>
        }
      </PrimaryHeaderSection>
    );
  }
}

interface IProps extends RouteComponentProps<any> {
  onClickHamburger: () => void;
  isSignedIn: boolean;
  brandName?: string;
  shopName?: string;
}

interface IState {}

const PrimaryHeaderSection = styled.div`
  height: ${Height.NavigationHeader};
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  z-index: ${ZIndexSettings.HEADER};
`;

const Image = styled(SemanticImage)`
  position: relative;
`;

const ImageWrapper = styled.div`
  position: absolute;
  height: 20px;
  top: 22px;
  left: 20px;
`;

const HistoryButtonWrapper = styled.div`
  position: absolute;
  height: 30px;
  top: 15px;
  bottom: 0px;
  right: 20px;
`;

const HistoryButton = styled.div`
  background-color: #a9a9a9;
  padding: 5px 8px;
  border-radius: 5px;
  color: white;
  position: relative;
  top: 0px;
  bottom: 0px;
  font-size: 11px;
`;

const PrimaryHeaderTitle = styled.div`
  color: #333;
  font-size: 16px;
  height: ${Height.NavigationHeader};
  display: flex;
  justify-content: center;
`;

const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  min-width: 60%;
  word-wrap: break-word;
  text-align: center;
`;
