import {
  CART_UPDATE_CART,
  CHOICE_UPDATE_ALL_CHOICE,
  EntityActionTypes,
  MENU_UPDATE_ALL_MENU,
  OPTION_UPDATE_ALL_OPTION,
  ORDERS_UPDATE_ORDERS,
  SHOP_UPDATE_ALL_SHOP, UPDATE_COMPANY_ACTION
} from "./types";
import { entityInitialState, EntityState } from "./state";

const uuidv4 = require("uuid/v4");

export default (
  state: EntityState = entityInitialState,
  action: EntityActionTypes
): EntityState => {
  switch (action.type) {
    case UPDATE_COMPANY_ACTION:
      return Object.assign({}, state, {
        company: action.company
      });
    case SHOP_UPDATE_ALL_SHOP:
      return Object.assign({}, state, {
        shops: action.shops
      });
    case MENU_UPDATE_ALL_MENU:
      return Object.assign({}, state, {
        menus: action.menus
      });
    case OPTION_UPDATE_ALL_OPTION:
      return Object.assign({}, state, {
        options: action.options
      });
    case CHOICE_UPDATE_ALL_CHOICE:
      return Object.assign({}, state, {
        choice: action.choices
      });
    case ORDERS_UPDATE_ORDERS:
      return Object.assign({}, state, {
        orders: action.orders
      });
    case CART_UPDATE_CART: {
      const cart = Object.assign({}, action.cart);
      cart.order_menus = Array.from(cart.order_menus).map(orderMenu =>
        Object.assign({}, orderMenu, { id: uuidv4() })
        );
      return Object.assign({}, state, { cart });
    }
    default:
      return state;
  }
};
