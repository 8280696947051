import { User } from "../../infra/domain/User";
import { StpCard } from "../../infra/domain/StpCard";

export const RESET_USER = "RESET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";
export const UPDATE_CARDS = "UPDATE_CARDS";

interface ResetUserAction {
  type: typeof RESET_USER;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  user: Partial<User>;
}

interface UpdateAccessToken {
  type: typeof UPDATE_ACCESS_TOKEN;
  accessToken: string;
}

interface UpdateCards {
  type: typeof UPDATE_CARDS;
  cards: StpCard[];
}

export type UserActionTypes = ResetUserAction | UpdateUserAction | UpdateCards | UpdateAccessToken;
