import State from "../../../store/state";
import { connect } from "react-redux";
import { OrderDetailBackButtonComponent } from "./component";
import { replace } from "connected-react-router";
import router from "../../routes";

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClick: () => {
      dispatch(replace(router.shops));
    }
  };
};

export const OrderDetailBackButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailBackButtonComponent);

export default OrderDetailBackButton;
