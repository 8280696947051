import React from "react";
import ShopList from "../../organisms/shop_list/container";
import styled from "styled-components";
import { getBrowserDocumentHiddenProp, getBrowserVisibilityProp } from "../../../util/window";

export class ShopsComponent extends React.Component<IProps> {

  visibilityProp = getBrowserVisibilityProp();
  hidden = getBrowserDocumentHiddenProp();

  componentDidMount() {
    document.addEventListener(this.visibilityProp, this.handleVisibilityChange, false);
  }

  componentWillUnmount()    {
    document.removeEventListener(this.visibilityProp, this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    try {
      // @ts-ignore
      if (document[this.hidden]) {
      } else {
        this.props.listShops();
      }
    } catch (e) {
      console.log(e);
    }
  };

  constructor(props: IProps) {
    super(props);
    props.listShops();
  }

  render() {
    return (
        <Wrapper>
          <ShopList/>
        </Wrapper>
    );
  }
}

interface IProps {
  listShops: () => void;
}

const Wrapper = styled.div`
`;
