import React from "react";
import { Form, TextArea } from "semantic-ui-react";

class TextAreaForm extends React.Component<IProps> {
  render() {
    return (
      <Form>
        <TextArea
          onChange={this.props.onChange}
          placeholder="アレルギー等ございましたらご入力ください"
        />
      </Form>
    );
  }
}

// const TextAreaForm = (onChange: () => void) => (
//   <Form>
//     <TextArea onChange="{onChange}" placeholder='アレルギー等ございましたらご入力ください' />
//   </Form>
// )

interface IProps {
  onChange: (e: any) => void;
}

export default TextAreaForm;
