import React from "react";
import styled from "styled-components";
import HeaderShopAbout from "../../organisms/header_shop_about/container";
import CartButton from "../../organisms/cart_button/container";
import MenuItemList from "../../organisms/menu_list/container";
import { getDisplayHeight } from "../../../util/window";

export class MenusComponent extends React.Component<IProps, any> {

  componentDidMount(): void {
    this.props.initializeInformation();
  }

  render() {
    const height = getDisplayHeight() - 60;
    return (
      <Wrapper>
        <ContentsWrapper id="target-list" style={{ height: `${height}px`, overflow:"scroll" }}>
          <HeaderShopAbout />
          <MenuItemList />
          <CartButton />
        </ContentsWrapper>
      </Wrapper>
    );
  }
}

interface IProps {
  shopName: string | undefined;
  initializeInformation: () => void;
}

const Wrapper = styled.div`
  width: 100%;
`;

const ContentsWrapper = styled.div`
  width: 100%;
`;
