import { call, take, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import actions from "./action";
import { handleError } from "../../../store/sagas";
import AppRepository from "../../../infra/repository/AppRepository";
import router from "../../routes";

function updateActiveOrderId(
  appRepository: AppRepository
) {
  return function* () {
    while (true) {
      const action = yield take(actions.updateActiveOrderId);
      try {
        yield call(appRepository.updateActiveOrderId, action.orderId);
        yield put(push(router.orderDetail));
      } catch (error) {
        yield handleError(error);
      }
    }
  };
}

function createSagas(
  appRepository: AppRepository
) {
  return [
    updateActiveOrderId(appRepository)
  ];
}

export default createSagas;
