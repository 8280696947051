import { call, select, take } from "redux-saga/effects";
import actions from "./action";
import { deleteCommunicationId, getShops, handleError, putCommunicationId, } from "../../../store/sagas";
import uuidv4 from "uuid";
import UserRepository from "../../../infra/repository/UserRepository";
import { Shop } from "../../../infra/domain/shop";

function fetchCard(userRepository: UserRepository) {
  return function* () {
    while (true) {
      yield take(actions.fetchCard);
      const communication = uuidv4();
      try {
        yield putCommunicationId(communication);

        const shops: Shop[] = yield select(getShops);

        if (shops.map(shop => shop.enable_stripe).some(v => v)) {
          yield call(userRepository.fetchCardsAndStore);
        }

      } catch (e) {
        yield handleError(e);
      } finally {
        yield deleteCommunicationId(communication);
      }
    }
  };
}

const createSagas = (
  userRepository: UserRepository
) => [
  fetchCard(userRepository),
];

export default createSagas;
