import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootSaga from "./sagas";

import { connectRouter, routerMiddleware } from "connected-react-router";

import { createBrowserHistory } from "history";
import appReducer from "./app/reducer";
import shopReducer from "./shop/reducer";
import userReducer from "./user/reducer";
import entitiesReducer from "./entity/reducer";
import ReactGA from "react-ga";

export const history = createBrowserHistory();

export default function configureStore() {

  if (process.env.REACT_APP_STAGE === "prod") {
    history.listen(({ pathname }) => {
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    });
  }

  const composeEnhancer =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const persistConfig = {
    key: "root",
    storage,
    blacklist: ["router", "app"]
  };

  const appPersistConfig = {
    key: "app",
    storage,
    blacklist: ["currentError", "isLoading"]
  };

  const saga = createSagaMiddleware();
  const store = createStore(
    persistReducer(
      persistConfig,
      combineReducers({
        app: persistReducer(appPersistConfig, appReducer),
        shop: shopReducer,
        user: userReducer,
        entity: entitiesReducer,
        router: connectRouter(history)
      }),
    ),
    composeEnhancer(applyMiddleware(routerMiddleware(history), saga)),
  );
  const persistor = persistStore(store);

  saga.run(rootSaga);

  return { store, persistor };
}
