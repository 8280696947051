import State from "../../../store/state";
import { connect } from "react-redux";
import { ShopListComponent } from "./component";
import { dispatchers } from "./action";
import { Shop } from "../../../infra/domain/shop";

const mapStateToProps = (state: State) => {
  return {
    shops: state.entity.shops,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClickShopItem: (shop: Shop) => dispatch(
      dispatchers.handleClickShopItem(shop)
    )
  };
};

export const ShopList =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ShopListComponent);

export default ShopList;
