import React from "react";
import styled from "styled-components";
import { Image, Modal as SemanticModal, Transition } from "semantic-ui-react";
import WarningImg from "../../../assets/images/warning.svg";
import ModalTitle from "../../components/atoms/modal_title";
import { ZIndexSettings } from "../../config/ZIndexSettings";

export class ErrorModalComponent extends React.Component<IProps, IState> {

  private title = this.props.title;
  private message = this.props.message;
  render() {
    this.title = this.props.title || this.title;
    this.message = this.props.message || this.message;
    return (
      <Transition
        visible={this.props.isShow}
        unmountOnHide={true}
        animation={"scale"}
        duration={500}
      >
        <SemanticModal
          closeIcon={true}
          open={true}
          onClose={this.props.onHide}
          style={{
            overflow: "hidden",
            borderRadius: 20,
            zIndex: ZIndexSettings.MODAL
          }}
        >
          <div>
            <Image
              src={WarningImg}
              style={{ width: "100%", height: "146px", objectFit: "cover" }}
            />
            <TextSection>
              <ModalTitle>{this.title}</ModalTitle>
              <Text>{this.message}</Text>
            </TextSection>
          </div>
        </SemanticModal>
      </Transition>
    );
  }
}
interface IProps {
  title?: string;
  message?: string;
  onHide: () => void;
  isShow: boolean;
}
interface IState {
  shouldKeepShow: boolean;
}

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 18px auto;
`;
const TextSection = styled.div`
  text-align: center;
  margin: 32px auto;
`;
