import { connect } from "react-redux";
import { withRouter } from "react-router";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { CartComponent } from "./component";
import { OrderMenu } from "../../../infra/domain/entities";
import { CartMenuItemCartItem } from "../../components/molecules/cart_menu_item";

const mapStateToProps = (state: State) => {
  return {
    cartItems: state.entity.cart.order_menus,
    exTax: state.shop.activeShop.ex_tax || false
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCartItem: (
      cartItem: OrderMenu,
      cartMenuItem: CartMenuItemCartItem
    ) => {
      cartItem.quantity = cartMenuItem.quantity;
      dispatch(dispatchers.handleUpdateCart(cartItem));
    },
    postOrder: () => {
      dispatch(dispatchers.handlePostOrder());
    }
  };
};

export const Cart = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CartComponent)
);

export default Cart;
