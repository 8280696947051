import { Choice, Company, Menu, Option, Order, Cart } from "../../infra/domain/entities";
import { Shop } from "../../infra/domain/shop";

export const SHOP_UPDATE_ALL_SHOP = "SHOP_UPDATE_ALL_SHOP";
export const MENU_UPDATE_ALL_MENU = "UPDATE_ALL_MENU";
export const OPTION_UPDATE_ALL_OPTION = "UPDATE_ALL_OPTION";
export const CHOICE_UPDATE_ALL_CHOICE = "UPDATE_ALL_CHOICE";
export const ORDERS_UPDATE_ORDERS = "UPDATE_ALL_ORDERS";
export const CART_UPDATE_CART = "UPDATE_CART";
export const UPDATE_COMPANY_ACTION = "UPDATE_COMPANY_ACTION";

interface UpdateCompanyAction {
  type: typeof UPDATE_COMPANY_ACTION;
  company: Company;
}
interface UpdateAllShopsAction {
  type: typeof SHOP_UPDATE_ALL_SHOP;
  shops: Shop[];
}

interface UpdateAllMenusAction {
  type: typeof MENU_UPDATE_ALL_MENU;
  menus: Menu[];
}

interface UpdateAllOptionsAction {
  type: typeof OPTION_UPDATE_ALL_OPTION;
  options: Option[];
}

interface UpdateAllChoicesAction {
  type: typeof CHOICE_UPDATE_ALL_CHOICE;
  choices: Choice[];
}

interface UpdateOrdersAction {
  type: typeof ORDERS_UPDATE_ORDERS;
  orders: Order[];
}

interface UpdateCart {
  type: typeof CART_UPDATE_CART;
  cart: Cart;
}

// tslint:disable-next-line:max-line-length
export type EntityActionTypes = UpdateAllShopsAction | UpdateAllMenusAction | UpdateAllChoicesAction | UpdateOrdersAction | UpdateCart | UpdateAllOptionsAction | UpdateCompanyAction;
