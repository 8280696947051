import { connect } from "react-redux";
import State from "../../../store/state";
import { MenuItemListComponent } from "./component";
import { dispatchers } from "./action";
import { Menu, Category } from "../../../infra/domain/entities";
import { compareValues } from "../../../util/reducer_helper";

const mapStateToProps = (state: State) => {
  return {
    categorizedMenus: getCategorizedMenus(state.entity.menus, state.shop.activeShop.categories),
    exTax: state.shop.activeShop.ex_tax || false
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClickMenuItem: (menu: Menu) => dispatch(
        dispatchers.handleClickMenuItem(menu)
    ),
  };
};

export const MenuItemList =  connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuItemListComponent);

export default MenuItemList;

const getCategorizedMenus = (menus: Menu[], categories: Category[]) => {
  const categorizedMenus: CategorizedMenu[] = [];

  categories.forEach(category => {
    const menusInCategory = menus
        .filter(menu => {
          return menu.category_id === category.category_id;
        }).sort(compareValues("priority", "asc"));
    categorizedMenus.push({
      category_id: category.category_id,
      name: category.name,
      priority: category.priority,
      menus: menusInCategory
    });
  });
  categories.sort(compareValues("priority", "asc"));
  return categorizedMenus;
};

export interface CategorizedMenu {
  category_id: string;
  name: string;
  priority: number;
  menus: Menu[];
}
