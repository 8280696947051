import React from "react";
import styled from "styled-components";

const Input = (props: IProps) => {
  return (
    <div>
      {props.message && <Text>{props.message}</Text>}
      <StyledInput
        onChange={props.onChange}
        onBlur={props.onFocusout}
        width={props.width ? props.width : "300px"}
        placeholder={props.placeholder ? props.placeholder : ""}
        value={props.value}
        type={props.isPassword ? "password" : "text"}
        name={props.name}
        message={props.message}
      />
    </div>
  );
};

interface IProps {
  name?: string;
  width?: string;
  height?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocusout?: (event: any) => void;
  placeholder?: string;
  isPassword?: boolean;
  message?: string;
}

export default Input;
const StyledInput = styled.input`
  width: ${(props: IProps) => (props.width ? props.width : "300px")};
  height: ${(props: IProps) => (props.height ? props.height : "60px")};
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid
    ${(props: IProps) => (props.message ? "#F2C94C" : "rgba(34, 36, 38, 0.15)")};
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: none;
  font-size: 16px;
  transform: scale(0.8);
`;

const Text = styled.div`
  color: #f2c94c;
  font-size: 12px;
  text-align: center;
`;
