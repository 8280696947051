export const actions = {
  listShops: "SHOPS_LIST_SHOPS"
};

export const dispatchers = {
  listShops: () => ({
    type: actions.listShops,
  })
};

export default actions;
