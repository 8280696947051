import { put, take } from "redux-saga/effects";
import { actions } from "./action";
import { PopUpModal } from "../../../store/app/state";
import { handleError } from "../../../store/sagas";
import uuid from "uuid";
import { call } from "@redux-saga/core/effects";
import {
  addCurrentCommunicationAction,
  deleteCurrentCommunicationAction,
  updatePopUpAction
} from "../../../store/app/actions";
import UserRepository from "../../../infra/repository/UserRepository";

function handleUpdateUser(
    userRepository: UserRepository
) {
  return function* () {
    while (true) {
      const communication = uuid();
      const action = yield take(actions.updateUserName);
      try {
        yield put(addCurrentCommunicationAction(communication));
        yield call(userRepository.updateUser, action.name);
        yield put(updatePopUpAction(PopUpModal.UpdateUserComplete));
      } catch (e) {
        yield handleError(e);
      } finally {
        yield put(deleteCurrentCommunicationAction(communication));
      }
    }
  };
}

function createSagas(
    userRepository: UserRepository
) {
  return [
    handleUpdateUser(userRepository),
  ];
}

export default createSagas;
