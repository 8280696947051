import {
  Choice, Company,
  Menu,
  Cart,
  Option,
  Order,
} from "../../infra/domain/entities";
import { Shop } from "../../infra/domain/shop";

export interface EntityState {
  company?: Company;
  shops: Shop[];
  menus: Menu[];
  options: Option[];
  choices: Choice[];
  orders: Order[];
  cart: Cart;
  orderedItems: Order[];
}

export const entityInitialState: EntityState = {
  shops: [],
  menus: [],
  options: [],
  choices: [],
  orders: [],
  cart: {
    shop_id: undefined,
    cart_id: undefined,
    price_all: 0,
    price: 0,
    tax_price: 0,
    order_menus: [],
  },
  orderedItems: []
};
