import React from "react";
import { RouteComponentProps } from "react-router";
import { AppState } from "../../../store/app/state";
import { UserState } from "../../../store/user/state";
import NoImg from "../../../assets/images/no_image.svg";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import ShopDate from "../../components/atoms/shop_date";
import PrimaryButton from "../../components/atoms/primary_button";
import mapIcon from "../../../assets/images/map_icon.svg";

export class HeaderShopAboutComponent extends React.Component<IProps, any> {
  render() {
    return (
      <Wrapper>
        <Image src={this.props.shopImage || NoImg} />
        <ShopInfoWrapper>
          {this.props.name && this.props.open && this.props.close && (
            <ShopInfoText>
              <ShopNameText>{this.props.name}</ShopNameText>
              <div style={{ paddingTop: "6px" }}>
                <ShopDate
                  open={this.props.open}
                  close={this.props.close}
                  color={"#333333"}
                />
              </div>
            </ShopInfoText>
          )}
          <ButtonLayout onClick={() => this.props.mapUrl && window.open(this.props.mapUrl)}>
            <PrimaryButton width={"100%"} color={"#f1F1F1"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <div>
                  <Image src={mapIcon} />
                </div>
                <div
                  style={{
                    color: "black",
                    fontSize: "12px",
                    lineHeight: "20px",
                    paddingLeft: "5px"
                  }}
                >
                  店舗の場所を確認
                </div>
              </div>
            </PrimaryButton>
          </ButtonLayout>
        </ShopInfoWrapper>
        <ShopSubInfoWrapper>
          {this.props.enableDelivery &&
            <DeliveryFee>配送手数料￥{this.props.deliveryFee ? this.props.deliveryFee.toLocaleString() : 0}</DeliveryFee>
          }
        </ShopSubInfoWrapper>
      </Wrapper>
    );
  }
}

interface IProps extends RouteComponentProps<any> {
  app: AppState;
  user: UserState;
  name?: string;
  open?: string;
  close?: string;
  shopImage?: string;
  mapUrl?: string;
  enableDelivery: boolean;
  deliveryFee?: number;
}

const Wrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
`;

const ShopInfoWrapper = styled.div`
  width: 100%;
  padding: 13px 14px 8px 20px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
`;

const ShopSubInfoWrapper = styled.div`
  width: 100%;
  padding: 8px 14px 8px 20px;
  display: flex;
  display: -webkit-flex;
  border-bottom: 1px solid #f1f1f1;
  align-items: left;
  font-size: 10px;
`;

const ShopInfoText = styled.div`
  flex-basis: 142px;
  -webkit-flex-basis: 142px;
  flex-grow: 2;
  padding-right: 14px;
`;

const ShopNameText = styled.div`
  font-size: 16px;
`;

const ButtonLayout = styled.div`
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.25));
`;

const DeliveryFee = styled.div`
  background-color: #eaeaea;
  padding: 3px 10px;
  margin-right: 5px;
`;
