import { connect } from "react-redux";
import State from "../../../store/state";
import { dispatchers } from "./action";
import { OrderHistoryListComponent, OrderHistoryListOrder } from "./component";
import { Order } from "../../../infra/domain/entities";
import { Shop } from "../../../infra/domain/shop";
import moment from "moment-timezone";

const mapStateToProps = (state: State) => {
  try {
    return {
      orders: translateOrdersToOrderHistoryListOrders(
        state.entity.orders,
        state.entity.shops
      )
    };
  } catch (error) {
    throw error;
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onOrderClick: (orderId: string) => {
      dispatch(dispatchers.handleUpdateActiveOrderId(orderId));
    }
  };
};

export const OrderHistoryList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryListComponent);

export default OrderHistoryList;

const translateOrdersToOrderHistoryListOrders = (
  orders: Order[],
  shops: Shop[]
) => {
  return orders.map(order => {
    const shop = shops.filter(s => s.shop_id === order.shop_id)[0];
    const dateTime = moment(order.visit_at * 1000).format("YYYY/MM/DD HH:mm");

    const orderHistoryListOrder: OrderHistoryListOrder = {
      orderId: order.order_id,
      dateTime,
      place: shop.name || "",
      orderNumber: order.order_number,
      orderStatus: order.order_status,
      orderType: order.order_type,
      isCanceled: order.is_canceled,
      refundedAmount: order.refunded_amount
    };
    return orderHistoryListOrder;
  });
};
