import { call, put, select } from "@redux-saga/core/effects";
import { updateActiveMenuItemAction, updateActiveOrderIdAction } from "../../store/app/actions";
import { ApiService } from "../api/ApiService";
import { getAccessToken } from "../../store/sagas";

export default class AppRepository {
  constructor(private apiClient: ApiService) {
    this.updateActiveOrderId = this.updateActiveOrderId.bind(this);
    this.fetchAndUpdateActiveMenu = this.fetchAndUpdateActiveMenu.bind(this);
  }

  *fetchAndUpdateActiveMenu(shopId: string, menuId: string) {
    const accessToken = yield select(getAccessToken);
    const menu = yield call(this.apiClient.getMenu, accessToken, shopId, menuId);
    yield put(updateActiveMenuItemAction(menu));
  }

  *updateActiveOrderId(orderId: string) {
    yield put(updateActiveOrderIdAction(orderId));
  }
}
