import React from "react";
import styled from "styled-components";
import SecondaryHeader from "../../components/atoms/secondary_header";
import { Button, Form, Input, Message } from "semantic-ui-react";
import { OrderType } from "../../../infra/domain/entities";

export default class ConfirmOrderAddressComponent extends React.Component<IProps, IState> {
  render() {
    return (
      <Wrapper>
          <div>
            <SecondaryHeader>{this.props.orderType === OrderType.TAKEOUT ? "緊急連絡先" : "お届け先"}</SecondaryHeader>
            <div style={{ padding:"10px" }}>

              <Form>
                {this.props.orderType === OrderType.DELIVERY && (
                  <>
                    <ZipInput
                      type="number"
                      onChange={(ev: any) => this.props.onChangeZipCode(ev.target.value)}
                      placeholder="郵便番号(ハイフンなし)"
                    />

                    <Button onClick={() => this.props.queryAddress()} style={{ marginLeft:"10px" }}> 住所検索 </Button>

                    <AddressInput
                      readOnly
                      value={this.props.autoComplementedAddress}
                      placeholder="住所が自動入力されます"
                    />

                    {this.props.addressErrorMessage &&
                      <Message negative>
                      <Message.Header>{this.props.addressErrorMessage}</Message.Header>
                      </Message>
                    }

                    <AddressInput
                      onChange={(ev: any) => this.props.onChangeAddress(ev.target.value)}
                      placeholder="番地以降を入力してください"
                    />
                 </>
                )}

                <TelInput
                  type="number"
                  onChange={(ev: any) => this.props.onChangePhoneNumber(ev.target.value)}
                  placeholder="緊急連絡先電話番号(ハイフンなし)"
                />

                {this.props.onChangeNickName &&
                  <NickNameInput
                    type="string"
                    onChange={(ev: any) => this.props.onChangeNickName!(ev.target.value)}
                    placeholder="受け取り用のお名前"
                  />
                }

              </Form>
            </div>
          </div>
      </Wrapper >
    );
  }
}

interface IProps {
  onChangeZipCode(value: string): void;
  onChangeAddress(value: string): void;
  onChangePhoneNumber(value: string): void;
  onChangeNickName?(value: string): void;
  queryAddress(): void;
  autoComplementedAddress: string;
  addressErrorMessage?: string;
  orderType: OrderType;
}

interface IState {
}

const Wrapper = styled.div`
  width: 100%;
`;

const ZipInput = styled(Input)`
  width: 60%;
  display: block;
  margin-top: 10px;
`;

const AddressInput = styled(Input)`
  width: 100%;
  display: block;
  margin-top: 10px;
  color: black;
`;

const TelInput = styled(Input)`
  width: 100%;
  display: block;
  margin-top: 10px;
`;

const NickNameInput = styled(Input)`
  width: 80%;
  display: block;
  margin-top: 10px;
`;
