import React from "react";
import styled from "styled-components";
import { Shop } from "../../../infra/domain/shop";
import ShopItem from "../../components/molecules/shop_item";

export class ShopListComponent extends React.Component<IProps, any> {
  render() {
    return (
      <ShopListWrapper>
        {this.props.shops.map((shop: Shop) => {
          return (
            <Wrapper key={shop.shop_id}>
              <ShopItem
                enableDelivery={shop.enable_delivery}
                deliveryFee={shop.delivery_fee}
                name={shop.name || ""}
                open={shop.open || ""}
                close={shop.close || ""}
                color={"#777777" || ""}
                is_open={shop.is_open}
                close_text={shop.close_text}
                image_url={shop.top_image_url || ""}
                onClick={() => {
                  if (shop.redirect_url) {
                    window.alert(
                      "当店舗は外部ページでのご注文となります。ページを遷移します。"
                    );
                    window.open(shop.redirect_url, "_blank");
                  } else {
                    this.props.onClickShopItem(shop);
                  }
                }}
              />
            </Wrapper>
          );
        })}
      </ShopListWrapper>
    );
  }
}

interface IProps {
  shops: Shop[];
  onClickShopItem: (shop: Shop) => void;
}

const Wrapper = styled.div`
  padding: 6px 17px;
`;

const ShopListWrapper = styled.div`
  padding-top: 6px;
  max-width: 767px;
  @media screen and (min-width: 767px) {
    margin: auto;
  }
`;
