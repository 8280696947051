import React from "react";
import styled from "styled-components";
import { Image as SemanticImage } from "semantic-ui-react";
import bagIcon from "../../../assets/images/bag_icon.svg";

export default class TakeoutText extends React.Component<IProps> {
  render() {
    return (
      <Wrapper>
        <Image src={bagIcon} style={{ width: "20px", height: "20px" }} />
        <Text>テイクアウト</Text>
      </Wrapper>
    );
  }
}

interface IProps {}

const Image = styled(SemanticImage)`
  width: "20px";
  height: "20px";
`;

const Wrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  padding-left: 16px;
`;
