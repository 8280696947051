const LineCode = "code";
const NavigateTo = "navigateTo";
const AccessToken = "accessToken";
const LiffState = "liff.state";

export interface LineLoginQueryParameters  {
  lineCode: string | null;
  navigateTo: string;
  accessToken : string;
}

/**
 * NOTE: LIFF の挙動変更に対する対応
 * LIFF の挙動が変化し，リダイレクト時のクエリパラメータがエンコードされて liff.state というクエリパラメータとして埋め込まれるようになった
 * このアプリでは，lineCode と，dinii 側が渡しているクエリパラメータの navigateTo と accessToken が必要だが，
 * lineCode は直接クエリパラメータとして，navigateTo と accessToken は liff.state というクエリパラメータ内にエンコードされた渡されている
 * そのため，lineCode はそのままパースし，liff.state がクエリパラメータとしてある場合は，その内部をデコードした上で再度クエリパースすることで，
 * 実質 lineCode, navigateTo, accessToken を URLSearchParams 形式で取り出せるようにする処理をここで行っている
 */
const stripLiffState = (query:URLSearchParams) => {
  const liffState = query.get(LiffState);
  const lineCode = query.get(LineCode);

  if (liffState && lineCode) {
    const stripQuery = new URLSearchParams(decodeURIComponent(liffState));
    stripQuery.append(LineCode, lineCode);
    return stripQuery;
  }

  return query;
};

export const fetchLineLoginQueryParameters = (location: Location): LineLoginQueryParameters | null => {
  const query = stripLiffState(new URLSearchParams(location.search));

  const lineCode = query.get(LineCode);
  const navigateTo = query.get(NavigateTo);
  const accessToken = query.get(AccessToken);
  // NOTE: LIFF の挙動変更に対する対応 2
  // LIFF の初期化時，なぜかルートに一度リダイレクトされてしまう既知の問題があるが，
  // LIFF の挙動変化によって，ルートリダイレクト時に渡されるクエリパラメータが，liff.state 内部のものだけとなった
  // つまり lineCode の復元が，ルートリダイレクト後にはできない．
  // 一方で，lineCode は liff init 時に必要なものであり，ルートリダイレクトされた時にはもう不要ではあるので，
  // ここではクエリパラメータの返却条件から lineCode を必須ではなくすことで対応した
  if (navigateTo && accessToken) {
    return { navigateTo, accessToken, lineCode };
  }

  return null;
};
