import React from "react";
import styled from "styled-components";

export default class SubChoiceButton extends React.Component<IProps, IState> {
  render() {
    return this.props.isSoldOut ? <SoldOutChoiceWrapper>{this.props.name}</SoldOutChoiceWrapper> :this.props.isSelected ? (
      <SelectedChoiceWrapper onClick={this.props.onClick}>{this.props.name}</SelectedChoiceWrapper>
    ) : <ChoiceWrapper onClick={this.props.onClick}>{this.props.name}</ChoiceWrapper>;
  }
}

interface IProps {
  name: string;
  isSelected: boolean;
  isSoldOut: boolean;
  onClick: () => void;
}

interface IState {}

const ChoiceWrapper = styled.div`
  color: #f46f46;
  padding: 6px;
  text-align: center;
  background-color: white;
  margin: 4px;
  border-style: solid;
  justify-content: flex-start;
  width: 100%;
  border-width: 1px;
  border-color: #f46f46;
  border-radius: 4px;
`;

const SoldOutChoiceWrapper = styled.div`
  color: #c4c4c4;
  padding: 6px;
  text-align: center;
  background-color: white;
  margin: 4px;
  border-style: solid;
  justify-content: flex-start;
  width: 100%;
  border-width: 1px;
  border-color: #c4c4c4;
  border-radius: 4px;
`;

const SelectedChoiceWrapper = styled.div`
  color: white;
  padding: 6px;
  text-align: center;
  background-color: #f46f46;
  margin: 4px;
  border-style: solid;
  justify-content: flex-start;
  width: 100%;
  border-width: 1px;
  border-color: #f46f46;
  border-radius: 4px;
`;
